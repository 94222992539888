import { Box, Card, Stack, Button } from '@mui/material'
import { forwardRef } from 'react'

type ShootingConfirmProps = {
  onSubmit(): void
  onReset(): void
  isLastVariant: boolean
}

const ShootingConfirm = forwardRef<typeof Box, ShootingConfirmProps>(
  ({ onSubmit, onReset, isLastVariant }, ref) => (
    <Box
      ref={ref}
      sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}
    >
      <Card sx={{ p: 4, m: 4, borderRadius: 3 }}>
        <Stack gap={2}>
          <Box>
            <Button
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              onClick={onSubmit}
            >
              {isLastVariant ? '撮影完了' : '次のアングルへ'}
            </Button>
          </Box>
          <Box>
            <Button
              variant="text"
              fullWidth
              size="large"
              sx={{ color: '#666' }}
              onClick={onReset}
            >
              撮り直し
            </Button>
          </Box>
        </Stack>
      </Card>
    </Box>
  )
)

export default ShootingConfirm

import { FC } from 'react'
import { DateField } from 's2-component'
import { TextfieldProps } from '.'
import { FormTitle } from './FormTitle'

export const DateForm: FC<TextfieldProps> = ({ id, title, required }) => (
  <>
    <FormTitle title={title} required={required} />
    <DateField fullWidth name={id} textFieldProps={{ required }} />
  </>
)

import { ParentSize } from '@visx/responsive'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, BoxProps, Grid } from '@mui/material'
import { FC, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { FallbackLoader, SelectBoxField } from 's2-component'
import { z } from 'zod'
import { useDiagnoses } from '../../../../common/hooks/useDiagnosis'
import { useProjectValue } from '../../../../common/hooks/useProject'
import OverallRatingGraph from './OverallRatingGraph'
import SkinAgeGraph from './SkinAgeGraph'
import SkinScoreGraph from './SkinScoreGraph'
import $style from './style.module.scss'

const formSchema = z.object({
  category: z.enum(['skinScore', 'skinAge', 'overallRating']),
  times: z.union([z.number(), z.string().transform((v) => parseInt(v, 10))]),
})

type FormSchema = z.infer<typeof formSchema>

type CategoryOption = {
  value: FormSchema['category']
  label: string
}

type TimesOption = {
  value: number
  label: string
}

export type ResultTransactionProps = BoxProps & {}

export const ResultTransaction: FC<ResultTransactionProps> = ({ ...props }) => {
  const { labels } = useProjectValue()
  const form = useForm<FormSchema>({
    defaultValues: {
      category: 'skinScore',
      times: 10,
    },
    resolver: zodResolver(formSchema),
  })
  const categoryOptions: CategoryOption[] = useMemo(
    () => [
      { value: 'skinScore', label: labels.skinScore },
      { value: 'skinAge', label: labels.skinAge },
      { value: 'overallRating', label: labels.overallRating },
    ],
    [labels.overallRating, labels.skinAge, labels.skinScore]
  )
  const timesOptions: TimesOption[] = useMemo(
    () => [
      { value: 10, label: '直近10回分' },
      { value: 50, label: '直近50回分' },
      { value: 100, label: '直近100回分' },
    ],
    []
  )
  const { times, category } = form.watch()
  const { contents, loading } = useDiagnoses(times)

  return (
    <FormProvider {...form}>
      <Box component="form" className={$style.root} {...props}>
        <Box height={300} mb={8}>
          <ParentSize>
            {(parent) => {
              if (contents?.length) {
                const graphProps = {
                  width: parent.width,
                  height: parent.height,
                  items: contents,
                }

                switch (category) {
                  case 'overallRating':
                    return <OverallRatingGraph {...graphProps} />
                  case 'skinAge':
                    return <SkinAgeGraph {...graphProps} />
                  case 'skinScore':
                    return <SkinScoreGraph {...graphProps} />
                  default:
                    break
                }
              }

              if (loading) {
                return null
              }

              return (
                <div className="s2-flex-center" style={{ height: '100%' }}>
                  診断データがまだありません
                </div>
              )
            }}
          </ParentSize>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectBoxField<FormSchema, 'category'>
              label="項目（縦軸）"
              name="category"
              options={categoryOptions}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectBoxField<FormSchema, 'times'>
              label="回数（横軸）"
              name="times"
              options={timesOptions}
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <SwitchTransition>
          <CSSTransition key={`${loading}`} classNames="fade" timeout={200}>
            {loading ? (
              <FallbackLoader fullHeight className={$style.loading} />
            ) : (
              <div />
            )}
          </CSSTransition>
        </SwitchTransition>
      </Box>
    </FormProvider>
  )
}

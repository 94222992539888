import { FC } from 'react'
import { RadioBoxField } from 's2-component'
import { SelectableFieldProps } from '.'
import { FormTitle } from './FormTitle'

export const RadioboxForm: FC<SelectableFieldProps> = ({
  id,
  title,
  required,
  options,
}) => (
  <>
    <FormTitle title={title} required={required} />
    <RadioBoxField name={id} options={options} />
  </>
)

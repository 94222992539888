import { IconButton, InputAdornment, TextField } from '@mui/material'
import { useState, useCallback, forwardRef } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import type { TextFieldProps } from '@mui/material'

export const PasswordField = forwardRef<
  HTMLInputElement,
  Omit<TextFieldProps, 'type' | 'InputProps'>
>((props, ref) => {
  const [visible, setVisible] = useState(false)
  const handleClickShowPassword = useCallback(
    () => setVisible((prev) => !prev),
    [setVisible]
  )

  return (
    <TextField
      {...props}
      ref={ref}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
})

PasswordField.displayName = 'PasswordField'

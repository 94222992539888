import { useCallback } from 'react'
import client from '../utils/api-client'

type Category = 'feedback' | 'recommend_product'

type Action = 'view' | 'click'

export const useRecord = () => {
  const recordUserLog = useCallback(
    async (category: Category, action: Action, value: any) => {
      await client.recordUserEvent({ category, action, value })
    },
    []
  )

  const viewProduct = useCallback(
    (value: string) => {
      recordUserLog('recommend_product', 'view', value)
    },
    [recordUserLog]
  )

  const viewFeedback = useCallback(
    (value: string) => {
      recordUserLog('feedback', 'view', value)
    },
    [recordUserLog]
  )

  const clickProduct = useCallback(
    (value: string) => {
      recordUserLog('recommend_product', 'click', value)
    },
    [recordUserLog]
  )

  const clickFeedback = useCallback(
    (value: string) => {
      recordUserLog('feedback', 'click', value)
    },
    [recordUserLog]
  )

  return {
    recordUserLog,
    viewProduct,
    viewFeedback,
    clickProduct,
    clickFeedback,
  }
}

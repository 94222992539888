import { z } from 'zod'

export const emailSchema = z
  .string()
  .email('メールアドレスの形式が正しくありません')

export const emailRequiredSchema = emailSchema.min(1, '必須項目です')

export type Email = z.infer<typeof emailSchema>

export type EmailRequired = z.infer<typeof emailRequiredSchema>

import { css } from '@emotion/react'
import { Box, BoxProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export type FillHeightProps = PropsWithChildren<
  BoxProps & {
    root?: boolean
  }
>

export const childStyle = css`
  height: 100%;
  max-height: 100vh;
  max-height: 100dvh;
`

export const rootStyle = css`
  height: 100vh;
  height: 100dvh;
`

export const FillHeight: FC<FillHeightProps> = ({
  children,
  root,
  ...props
}) => (
  <Box {...props} css={root ? rootStyle : childStyle}>
    {children}
  </Box>
)

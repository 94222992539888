import { z } from 'zod'

const REGX_SPACE = /\s/g
const REGX_DEGIT = /\d/
const REGX_ALPHABET = /[a-zA-Z]/
const REGX_FORMAT = /^[a-zA-Z\d-_]+$/

export const idSchema = z
  .string()
  .trim()
  .refine(
    (v) => v.replace(REGX_SPACE, '').length >= 16,
    '16文字以上にしてください'
  )
  .refine(
    (v) => REGX_DEGIT.test(v) && REGX_ALPHABET.test(v),
    '英数字を最低1つずつ含めてください'
  )
  .refine(
    (v) => REGX_FORMAT.test(v),
    '使用できるのは英数字、ハイフン（-）、アンダーバー（_）のみです'
  )
  .transform((v) => v.replace(REGX_SPACE, ''))

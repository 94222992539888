import { Box, styled } from '@mui/material'
import { FC } from 'react'
import { Description, Heading, Thumbnail } from 's2-component'
import imgPath from './assets/howto.png'

const Title = styled(Heading)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: theme.spacing(2),
  flex: 1,
}))

export const S2HowtoBestShootingView: FC = () => (
  <Box>
    <Box
      sx={{
        pt: 4,
        background:
          'linear-gradient(0deg, hsl(40deg 100% 84%), hsl(187deg 100% 88%))',
      }}
    >
      <Box sx={{ position: 'relative', pt: '50%' }}>
        <Thumbnail
          {...(typeof imgPath === 'string' ? { src: imgPath } : imgPath)}
          alt="精度のいい写真を撮る方法"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
          }}
        />
      </Box>
    </Box>
    <Box p={4}>
      <Title variant="h3" mt={0}>
        明るさの質が一番大事
      </Title>
      <Heading variant="h4" color="secondary">
        1. 照明は昼白色
      </Heading>
      <Description>
        写真の明るさが十分にないと分析の精度に影響が出ることがあります。上部・左右から均一の明るさで設置することを推奨しております。
      </Description>
      <Heading variant="h4" color="secondary">
        2. 背景は白無地
      </Heading>
      <Description>
        背景に部屋の家具や外の風景が写り込んでしまうと分析の精度に影響が出ることがあります。
        <br />
        また背景を白無地にすることで、同じ光量でも顔を明るくすることができます。
      </Description>
      <Heading variant="h4" color="secondary">
        3. フラッシュは使わない
      </Heading>
      <Description>
        フラッシュは肌が白飛びしてしまい、分析結果に影響が出ることがあるためご利用をお控えください。
      </Description>
      <Heading variant="h4" color="secondary">
        4. 外では撮らない
      </Heading>
      <Description>
        屋外撮影は影ができやすかったり、天候によって光量が変動してしまうため、推奨しておりません。
      </Description>
      <Title variant="h3">化粧・メガネ・前髪は極力オフ</Title>
      <Description>
        化粧やメガネなどのアクセサリー類を身に着けていたり、髪の毛が顔にかかっている状態だと分析の精度に影響が出ることがあります。出来る限り外しましょう。
      </Description>
      <Title variant="h3">同じ時間・場所・状態で</Title>
      <Description>
        日々の変化をより明確にするため、同じ時間、同じ撮影場所、同じ状態（化粧やメガネの有無等）で撮影することを推奨しています。
      </Description>
    </Box>
  </Box>
)

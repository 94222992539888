import { FormControlProps } from '@mui/material/FormControl'
import { FieldValues, Path } from 'react-hook-form'

export type OptionValue = string | number

export type Options = ({ value: OptionValue; label: string } | string)[]

export type FieldProps<T extends FieldValues, N extends Path<T>> = {
  id?: string
  label?: string
  name: N
  options: Options
} & Pick<FormControlProps, 'size' | 'fullWidth'>

export const parseOptions = (options: Options) =>
  options.map((option) => {
    if (typeof option !== 'string') {
      return option
    }

    const [value2, label2] = option.split(':')
    const value = value2.trim()
    const label = label2?.trim() ?? value

    return { value, label }
  })

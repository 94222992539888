import {
  Alert,
  Snackbar,
  AlertColor,
  styled,
  SnackbarProps,
} from '@mui/material'
import { useState, useCallback, useEffect, FC, PropsWithChildren } from 'react'

const Message = styled('div')({
  listStyleType: 'disc',
  textIndent: '-1em',
  paddingLeft: '1em',
})

export type S2SnackbarProps = {
  messages?: string[]
  severity?: AlertColor
} & Pick<SnackbarProps, 'autoHideDuration'>

export const S2Snackbar: FC<PropsWithChildren<S2SnackbarProps>> = ({
  messages,
  severity = 'info',
  autoHideDuration = 5000,
  children,
}) => {
  const [open, setOpen] = useState(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    setOpen(!!messages?.length || !!children)
  }, [messages, children])

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%', alignSelf: 'center', fontSize: 14 }}
      >
        {children}
        {messages?.map((message, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Message key={i}>{message}</Message>
        ))}
      </Alert>
    </Snackbar>
  )
}

import { z } from 'zod'

export const urlSchema = z
  .string()
  .trim()
  .url('URLの形式が正しくありません')
  .max(4096, '4096文字以内にしてください')

export const optinalUrlSchema = z
  .union([urlSchema, z.literal('').transform(() => undefined)])
  .nullish()

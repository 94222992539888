import { Box, BoxProps, styled } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

const Parent = styled(Box)({
  color: '#fff',
  fontSize: 12,
  fontWidght: 500,
  lineHeight: 1.2,
  borderRadius: '4px',
  padding: '4px 8px',
})

export type LabelProps = PropsWithChildren<{
  sx?: BoxProps['sx']
  severity?: 'primary' | 'info' | 'error'
}>

export const Label: FC<LabelProps> = ({ children, severity = 'info', sx }) => (
  <Parent
    sx={{
      display: 'inline-flex',
      backgroundColor: (theme) => theme.palette[severity].main,
      ...sx,
    }}
  >
    {children}
  </Parent>
)

import { FC } from 'react'
import { useFaceDetect } from '../../hooks/useFaceDetect'
import $style from './style.module.scss'

export const S2FaceDetectCameraView: FC = () => {
  const { videoRef, canvasRef } = useFaceDetect()
  return (
    <>
      <video
        ref={videoRef}
        className={[$style.src, $style.video].join(' ')}
        playsInline
        muted
      />
      <canvas
        ref={canvasRef}
        className={[$style.src, $style.canvas].join(' ')}
      />
    </>
  )
}

export type Options = {
  mimeType?: MimeType
  quality?: number
}

const MimeTypeEnum = {
  Jpeg: 'image/jpeg',
  Png: 'image/png',
} as const

type MimeType = (typeof MimeTypeEnum)[keyof typeof MimeTypeEnum]

const DEFAULT_OPTIONS: Options = {
  mimeType: 'image/jpeg',
  quality: 0.75,
}

export const toDataURL = (canvas?: HTMLCanvasElement, options?: Options) => {
  if (!canvas) {
    return ''
  }

  const { mimeType, quality } = {
    ...DEFAULT_OPTIONS,
    ...options,
  }
  return canvas.toDataURL(mimeType, quality)
}
export const toBlob = (canvas: HTMLCanvasElement, options?: Options) => {
  if (!canvas) {
    return ''
  }

  const { mimeType, quality } = {
    ...DEFAULT_OPTIONS,
    ...options,
  }
  return new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => resolve(blob), mimeType, quality)
  })
}

export const getSize = (source: CanvasSource) => {
  if ('videoWidth' in source) {
    return {
      width: source.videoWidth,
      height: source.videoHeight,
    }
  }

  if ('naturalWidth' in source) {
    return {
      width: source.naturalWidth,
      height: source.naturalHeight,
    }
  }

  return {
    width: source.width,
    height: source.height,
  }
}

export const convertRect = (
  dist: HTMLCanvasElement,
  source: CanvasSource
): Rect => {
  let sx = 0
  let sy = 0
  let { width: sw, height: sh } = getSize(source)
  const { width: dw, height: dh } = getSize(dist)
  const sAspect = sh / sw
  const dAspect = dh / dw

  if (sAspect < dAspect) {
    const sw2 = Math.round(dw * (sh / dh))
    sx = (sw - sw2) / 2
    sw = sw2
  } else if (sAspect > dAspect) {
    const sh2 = Math.round(dh * (sw / dw))
    sy = (sh - sh2) / 2
    sh = sh2
  }

  return {
    x: sx,
    y: sy,
    w: sw,
    h: sh,
  }
}

export const draw = (
  dist: HTMLCanvasElement,
  source: CanvasSource,
  flipX = true
) => {
  const ctx = dist.getContext('2d')

  if (!source || !ctx) {
    return
  }

  const { width: dw, height: dh } = getSize(dist)
  const { x, y, w, h } = convertRect(dist, source)
  ctx.restore()
  ctx.clearRect(0, 0, dw, dh)
  if (flipX) {
    ctx.translate(dw, 0)
    ctx.scale(-1, 1)
  }
  ctx.drawImage(source, x, y, w, h, 0, 0, dw, dh)
  ctx.save()
}

export const clip = (
  source: HTMLCanvasElement,
  { x, y, w, h }: Rect,
  maxSize = 256
) => {
  const dCanvas = source.cloneNode() as HTMLCanvasElement | undefined
  const context = dCanvas?.getContext('2d')

  if (!dCanvas || !context) {
    return
  }

  const longSide = Math.max(w, h)
  const dw = longSide > maxSize ? Math.round((w / longSide) * maxSize) : w
  const dh = longSide > maxSize ? Math.round((h / longSide) * maxSize) : h

  dCanvas.width = dw
  dCanvas.height = dh
  context.drawImage(source, x, y, w, h, 0, 0, dw, dh)

  // eslint-disable-next-line consistent-return
  return dCanvas
}

import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { Box, Card, Divider, Typography, TypographyProps } from '@mui/material'
import { useSetRecoilState } from 'recoil'
import { DateTime } from 'luxon'
import { MaterialIconButton } from 's2-component'

import { useProjectValue } from '../../../../common/hooks/useProject'
import { Diagnosis } from '../../../../common/schemas/diagnosis'
import { useGA } from '../../../../common/hooks/useGA'
import { currentDiagnosisIdState } from '../../../../common/hooks/useDiagnosis'
import { MenuLauncher } from './MenuLauncher'
import $style from './style.module.scss'

const SkinTypeNameEnum = {
  dry: '乾燥肌',
  oily: '脂性肌',
  mix: '混合肌',
  sensitive: '敏感肌',
  normal: '普通肌',
} as const

const SkinResultItem: FC<
  PropsWithChildren<{ label: string; valueProps?: TypographyProps }>
> = ({
  label,
  valueProps = {
    variant: 'h2',
    my: 0,
    fontWeight: 500,
  },
  children,
}) => (
  <Box component="dl" my={0}>
    <Typography component="dt" variant="body2" color="primary">
      {label}
    </Typography>
    <Typography component="dd" {...valueProps}>
      {children}
    </Typography>
  </Box>
)

export type DetailCardProps = {
  result: Diagnosis
}

export const DetailCard: FC<DetailCardProps> = ({ result }) => {
  const {
    history: {
      visibleItem,
      features: { roundOverallRating },
    },
    labels,
  } = useProjectValue()
  const visibleSkinScore = visibleItem.skinScore
  const setCurrentResultId = useSetRecoilState(currentDiagnosisIdState)
  const { selectContent } = useGA()[1]
  const averageScore = useMemo(() => {
    const visibleValues = Object.entries(result.scores).filter(
      ([key]) => visibleSkinScore[key as keyof typeof result.scores]
    )
    const total = visibleValues.reduce((sum, [, value]) => sum + value.score, 0)
    return roundOverallRating
      ? Math.round((total / visibleValues.length) * 100)
      : parseFloat(((total / visibleValues.length) * 100).toFixed(2))
  }, [result, roundOverallRating, visibleSkinScore])
  const dateString = useMemo(
    () => DateTime.fromMillis(result.createdAt).toFormat('yyyy/MM/dd'),
    [result.createdAt]
  )
  const timeString = useMemo(
    () => DateTime.fromMillis(result.createdAt).toFormat('HH:mm:ss'),
    [result.createdAt]
  )

  const findById = useCallback(
    async (id?: number) => {
      if (!id) {
        return
      }
      setCurrentResultId(id)
      selectContent('histroy_detail:changeHistory')
    },
    [selectContent, setCurrentResultId]
  )

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: 3,
      }}
    >
      <Box p={4} display="flex" gap={3} flexDirection="column">
        {visibleItem.skinType ? (
          <SkinResultItem label={labels.skinType}>
            {SkinTypeNameEnum[result.type]}
          </SkinResultItem>
        ) : null}
        {visibleItem.skinAge ? (
          <SkinResultItem label={labels.skinAge}>
            {result.age}
            <Typography variant="body1" component="span">
              歳
            </Typography>
          </SkinResultItem>
        ) : null}
        {visibleItem.overallRating ? (
          <SkinResultItem label={labels.overallRating}>
            {averageScore}
            <Typography variant="body1" component="span">
              点
            </Typography>
          </SkinResultItem>
        ) : null}
        <SkinResultItem
          label={labels.detectedAt}
          valueProps={{
            variant: 'body1',
            fontWeight: 500,
            lineHeight: 1.2,
            mt: 0.5,
          }}
        >
          {dateString}
          <br />
          {timeString}
        </SkinResultItem>
      </Box>
      <Box flex="1" />
      {visibleItem.backnumber ? (
        <>
          <Divider />
          <Box className={$style.switch}>
            <MaterialIconButton
              className={$style.switch__btn}
              disabled={!result.prevId}
              onClick={() => findById(result.prevId)}
              icon="navigate_before"
            />
            <MenuLauncher
              className={$style.switch__btn}
              disabled={!result.prevId && !result.nextId}
              selected={result.createdAt}
              onSelect={findById}
              icon="list"
            />
            <MaterialIconButton
              className={$style.switch__btn}
              disabled={!result.nextId}
              onClick={() => findById(result.nextId)}
              icon="navigate_next"
            />
          </Box>
        </>
      ) : null}
    </Card>
  )
}

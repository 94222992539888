import { FC, useMemo } from 'react'
import parse, {
  HTMLReactParserOptions,
  Element,
  domToReact,
} from 'html-react-parser'
import Typography from '@mui/material/Typography'
import { Description } from '../../ui-elements/Description'
import {
  ListItem,
  UlList,
  OlList,
  Table,
  TableTr,
  TableTh,
  TableTd,
} from '../Policy/ui'
import { TextLink } from '../../ui-elements/TextLink'

const options: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element && domNode.attribs) {
      const { children, name, attribs } = domNode

      switch (name) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          return (
            <Typography {...attribs} variant={name}>
              {domToReact(children, options)}
            </Typography>
          )
        case 'ul':
          return <UlList {...attribs}>{domToReact(children, options)}</UlList>
        case 'ol':
          return <OlList {...attribs}>{domToReact(children, options)}</OlList>
        case 'li':
          return (
            <ListItem {...attribs}>{domToReact(children, options)}</ListItem>
          )
        case 'a':
          return (
            <TextLink {...attribs}>{domToReact(children, options)}</TextLink>
          )
        case 'table':
          return <Table {...attribs}>{domToReact(children, options)}</Table>
        case 'tr':
          return <TableTr {...attribs}>{domToReact(children, options)}</TableTr>
        case 'th':
          return <TableTh {...attribs}>{domToReact(children, options)}</TableTh>
        case 'td':
          return <TableTd {...attribs}>{domToReact(children, options)}</TableTd>
        case 'p':
          return (
            <Description {...attribs}>
              {domToReact(children, options)}
            </Description>
          )
        default:
          break
      }
    }

    return domNode
  },
}

export const ElementToMUI: FC<{ html: string }> = ({ html }) => {
  const Elements = useMemo(() => parse(html, options), [html])
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{Elements}</>
}

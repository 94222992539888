import { feedbackSchema as baseSchema } from 's2-lib'
import { z } from 'zod'
import { assetImagePathSchema } from './assetImagePath'

const baseContent = baseSchema.shape.content

export const feedbackSchema = baseSchema.extend({
  content: baseContent.extend({
    banner: assetImagePathSchema,
    thumbnail: assetImagePathSchema,
  }),
})

export type Feedback = z.infer<typeof feedbackSchema>

import { ZodTypeAny } from 'zod'
import { Box, Divider, Stack, Button } from '@mui/material'
import { FC, useCallback } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSetCounselingAnswer } from '../../../common/hooks/useCounseling'
import { useRedirect } from '../../../common/hooks/useRedirect'
import {
  Counseling,
  CounselingAnswer,
} from '../../../common/schemas/counseling'
import { StickyFooter } from '../../ui/StickyFooter'
import { CheckboxForm } from './CheckboxForm'
import { RadioboxForm } from './RadioForm'
import { SelectForm } from './SelectForm'
import { DateForm } from './DateForm'
import { TextForm } from './TextForm'

function getField(data: Counseling) {
  const props = {
    id: data.id,
    title: data.title,
    required: data.required,
  }
  switch (data.fieldType) {
    case 'checkbox':
      return data.options?.length ? (
        <CheckboxForm {...props} options={data.options} />
      ) : null
    case 'radiobox':
      return data.options?.length ? (
        <RadioboxForm {...props} options={data.options} />
      ) : null
    case 'selectbox':
      return data.options?.length ? (
        <SelectForm {...props} options={data.options} />
      ) : null
    // case 'text':
    default:
      switch (data.inputType) {
        case 'date':
          return <DateForm {...props} />
        default:
          return <TextForm {...props} />
      }
  }
}

type FormProps = {
  items: Counseling[]
  defaultValues: Partial<CounselingAnswer>
  validateResolver: ZodTypeAny
}

export const Form: FC<FormProps> = ({
  items,
  defaultValues,
  validateResolver,
}) => {
  const redirect = useRedirect()
  const setAnswer = useSetCounselingAnswer()
  const form = useForm<CounselingAnswer>({
    defaultValues,
    resolver: zodResolver(validateResolver),
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = form
  const onSubmit: SubmitHandler<CounselingAnswer> = useCallback(
    (data) => {
      setAnswer(data)
      redirect('/shooting')
    },
    [redirect, setAnswer]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        <Stack gap={2} px={6} sx={{ wordBreak: 'break-all' }}>
          {items.map((data) => (
            <Box key={data.id}>{getField(data)}</Box>
          ))}
        </Stack>
      </FormProvider>
      <StickyFooter>
        <Divider />
        <Box px={6} py={4}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={!isValid}
            fullWidth
          >
            顔写真を撮影する
          </Button>
        </Box>
      </StickyFooter>
    </form>
  )
}

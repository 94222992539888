import { Box, Typography, TypographyProps } from '@mui/material'
import { FC, PropsWithChildren, ReactNode } from 'react'

export type HeadingProps = PropsWithChildren<
  TypographyProps & {
    action?: ReactNode
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  }
>

export const Heading: FC<HeadingProps> = ({
  children,
  action,
  variant,
  mt,
  mb,
  ...props
}) => (
  <Typography
    className="s2-heading"
    component={Box}
    variant={variant}
    sx={{
      display: 'flex',
      alignItems: 'center',
      mt,
      mb,
    }}
  >
    <Typography variant={variant} mt={0} mb={0} {...props}>
      {children}
    </Typography>
    {action && (
      <>
        <Box sx={{ flex: 1 }} />
        <Typography variant="body1" component={Box} display="flex" gap={4}>
          {action}
        </Typography>
      </>
    )}
  </Typography>
)

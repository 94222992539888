import { FC, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { Helmet } from 'react-helmet-async'
import { useAccountValue } from '../../common/hooks/useAccount'
import { gaMeasurementIdState } from '../../common/hooks/useGA'
import { useProjectValue } from '../../common/hooks/useProject'

export const GASetup: FC = () => {
  const account = useAccountValue()
  const project = useProjectValue()
  const projectId = project?.id
  const gaMeasurementId = useRecoilValue(gaMeasurementIdState)

  useEffect(() => {
    function setup() {
      window.gtag?.('set', {
        user_id: account?.id,
        user_properties: {
          project_id: projectId,
          id_linkage: account?.connectId,
          ...(account
            ? {
                uid: account.id,
                gender: account.gender,
                birth_date: account.birthDate,
              }
            : {}),
        },
      })
    }

    if (gaMeasurementId && projectId) {
      if ('gtag' in window) {
        setup()
      } else {
        // @ts-ignore
        window.readyGtag = setup
      }
    }
  }, [account, gaMeasurementId, projectId])

  return (
    <Helmet>
      {gaMeasurementId && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
window.gtag = window.gtag || function gtag(){dataLayer.push(arguments);}
window.gtag('js', new Date());
window.gtag('config', '${gaMeasurementId}', { send_page_view: false });
window.readyGtag && window.readyGtag();`}
          </script>
        </>
      )}
    </Helmet>
  )
}

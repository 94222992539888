import { Backdrop, Box, CircularProgress, IconButton } from '@mui/material'

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

import { FC, useEffect, useState } from 'react'

import type { DiagnosisResult } from '../common/schemas/diagnosis'

import { useCurrentDiagnosisValueLoadable } from '../common/hooks/useDiagnosis'
import { usePageview } from '../common/hooks/useGA'
import { useProjectValue } from '../common/hooks/useProject'

import { Authentication } from '../components/functional/Authentication'
import Redirect from '../components/functional/Redirect'
import { TheFooter } from '../components/layouts/TheFooter'
import { MatchingIndex } from '../components/pages/result/MatchingIndex'
import { ResultIndex } from '../components/pages/result/ResultIndex'
import { TransferAccountModal } from '../components/pages/result/TransferAccountModal'
import TitleHeader from '../components/ui/TitleHeader'

const PageComponent: FC = () => {
  const project = useProjectValue()
  const { state, contents } = useCurrentDiagnosisValueLoadable()
  const [result, setResult] = useState<DiagnosisResult | null>(null)
  const [openedTransferAccountModal, setOpenedTransferAccountModal] =
    useState(false)
  const hasValue = state === 'hasValue'

  usePageview({
    ignore: !hasValue || !contents,
  })

  useEffect(() => {
    if (hasValue && contents) {
      setResult(contents)
    }
  }, [contents, hasValue])

  if (hasValue && !contents) {
    return <Redirect to="/counseling" replace />
  }

  if (!result) {
    return null
  }

  // modal版の場合は TheHeader に backIcon='close' onBack={} を付与する
  return (
    <div>
      <TitleHeader
        action={
          project.features.transferAccountUrl && (
            <IconButton
              sx={{ color: 'rgba(22,22,22,0.85)' }}
              onClick={() => setOpenedTransferAccountModal(true)}
            >
              <ManageAccountsIcon />
            </IconButton>
          )
        }
      />
      <Box sx={{ px: 6 }}>
        <ResultIndex result={result.item} />
        <MatchingIndex {...result.rewards} />
      </Box>
      <TheFooter mt={12} mb={6} />
      <Backdrop
        sx={{
          backgroundColor: 'rgba(255,255,255,0.5)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={!hasValue}
      >
        <CircularProgress size={32} />
      </Backdrop>
      {project.features.transferAccountUrl && (
        <TransferAccountModal
          transferAccountUrl={project.features.transferAccountUrl}
          open={openedTransferAccountModal}
          onClose={() => setOpenedTransferAccountModal(false)}
        />
      )}
    </div>
  )
}

export default () => (
  <Authentication>
    <PageComponent />
  </Authentication>
)

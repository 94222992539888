import { FC, useEffect, useRef } from 'react'
import { styled } from '@mui/material'
import QR, { QRCodeRenderersOptions } from 'qrcode'

const Canvas = styled('canvas')`
  display: block;
  height: auto !important;
  aspect-ratio: 1 / 1;
  margin-inline: auto;
`

type QRCodeProps = {
  value: string
  size: number
} & Omit<QRCodeRenderersOptions, 'width'>

export const QRCode: FC<QRCodeProps> = ({ value, size, ...rest }) => {
  const ref = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (ref.current && value.trim()) {
      QR.toCanvas(ref.current, value, { width: size * 2, margin: 8, ...rest })
    }
  }, [value, size, rest])

  return <Canvas ref={ref} style={{ maxWidth: `min(100%, ${size}px)` }} />
}

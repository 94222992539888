import { Box } from '@mui/material'
import { FC, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FaceDetectProvider, FaceDirVariant } from 's2-shooting-component'
import {
  useCounseling,
  useCounselingAnswerValue,
} from '../common/hooks/useCounseling'
import { usePageview } from '../common/hooks/useGA'
import { Authentication } from '../components/functional/Authentication'
import Redirect from '../components/functional/Redirect'
import { TheHeader } from '../components/layouts/TheHeader'
import ShootingView from '../components/pages/shooting/ShootingView'
import { useProjectValue } from '../common/hooks/useProject'

const PageComponent: FC = () => {
  const { items } = useCounseling()
  const [searchParams] = useSearchParams()
  const answers = useCounselingAnswerValue()
  const {
    history: {
      features: { additionalPicts },
    },
  } = useProjectValue()
  const hasCounseling = items.length > 0
  // 設問があるのに解答がない場合はリダイレクト
  const isRedirect = hasCounseling && !answers
  const variant = useMemo<FaceDirVariant>(() => {
    const v = searchParams.get('variant')
    switch (v) {
      case 'dir-left':
      case 'dir-right':
        return v
      default:
        return 'front'
    }
  }, [searchParams])
  const shootingVariants = useMemo<FaceDirVariant[]>(
    () => Array.from(new Set(['front', ...additionalPicts])),
    [additionalPicts]
  )

  const prevPagePath = useMemo(() => {
    if (shootingVariants.length <= 1 || variant === 'front') {
      return hasCounseling ? '/counseling' : '/'
    }

    const currentVariant = shootingVariants.indexOf(variant)

    switch (currentVariant) {
      case 0:
        return hasCounseling ? '/counseling' : '/'
      case 1:
        return '/shooting'
      default:
        return `/shooting?variant=${shootingVariants[currentVariant - 1]}`
    }
  }, [hasCounseling, shootingVariants, variant])

  const title = useMemo(() => {
    const MAX_PAGE = (hasCounseling ? 1 : 0) + shootingVariants.length
    let prefix = '顔写真撮影'

    if (MAX_PAGE === 1) {
      return prefix
    }

    const currentPage =
      Math.max(0, shootingVariants.indexOf(variant)) + (hasCounseling ? 2 : 1)

    if (shootingVariants.length > 1) {
      switch (variant) {
        case 'dir-left':
          prefix = '左向き写真撮影'
          break
        case 'dir-right':
          prefix = '右向き写真撮影'
          break
        default:
          prefix = '正面写真撮影'
          break
      }
    }

    return `${prefix}（${currentPage}/${MAX_PAGE}）`
  }, [hasCounseling, shootingVariants, variant])

  usePageview({
    ignore: isRedirect,
  })

  if (isRedirect) {
    return <Redirect to={prevPagePath} replace />
  }

  return (
    <Box sx={{ position: 'relative', height: '100%', background: '#000' }}>
      <TheHeader backPath={prevPagePath}>{title}</TheHeader>
      <FaceDetectProvider>
        <ShootingView
          postLoading
          variant={variant}
          variants={shootingVariants}
          answers={answers}
        />
      </FaceDetectProvider>
    </Box>
  )
}

export default () => (
  <Authentication>
    <PageComponent />
  </Authentication>
)

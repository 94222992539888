import { z } from 'zod'
import { urlSchema } from './url'

export const customPageKeyValues = [
  'welcome',
  'privacyPolicy',
  'termsOfService',
] as const

export const customPageKeyEnum = z.enum(customPageKeyValues)

export const customPageTypeValues = [
  'default',
  'customize',
  'external',
] as const

export const customPageTypeEnum = z.enum(customPageTypeValues)

export const commonCustomPageDefaultTypeSchema = z.object({
  type: z.literal('default'),
})

export const commonCustomPageExternalTypeSchema = z.object({
  type: z.literal('external'),
  url: urlSchema,
})

export const commonCustomPageCustomTypeScehma = z.object({
  type: z.literal('customize'),
  content: z.string().describe('マークダウン表記'),
})

export const commonCustomPageSchema = z.union([
  commonCustomPageDefaultTypeSchema,
  commonCustomPageExternalTypeSchema,
  commonCustomPageCustomTypeScehma,
])

export const welcomeCustomPageCustomTypeScehma = z.object({
  type: z.literal('customize'),
  title: z.string().optional(),
  content: z.string().optional().describe('マークダウン表記'),
  btnLabel: z.string().optional(),
  kvPath: z.string().optional().describe('トップ画像パス'),
})

export const welcomeCustomPageSchema = z.union([
  commonCustomPageDefaultTypeSchema,
  commonCustomPageExternalTypeSchema,
  welcomeCustomPageCustomTypeScehma,
])

export type CustomPageKey = z.infer<typeof customPageKeyEnum>

export type CustomPageType = z.infer<typeof customPageTypeEnum>

export type CommonCustomPageDefaultType = z.infer<
  typeof commonCustomPageDefaultTypeSchema
>
export type CommonCustomPageExternalType = z.infer<
  typeof commonCustomPageExternalTypeSchema
>
export type CommonCustomPageCustomType = z.infer<
  typeof commonCustomPageCustomTypeScehma
>
export type WelcomeCustomPageCustomType = z.infer<
  typeof welcomeCustomPageCustomTypeScehma
>
export type CommonCustomPage = z.infer<typeof commonCustomPageSchema>
export type WelcomeCustomPage = z.infer<typeof welcomeCustomPageSchema>

export const defaultPrivacyPolicyContent = `株式会社Novera（以下「当社」といいます）は、当社の提供するサービス（以下「本サービス」といいます）における、お客様についての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます）を定めます。

## 収集する利用者情報及び収集方法

本ポリシーにおいて、「利用者情報」とは、お客様の識別に係る情報、通信サービス上の行動履歴その他お客様のスマートフォン、ＰＣ等の端末においてお客様又はお客様の端末に関連して生成又は蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。

本サービスにおいて当社が収集する利用者情報は、以下のようなものとなります。

- 氏名
- メールアドレス
- 電話番号
- 住所、所在地
- お支払い情報
- その他当社が定める入力フォームにユーザーが入力する情報

なお、お客様が、当社製品「novera」など、当社及び第三者の提供するサービスの利用を可能とする当社の音声アシスタントサービス（当該サービスにアクセスできる製品又はアプリケーションを含め、以下「音声等サービス」といいます）をご利用の場合には、当社は、あわせて以下の情報を取得します。

- 生体情報
- 音声等サービスにおいてカメラで撮影した画像データ（画像データ及び画像ファイルサイズ・撮影日時等の情報を含みます）及びその認識結果
- 音声等サービスのマイクで録音した音声データ及びその認識結果
- 音声等サービスのセンサーで取得した位置情報、利用環境情報及びその認識結果
- その他お客様が音声等サービスに対して投稿、送信した音声、テキスト、画像、動画等の一切のコンテンツ

## 利用目的

利用者情報は、本サービスの提供のために利用されるほか、以下の目的にも使用いたします。
1. 本サービスに関する登録の受付、本人確認、ご案内、問合せの受付及び回答
2. 本サービスに関するご案内
3. メールマガジンの送信
4. 本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます）に違反する行為への対応
5. 本サービスに関する規約等の変更などの通知
6. 当社のサービスに関連してお客様を識別できない形式に加工した統計データの作成
7. 本サービスの利用状況の確認及び分析のため
8. 本サービス及び本サービスの運用、機能改善及び品質向上のため
9. 大学等の研究及び当社提携先との共同研究目的のため
10. 当社又は第三者の広告の配信又は表示
11. マーケティングでの利用
12. 上記の利用目的に付随する行為

## 第三者への提供

当社は、利用者情報のうち、個人情報については、法律で定められている場合を除いて、お客様の個人情報を当該本人の同意を得ず第三者に提供することはありません。ただし、次に掲げる場合はこの限りでありません。

- 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
- 合併その他の事由による事業の承継に伴って個人情報が提供される場合
- 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合

## 個人関連情報の取扱い

当社は、法令で定める場合を除き、第三者が個人関連情報を個人データとして取得することが想定されるときは、当社においてお客様から同意を取得するか、又は、当該第三者においてお客様本人から同意を得られていることを確認したうえで、当該情報を提供いたします。

## 個人情報の開示

当社は、お客様から、個人情報保護法の定めに基づき個人情報の開示を求めたれたときは、お客様ご本人からのご請求であることを確認の上で、お客様に対し、遅滞なく開示を行います。ただし、個人情報保護法その他の法令により、当社が開示義務を負わない場合は、この限りでありません。なお、個人情報の開示につきましては、手数料（１件あたり1000円）を頂戴しておりますので、予めご了承ください。

## 個人情報の訂正及び利用停止等

当社は、お客様から（１）個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び（２）あらかじめ公表された利用目的の範囲を超えて取扱われているという理由又は偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、お客様ご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正又は利用停止を行い、その旨をお客様に通知します。なお、合理的な理由に基づいて訂正又は利用停止を行わない旨を決定したときは、お客様に対し、その旨を通知いたします。

当社は、お客様から、お客様の個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、お客様ご本人からのご請求であることを確認のうえで、個人情報の消去を行い、その旨をお客様に通知します。

なお、個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、この規定は適用されません。

## 外部サービスの利用
当社は、お客様状況解析のために、Google Inc.（以下「Google」といいます）が提供するFirebase Crashlyticsを用いる場合がございます。内容につきましては、以下のリンクからご確認ください。

- [Firebase Crashlytics Google inc. 規約](https://firebase.google.com/terms/crashlytics/)
- [Google Analytics Google inc. 規約](https://www.google.com/analytics/terms/jp.html)
- [Google プライバシーポリシー](https://policies.google.com/privacy?hl=ja)

## プライバシーポリシーの変更手続き

当社は、利用者情報の取扱いに関する運用状況を適宜見直し、継続的な改善に務めるものとし、必要に応じて、本ポリシーを変更することがあります。変更した場合には、当社ホームページに掲載する方法でお客様に通知いたします。`

export const defaultTermsOfServiceContent = `本規約は、株式会社Novera（以下「当社」といいます）が運営する「skinsense」及びこれに付随するサービス（理由の如何を問わず、名称又は内容が変更された場合には当該変更後のサービスを含み、以下「本サービス」といいます）の利用者に適用されるものとします。

## 1 適用

1. 本規約は、本サービスの提供条件及び本サービスの利用に関する当社と利用者との間の権利義務関係を定めることを目的とします。
2. 本規約の内容と、本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。

## 2 禁止事項

利用者は、本サービスの利用に関し、以下の行為を行わないものとします。
- 当社若しくは第三者の権利を侵害する行為又は侵害するおそれのある行為
- 第三者になりすまして本サービスを利用する行為
- 営業活動、営利目的の利用又はその準備行為
- 犯罪行為又は犯罪行為に関連する行為
- 第三者の設備又は本サービスの利用・運営に支障を与える行為
- ストーカー行為、交際又は面談の強要等の行為その他これに類する行為
- 法令、本規約又は公序良俗に違反する行為
- 当社の信用を毀損し、又は当社の財産を侵害する行為
- 第三者に対して本サービスを再頒布、貸与又は販売する行為
- 本サービスについて、当社の承諾を得た場合を除き、その全部又は一部を問わず利用（複製、複写、公開、アップロード、送信、譲渡、貸与、翻訳、翻案、改変、その他形態を問わない）する行為
- その他当社が合理的理由により不適切と判断する行為
- 本サービスのネットワーク又はシステム等に過度な負荷をかける行為
- 当社が提供するシステムに対するリバースエンジニアリングその他の解析行為
- 当社のネットワーク又はシステム等への不正アクセス

## 3 本サービスの変更・停止・中断

1. 当社は、事前の通知なく、本サービスの全部又は一部を変更、追加、廃止する場合があります。
2. 当社は、本サービスの全部又は一部を変更、追加、廃止により利用者又は第三者に生じた損害について、一切の責任を負わないものとします。

## 4 権利帰属

1. 本サービスを通じて提供されるサービス（映像、音声、文字等を含む）に関わる著作権、著作隣接権、商標権、特許権その他一切の知的財産権は、当社又は正当な権利を有する者に帰属し、本サービスの利用によっても利用者にいかなる権利も付与されるものではありません。
2. 利用者は、本サービスを通じて提供される情報その他の著作物を、当該著作物に関して明示的に許諾された範囲及び著作権法で認められる私的使用の範囲を超えて利用することはできません。
3. 利用者は、利用者が当社に提供したデータ（利用者が本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますが、これに限りません）の一切を意味します）について、自らが投稿その他送信することについて適法な権利を有していること及び第三者の権利を侵害していないことを表明・保証するとともに、当社に対し、世界的、非独占的、無償、サブライセンス可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。
4. 利用者は、当社及び当社から権利を承継し又は許諾された者に対して著作者人格権を行使しないことに同意するものとします。

## 5 利用の停止等

当社は、利用者が、以下の各号のいずれかに該当する場合は、事前に通知又は催告することなく、利用者について本サービスの利用を一時的に停止することができるものとします。

- 本規約のいずれかの条項に違反した場合
- 当社に提供した情報に虚偽の事実があることが判明した場合
- ６ヶ月以上本サービスの利用がない場合
- 当社からの問い合わせその他の回答を求める連絡に対して、３０日間以上応答がない場合
- 当社が本サービスの利用を適当でないと判断した場合

## 6 本サービスの終了

1. 当社は、当社の都合により、本サービスの内容を変更し、又は提供を終了することができます。
2. 当社は、本条に基づき当社が行った措置により利用者に生じた損害について、一切の責任を負いません。

## 7 第三者サイト及び広告

1. 当社は、本サービスとリンクしている当社以外の第三者サイトの内容について、一切の責任を負いません。
2. 本サービスにおいて広告や販売促進を行っている広告主又は業者との取引は、利用者と当該広告主又は業者の責任で行われるものであり、当社は、当該取引によって生じた損害につき、一切の責任を負いません。

## 8 個人情報及び提供データ等の取扱い

1. 当社おける個人情報の取扱いについては、別途当社が定める「プライバシーポリシー」の定めによるものとし、利用者は、これに従って当社が個人情報を取り扱うことに同意するものとします。
2. 当社は、利用者が当社に提供した情報につき、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、利用者はこれに異議を唱えないものとします。
3. 当社は、利用者から提供されたデータ等を、安全性の高いネットワーク上に保存いたします。しかしながら、当社は、これらの完全な安全性、信頼性等を保証するものではなく、保存された登録情報及び利用者から提供されたデータ等その他の消失に起因して生じた損害につき、賠償する責任を一切負わないものとします。

## 9 免責

1. 本サービスは、医学的な知識・情報を提供するものではありません。また、当社は、本サービスが利用者の目的に適合すること、期待する機能、商品価値、正確性、有用性を有することについて、何ら保証するものではありません。なお、当社の提供する情報に基づく商品の購入、使用等その他の行為は、利用者自身の責任に基づく判断に委ねられており、当社は一切の責任を負いません。
2. 何らかの理由により当社が責任を負う場合があっても、当社は、利用者の損害につき、付随的損害、間接損害、特別損害、将来の損害又は逸失利益にかかる損害については、賠償する責任を負わないものとします。
3. 本サービスに関連して利用者と他の利用者又は第三者との間において生じた取引、連絡、紛争等については、当社は一切の責任を負いません。

## 10 損害賠償

利用者が本規約に違反し、又は不正若しくは不当な行為によって当社に損害を与えた場合、当社は、当該利用者に対して損害賠償の請求を行うことができるものとし、利用者は、当社に生じた損害一切（弁護士費用等含む）について賠償するものとします。

## 11 反社会的勢力への不関与

利用者は、自身が暴力団、暴力団員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治運動標ぼうゴロ、特殊知能暴力集団、その他反社会的勢力に所属又は該当せず、かつ、これらの者と関与していないことを表明し、将来にわたっても所属若しくは該当、又は関与しないことを確約するものとします。

## 12 本規約等の変更

当社は、本規約を変更できるものとします。当社は、本規約を変更した場合には、当該変更内容を通知（アプリ内での表示を含む）するものとします。

## 13 通知

本サービスに関する当社から利用者に対する連絡又は通知は、当社の定める方法にて行うものとします。

## 14 利用契約上の地位の譲渡等
1. 利用者は、当社の書面のよる承諾なく、本規約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定その他処分をすることができません。
2. 当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本規約上の地位、本規約に基づく権利及び義務並びに利用者の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき、予め同意したものとみなします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。

## 15 分離可能性

本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約のその他の条項、及び一部が無効又は執行不能と判断された条項の残りの部分は、継続して完全にその効力を有するものとします。

## 16 準拠法及び合意管轄

本規約に関する裁判については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄とします。

<div style="text-align: right;">
以上
</div>`

export const defaultWelcomeTitle = '無料でAI肌診断'

export const defaultWelcomeContent = `簡単なアンケートと顔写真を撮るだけで
*あなたの素肌の状態*と
*肌や好みにあったおすすめコスメ*を
知ることができます！`

export const defaultWelcomeBtnLabel = '肌診断をはじめる'

export const contactContent = `
## 個人情報問合せ窓口

- **住所** : 東京都港区芝5-26-24 田町スクエア2F
- **宛先** : 株式会社Novera「個人情報問合せ窓口」
- **メールアドレス** : contact@novera.co.jp`

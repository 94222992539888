// eslint-disable-next-line max-classes-per-file
import { detectSingleFace, TinyFaceDetectorOptions, nets } from 'face-api.js'
import {
  createContext,
  FC,
  PropsWithChildren,
  useRef,
  MutableRefObject,
  useMemo,
  useContext,
  useEffect,
} from 'react'
import { usePageVisibility, loadImage } from 's2-lib'
import {
  OutlineVarint,
  makeLandmark,
  makeOutline,
} from '../helper/FaceDetector/Landmark'
import { clip, draw, getSize, toDataURL } from '../helper/Canvas'
import { OpenCV } from '../helper/OpenCV'
import { useProgress } from './useProgress'
import { EventEmitter } from '../helper/EventEmitter'
import { useVideoStream } from './useVideoStream'
import imgInitFaceUrl from '../assets/init_face.jpg'

type FaceDetectContextProps = {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>
  videoRef: MutableRefObject<HTMLVideoElement | null>
  detecter: FaceDetecter
  resize(): void
  start(): Promise<boolean>
  stop(): Promise<void>
  ready(
    assetDir?: string,
    onProgress?: (progress: number) => void
  ): Promise<void>
  toBase64(
    trimming: OutlineVarint | 'full'
  ): { origin: string; trimming: string } | undefined
  setDistFrame(distFrame: DistFrame): void
}

export type DistFrame = {
  root: Rect
  target: Rect
}

// 毎秒の計測回数
const DETECT_FPS = 10
const DETECT_SPF = 1000 / DETECT_FPS

let openCv: InstanceType<typeof OpenCV> | undefined

const detectOptions = new TinyFaceDetectorOptions({
  inputSize: 160,
})

class FaceDetecter extends EventEmitter<{
  detected(): void
  stop(): void
  start(): void
}> {
  private reqId = 0

  private detecting = false

  private startTime = 0

  public source?: CanvasSource

  public lastLandmark?: Landmark

  public lastEulerAngles?: EulerAngles

  public targetRect?: Rect

  constructor() {
    super()
    this.tick = this.tick.bind(this)
  }

  start(source: CanvasSource) {
    cancelAnimationFrame(this.reqId)
    this.detecting = false
    this.source = source
    this.startTime = performance.now()
    this.tick(this.startTime)
    this.emit('start')
  }

  stop() {
    // this.source = undefined
    cancelAnimationFrame(this.reqId)
    this.emit('stop')
  }

  private async tick(timestamp: number) {
    this.reqId = requestAnimationFrame(this.tick)

    if (this.detecting) {
      this.startTime = timestamp
    } else if (timestamp - this.startTime > DETECT_SPF) {
      this.startTime = timestamp
      this.detecting = true
      await this.detect()
      this.emit('detected')
      this.detecting = false
    }
  }

  private async detect() {
    const { source, targetRect } = this

    if (!source || !targetRect || !openCv) {
      return
    }

    const detection = await detectSingleFace(
      source,
      detectOptions
    ).withFaceLandmarks(true)

    // 顔が検出できない場合は処理終了
    if (!detection) {
      return
    }

    const landmark = makeLandmark(detection.landmarks)
    const eulerAngles = openCv.estimateEulerAngles(landmark)
    this.lastLandmark = landmark
    this.lastEulerAngles = eulerAngles
  }
}

const isActiveElement = <T extends HTMLElement>(
  target: T | null
): target is T => {
  const root = document.body
  let el = target?.parentNode
  while (el) {
    if (el === root) {
      return true
    }

    el = el.parentNode
  }
  return false
}

class PageVisiblityController {
  private detecting = false

  private props: FaceDetectContextProps

  constructor(props: FaceDetectContextProps) {
    this.props = props
    this.resume = this.resume.bind(this)
    this.pause = this.pause.bind(this)
  }

  pause() {
    const video = this.props.videoRef.current
    this.detecting = isActiveElement(video) && !video.paused
    this.props.stop()
  }

  resume() {
    if (this.detecting) {
      this.props.start()
    }
    this.detecting = false
  }
}

const converDistFrameOnSource = (
  source: CanvasSource,
  frame: DistFrame
): Rect => {
  const { width, height } = getSize(source)
  const {
    root: { w: rootW, h: rootH },
    target: { x, y, w, h },
  } = frame
  // ratio1以上が縦長、1以下が横長
  const sRatio = height / width
  const dRatio = rootH / rootW
  const ratio =
    dRatio === sRatio ? 1 : dRatio > sRatio ? height / rootH : width / rootW
  const dw = w * ratio
  const dh = h * ratio
  const dx = x * ratio + (width - rootW * ratio) / 2
  const dy = y * ratio + (height - rootH * ratio) / 2

  return {
    x: dx,
    y: dy,
    w: dw,
    h: dh,
  }
}

const convertLandmarkVideoToCanvas = (
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  landmark: Landmark
) => {
  const { videoWidth, videoHeight } = video
  const canvasWidth = canvas.width
  const canvasHeight = canvas.height

  if (videoWidth === canvasWidth && videoHeight === canvasHeight) {
    return landmark
  }

  const videoAspectRatio = videoWidth / videoHeight
  const canvasAspectRatio = canvasWidth / canvasHeight

  let offsetX = 0
  let offsetY = 0
  let scale = 1

  if (videoAspectRatio > canvasAspectRatio) {
    scale = canvasHeight / videoHeight
    offsetX = (canvasWidth - videoWidth * scale) / 2
  } else {
    scale = canvasWidth / videoWidth
    offsetY = (canvasHeight - videoHeight * scale) / 2
  }

  return Object.entries(landmark).reduce<Landmark>((acc, [key, value]) => {
    acc[key as keyof Landmark] = [
      value[0] * scale + offsetX,
      value[1] * scale + offsetY,
    ]
    return acc
  }, {})
}

export const detecter = new FaceDetecter()

let isReady = false

const ready = async (
  modelAssetsPath = '',
  onProgress: (progress: number) => void = () => {}
) => {
  if (isReady) {
    onProgress(1)
    return
  }

  isReady = true

  const [proceed1, proceed2, proceed3, proceed4, proceed5] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useProgress(5, (progress) => onProgress(progress))

  await Promise.all([
    // OpenCVのローディングと初期化
    (async () => {
      openCv = await OpenCV.create()
      proceed1()
    })(),
    // FaceAPIのローディングと初期化
    (async () => {
      const { tinyFaceDetector, faceLandmark68TinyNet } = nets

      // ローディング
      const [faceImage] = await Promise.all([
        loadImage(imgInitFaceUrl as unknown as string).then((image) => {
          proceed2()
          return image
        }),
        tinyFaceDetector.loadFromUri(modelAssetsPath).then(() => proceed3()),
        faceLandmark68TinyNet
          .loadFromUri(modelAssetsPath)
          .then(() => proceed4()),
      ])

      // 初期化（初回計測時にもろもろ初期化処理が走るため、一度目はready内で呼ぶ）
      // FIXME:本当はface-api処理はService Workerにしたい
      await detectSingleFace(faceImage, detectOptions).withFaceLandmarks(true)
      proceed5()
    })(),
  ])
}

const FaceDetectContext = createContext<FaceDetectContextProps | null>(null)

export const FaceDetectProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { create, cleanup } = useVideoStream()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const playPromiseRef = useRef<Promise<void> | undefined>()

  const props = useMemo<FaceDetectContextProps>(() => {
    const propObj: FaceDetectContextProps = {
      canvasRef,
      videoRef,
      detecter,
      async ready(assetDir, onProgress) {
        const video = videoRef.current
        const canvas = canvasRef.current
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [initProceed1, initProceed2] = useProgress(2, (progress) =>
          onProgress?.(progress)
        )

        if (video && canvas) {
          await Promise.all([
            ready(assetDir, initProceed1),
            create(video).then(() => initProceed2()),
          ])

          propObj.resize()
        }
      },
      resize() {
        const video = videoRef.current
        const canvas = canvasRef.current

        if (canvas && video) {
          const { width, height } = canvas.getBoundingClientRect()
          canvas.width = width
          canvas.height = height
          video.width = width
          video.height = height
          openCv?.updateCameraMatrix(width, height)
        }
      },
      async start() {
        try {
          const video = videoRef.current
          if (isActiveElement(video) && video.paused) {
            await (playPromiseRef.current = video.play())
            detecter.start(video)
          }
          return true
        } catch {
          return false
        }
      },
      async stop() {
        if (playPromiseRef.current) {
          // playメソッドのPromise解決中にpauseを呼ぶと AbortError が発生するので
          // 処理中のplayがあれば待つ
          await playPromiseRef.current
          playPromiseRef.current = undefined

          const video = videoRef.current

          if (isActiveElement(video) && !video.paused) {
            video.pause()
          }
        }

        detecter.stop()
      },
      toBase64(variant) {
        const landmark = detecter.lastLandmark
        const video = videoRef.current
        const originCanvas = canvasRef.current

        if (!video || !originCanvas || !landmark) {
          return undefined
        }

        const newLandmark = convertLandmarkVideoToCanvas(
          video,
          originCanvas,
          landmark
        )
        const trimmingClipRect = makeOutline('face', landmark)
        const originClipRect =
          variant === 'full' ? null : makeOutline(variant, newLandmark)
        const videoSize = getSize(video)
        const trimCanvas = document.createElement('canvas')
        const flipX = true

        trimCanvas.width = videoSize.width
        trimCanvas.height = videoSize.height

        draw(originCanvas, video, flipX)
        draw(trimCanvas, video, flipX)

        // drawで左右反転しているのでrectの座標も調整
        if (flipX) {
          trimmingClipRect.x =
            videoSize.width - trimmingClipRect.x - trimmingClipRect.w
          if (originClipRect) {
            originClipRect.x =
              originCanvas.width - originClipRect.x - originClipRect.w
          }
        }

        const origin = toDataURL(
          originClipRect ? clip(originCanvas, originClipRect) : originCanvas
        )
        const trimming = toDataURL(clip(trimCanvas, trimmingClipRect))

        return { origin, trimming }
      },
      setDistFrame(distFrame: DistFrame) {
        const video = videoRef.current
        detecter.targetRect = !video
          ? { x: 0, y: 0, w: 0, h: 0 }
          : converDistFrameOnSource(video, distFrame)
      },
    }

    return propObj
  }, [create])

  const pageVisiblityController = useMemo(
    () => new PageVisiblityController(props),
    [props]
  )

  usePageVisibility(
    pageVisiblityController.resume,
    pageVisiblityController.pause
  )

  useEffect(() => {
    const canvas = canvasRef.current
    const video = videoRef.current
    const resizeObserver = new ResizeObserver(props.resize)

    if (canvas) {
      resizeObserver.observe(canvas)
    }

    return () => {
      resizeObserver.disconnect()
      props.stop()
      cleanup(video)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FaceDetectContext.Provider value={props}>
      {children}
    </FaceDetectContext.Provider>
  )
}

export const useFaceDetect = () => useContext(FaceDetectContext)!

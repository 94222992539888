import { scaleLinear, scaleTime } from '@visx/scale'
import { LinePath } from '@visx/shape'
import { curveBasis } from '@visx/curve'
import { AxisLeft } from '@visx/axis'
import { FC } from 'react'
import { Group } from '@visx/group'
import DateAxis from './DateAxis'
import { Diagnosis } from '../../../../common/schemas/diagnosis'

export type SeriesData = {
  date: number
  value: number
}

export type Props = {
  width: number
  height: number
  items: Diagnosis[]
}

const Y_AXIS_SIZE = 30

const X_AXIS_SIZE = 21

const Y_LINES = [10, 20, 30, 40, 50, 60]

const SkinAgeGraph: FC<Props> = ({ width, height, items }) => {
  const seriesData = items.map<SeriesData>((data) => ({
    date: data.createdAt,
    value: data.age,
  }))
  const dates = seriesData.map(({ date }) => date)
  const xMin = Math.min(...dates)
  const xMax = Math.max(...dates)
  const xScale = scaleTime<number>({
    domain: [xMin, xMax],
    range: [0, width - Y_AXIS_SIZE],
  })
  const yScale = scaleLinear<number>({
    domain: [0, 70],
    range: [height - X_AXIS_SIZE, 0],
  })

  return (
    <svg width={width} height={height}>
      <Group left={Y_AXIS_SIZE}>
        {Y_LINES.map((y) => (
          <line
            key={y}
            x1={xScale(xMin)}
            x2={xScale(xMax)}
            y1={yScale(y)}
            y2={yScale(y)}
            stroke="#efefef"
          />
        ))}
        <LinePath<SeriesData>
          curve={curveBasis}
          data={seriesData}
          x={(d) => xScale(d.date)}
          y={(d) => yScale(d.value)}
          stroke="#F48FB1"
          strokeWidth={2}
          shapeRendering="auto"
        />
        <AxisLeft
          hideTicks
          hideZero
          scale={yScale}
          numTicks={6}
          tickFormat={(value, i, values) =>
            values.length - 1 === i ? '' : `${value}歳`
          }
          strokeWidth={2}
          stroke="#ccc"
          tickStroke="#ccc"
          tickLabelProps={() => ({
            fill: '#49454F',
            fontSize: 11,
            fontWeight: '500',
            textAnchor: 'end',
            dx: '2px',
            dy: '3px',
          })}
        />
        <DateAxis top={height - X_AXIS_SIZE} scale={xScale} />
      </Group>
    </svg>
  )
}

export default SkinAgeGraph

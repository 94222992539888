import { alpha } from '@mui/material'

const REGX = /rgba\(([.,\s\d]+)\)/g

export const createBoxShadow = (
  baseBoxShadow: string,
  color: string,
  factor = 1
) =>
  baseBoxShadow.replace(REGX, (_, matched) =>
    alpha(color, parseFloat(matched.split(',')[3] ?? '1') * factor)
  )

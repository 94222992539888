export default () => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
  >
    <path d="M18.7 5.9 14 3.2c-.6-.4-1.3-.5-2-.5s-1.4.2-2 .5L5.3 5.9c-.6.3-1.1.8-1.4 1.4-.4.6-.5 1.3-.5 2v5.4c0 .7.2 1.4.5 2 .4.6.9 1.1 1.5 1.5l4.7 2.7c.6.4 1.3.5 2 .5s1.4-.2 2-.5l4.7-2.7c.6-.4 1.1-.9 1.5-1.5.4-.6.5-1.3.5-2V9.3c0-.7-.2-1.4-.5-2-.5-.6-1-1.1-1.6-1.4zm0 8.8c0 .4-.1.7-.3 1-.2.3-.4.6-.7.7l-.8.5c-1.5-.9-3.2-1.4-4.9-1.4-1.7 0-3.4.5-4.9 1.4l-.8-.4c-.3-.3-.5-.5-.7-.8-.2-.3-.3-.6-.3-1V9.3c0-.4.1-.7.3-1 .2-.3.4-.5.7-.7L11 4.9c.3-.2.6-.3 1-.3s.7.1 1 .3l4.7 2.7c.3.2.5.4.7.7.2.3.3.6.3 1v5.4zm-3.8-4.1c0 .6-.2 1.2-.5 1.7s-.8.9-1.3 1.1c-.5.2-1.2.3-1.7.2-.6-.1-1.1-.4-1.5-.8-.4-.4-.7-1-.8-1.5-.2-.7-.2-1.3.1-1.9.2-.5.6-1 1.1-1.3.5-.3 1.1-.5 1.7-.5.4 0 .8.1 1.1.2.4.2.7.4 1 .7.3.3.5.6.7 1 .1.3.1.7.1 1.1z" />
  </svg>
)

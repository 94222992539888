import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Group } from '@visx/group'
import { scaleLinear } from '@visx/scale'
// import { Point } from '@visx/point'
import { LineRadial } from '@visx/shape'
import { ScaleLinear } from 'd3-scale'
import { usePrevious } from 's2-lib'

const degrees = 360

const sin = (num: number) => {
  if (Math.PI % num) {
    return Math.sin(num)
  }

  return Math.sin(num + 0.001)
}

const cos = (num: number) => {
  if (Math.PI % num) {
    return Math.cos(num)
  }

  return Math.cos(num + 0.0001)
}

const round = (num: number) => Math.round(num * 1000) / 1000

const genAngles = (length: number) =>
  [...new Array(length + 1)].map((_, i) => ({
    angle: i * (degrees / length),
  }))

const genLabelPoints = (labels: string[], radius: number) => {
  const step = (Math.PI * 2) / labels.length
  const adjustAngle = Math.PI
  return labels.map((label, i) => ({
    label,
    x: round(radius * sin(i * step - adjustAngle)),
    y: round(radius * cos(i * step - adjustAngle)),
  }))
}

function genPolygonPoints(
  { values }: RadarChartData,
  yScale: ScaleLinear<number, number>
) {
  const len = values.length
  const step = (Math.PI * 2) / len
  const points: { x: number; y: number }[] = []
  const adjustAngle = Math.PI
  const pointString = values.reduce((res, value, i) => {
    if (value === null) {
      return `${res}${0},${0} `
    }

    const scale = yScale(value)
    const x = round(scale * sin(i * step - adjustAngle))
    const y = round(scale * cos(i * step - adjustAngle))
    points[i] = { x, y }
    return `${res}${x},${y} `
  }, '')

  return { points, pointString }
}

function fillZero(strs: string[]) {
  return strs.map((str) =>
    str
      .trim()
      .split(' ')
      .map((str2) =>
        str2
          .split(',')
          .map(() => 0)
          .join(',')
      )
      .join(' ')
  )
}

/**
 * #RRGGBB or rgb(R,G,B) を rgba(R,G,B,alpha) に変換
 * @param color #RRGGBB or rgb(R,G,B)
 * @param alpha
 * @returns
 */
function rgbWithAlpha(color: string, alpha: number) {
  if (color.startsWith('#')) {
    // 3桁の場合は6桁に変換
    const hexCode =
      color.length === 4
        ? `${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`
        : color.slice(1)
    const r = parseInt(hexCode.slice(0, 2), 16)
    const g = parseInt(hexCode.slice(2, 4), 16)
    const b = parseInt(hexCode.slice(4, 6), 16)
    return `rgba(${r},${g},${b},${alpha})`
  }

  const [r, g, b] = color
    .slice(4, -1)
    .split(',')
    .map((v) => parseInt(v.trim(), 10))
  return `rgba(${r},${g},${b},${alpha})`
}

const defaultMargin = { top: 0, left: 0, right: 0, bottom: 0 }

type RadarChartData = {
  values: (number | null)[]
  color: string
}

export type RadarChartProps = {
  labelData: {
    fontSize: number
    values: string[]
  }
  dataArray: RadarChartData[]
  levels?: number
  margin?: Record<'top' | 'left' | 'right' | 'bottom', number>
}

export const RadarChart = forwardRef<SVGSVGElement, RadarChartProps>(
  ({ labelData, dataArray, levels = 5, margin = defaultMargin }, ref) => {
    const rootRef = useRef<SVGSVGElement | null>(null)
    const [{ width, height }, setSize] = useState({ width: 0, height: 0 })
    const areaW = width - margin.left - margin.right
    const areaH = height - margin.top - margin.bottom
    const radius = Math.max(Math.min(areaW, areaH), 0.001) / 2
    const radialScale = scaleLinear<number>({
      range: [0, Math.PI * 2],
      domain: [degrees, 0],
    })
    const yScale = scaleLinear<number>({
      range: [radius / levels, radius],
      domain: [0, 1],
    })
    const { values: labels, fontSize: labelFontSize } = labelData
    const webs = genAngles(labels.length)
    const labelPoints = genLabelPoints(labels, radius + labelFontSize * 0.25)
    const polygonPoints = useMemo(
      () => dataArray.map((data) => genPolygonPoints(data, yScale)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dataArray, width, height]
    )
    const currentPoints = useMemo(
      () => polygonPoints.map((a) => a.pointString),
      [polygonPoints]
    )
    const prevPoints = usePrevious(currentPoints) ?? fillZero(currentPoints)

    useEffect(() => {
      const parent = rootRef.current?.parentElement
      const resizeObserver = new ResizeObserver(() => {
        const rect = parent?.getBoundingClientRect()
        if (rect) {
          setSize({
            width: Math.floor(rect.width),
            height: Math.floor(rect.height),
          })
        }
      })

      if (parent) {
        resizeObserver.observe(parent)
      }

      return () => resizeObserver.disconnect()
    }, [])

    useEffect(() => {
      rootRef.current?.setCurrentTime(0)
    }, [currentPoints, prevPoints])

    useImperativeHandle(ref, () => rootRef.current!)

    return (
      <svg ref={rootRef} width={width} height={height}>
        <Group top={margin.top + areaH / 2} left={margin.left + areaW / 2}>
          {/* 背景の目盛り */}
          {[...new Array(levels)].map((_, i) => (
            <LineRadial
              // eslint-disable-next-line react/no-array-index-key
              key={`radial-${i}`}
              data={webs}
              angle={(d) => radialScale(d.angle) ?? 0}
              radius={((levels - i) * radius) / levels}
              fill="#fff"
              stroke="#ddd"
              strokeWidth={1}
              strokeLinecap="round"
            />
          ))}
          {/* 実際のパラメーター */}
          {currentPoints.map((str, i) => (
            <polygon
              // eslint-disable-next-line react/no-array-index-key
              key={`point-${i}`}
              fill={rgbWithAlpha(dataArray[i].color, 0.75)}
              points=""
              stroke="none"
              strokeWidth={1}
              style={{ mixBlendMode: 'multiply' }}
            >
              <animate
                attributeName="points"
                dur="0.5s"
                calcMode="spline"
                keyTimes="0;1"
                keySplines="0.5,0,0.5,1"
                from={prevPoints[i]}
                to={str}
                fill="freeze"
              />
            </polygon>
          ))}
          {/* 項目のラベル */}
          {labelPoints.map(({ x, y, label }) => {
            const textAnchor =
              Math.round(x) === 0 ? 'middle' : x > 0 ? 'start' : 'end'
            return (
              <text
                key={label}
                x={Math.round(x)}
                y={Math.round(y) * 1.08}
                dy={labelFontSize / 4}
                fontSize={`${labelFontSize}px`}
                textAnchor={textAnchor}
              >
                {label}
              </text>
            )
          })}
          {/* 目盛りのラベル */}
          {[...new Array(levels)].map((_, i) => {
            const rank = ['A', 'B', 'C', 'D', 'E', ''].reverse()[i]

            return (
              <text
                // eslint-disable-next-line react/no-array-index-key
                key={`rank-${i}`}
                x={10}
                y={(radius / levels) * -i - 12}
                fontSize="8px"
                textAnchor="middle"
                fill="#999"
              >
                {rank}
              </text>
            )
          })}
        </Group>
      </svg>
    )
  }
)

import { z } from 'zod'

const REGX_LOWER_CASE = /.*[a-z].*/
const REGX_UPPER_CASE = /.*[A-Z].*/
const REGX_NUMBER = /.*[\d].*/
const REGX_SPECIAL_CHARS = /.*[`~<>?,.!@#$%^&*()-_+="'|{};:[\]/\\].*/

export const passwordSchema = z
  .string()
  .optional()
  .refine((v) => !v || v.length >= 8, '8文字以上で入力してください')
  .refine((v) => !v || v.length <= 64, '64文字以内で入力してください')
  .refine(
    (v) =>
      !v ||
      (REGX_LOWER_CASE.test(v) &&
        REGX_UPPER_CASE.test(v) &&
        REGX_NUMBER.test(v) &&
        REGX_SPECIAL_CHARS.test(v)),
    '大英字、小英字、数字、記号をそれぞれ最低1つ以上含めてください'
  )

export const passwordRequiredSchema = z
  .string()
  .min(1, '必須項目です')
  .min(8, '8文字以上で入力してください')
  .max(64, '64文字以内で入力してください')
  .refine(
    (v) =>
      REGX_LOWER_CASE.test(v) &&
      REGX_UPPER_CASE.test(v) &&
      REGX_NUMBER.test(v) &&
      REGX_SPECIAL_CHARS.test(v),
    '大英字、小英字、数字、記号をそれぞれ最低1つ以上含めてください'
  )

export type Password = z.infer<typeof passwordSchema>
export type PasswordRequired = z.infer<typeof passwordRequiredSchema>

import { FC } from 'react'
import { Box, BoxProps, Typography, styled, keyframes } from '@mui/material'
import { parseMarkdown } from 's2-lib'
import { ElementToMUI } from '../ElementToMUI'

type Props = {
  title: string
  content: string
  kvPath: string
} & BoxProps

const heroBgAnim = keyframes`
0% {
  opacity: 0;
  background-size: 120% auto;
}

50% {
  background-size: 100% auto;
  filter: none;
  opacity: 1;
}

100% {
  background-size: 100% auto;
  filter: blur(10px);
  opacity: 0.75;
}
`

const heroContentAnim = keyframes`
0% {
  opacity: 0;
}

100% {
  opacity: 1;
}
`

const Root = styled(Box)`
  overflow: hidden;
  position: relative;
`

const Lead = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 500,
  'em, strong': {
    color: theme.palette.secondary.main,
    fontStyle: 'normal',
    fontWeight: 500,
  },
}))

const Background = styled(Box)`
  opacity: 0;
  max-height: 320px;
  height: 100vw;
  background: 50% 50% no-repeat;
  background-size: 120% auto;
  animation: ${heroBgAnim} 5s ease-out forwards;
`
const Content = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  animation: ${heroContentAnim} 1s ease-out 3s forwards;
`

export const WelcomeHero: FC<Props> = ({
  title,
  content,
  kvPath,
  ...props
}) => (
  <Root {...props}>
    <Background sx={{ backgroundImage: `url(${kvPath})` }} />
    <Content>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1" mx={4} fontWeight={700} color="secondary">
          {title}
        </Typography>
        <Lead>
          <ElementToMUI html={parseMarkdown(content)} />
        </Lead>
      </Box>
    </Content>
  </Root>
)

import { styled } from '@mui/material'

export const OlList = styled('ol')(({ theme }) => ({
  listStyle: 'decimal',
  margin: theme.spacing(0, 0, 0, 2),
  paddingLeft: 0,
})) as any

export const UlList = styled('ul')(({ theme }) => ({
  listStyle: 'disc',
  margin: theme.spacing(0, 0, 0, 2),
  paddingLeft: 0,
})) as any

export const ListItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 1),
})) as any

export const Table = styled('table')({
  width: '100%',
  borderSpacing: 0,
  textAlign: 'left',
}) as any

export const TableTr = styled('tr')(({ theme }) => ({
  display: 'block',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: '0.05rem solid #ccc',
})) as any

export const TableTh = styled('th')(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(2),
  color: theme.palette.grey['500'],
  ...theme.typography.body1,
})) as any

export const TableTd = styled('td')(({ theme }) => ({
  display: 'block',
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
})) as any

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'
import { useCallback } from 'react'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'
import { parseOptions, FieldProps, OptionValue } from './helper'

const CheckBoxField = <T extends FieldValues, N extends Path<T>>({
  name,
  options,
  size,
  fullWidth,
}: FieldProps<T, N>) => {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext<T>()
  const defaultValue = defaultValues?.[name]
  const parsedOption = parseOptions(options)
  const handleChange = useCallback(
    (value: OptionValue, currentValues?: OptionValue | OptionValue[]) => {
      const newValues = currentValues
        ? Array.isArray(currentValues)
          ? currentValues
          : [currentValues]
        : ([] as unknown as OptionValue[])
      const index = newValues.indexOf(value)
      if (index === -1) {
        newValues.push(value)
      } else {
        newValues.splice(index, 1)
      }

      return newValues
    },
    []
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          error={!!fieldState.error}
          size={size}
          fullWidth={fullWidth}
          sx={{
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
        >
          {parsedOption.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              label={label}
              control={
                <Checkbox
                  value={value}
                  onChange={() =>
                    field.onChange(handleChange(value, field.value))
                  }
                  defaultChecked={defaultValue?.includes(value)}
                />
              }
            />
          ))}
          {fieldState.error && (
            <FormHelperText>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}

export default CheckBoxField

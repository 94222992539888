import { styled } from '@mui/material'

export type StickyFooterProps = {
  top?: boolean | number | string
  bottom?: boolean | number | string
}

export const StickyFooter = styled('div')<StickyFooterProps>(
  ({ top, bottom, theme }) => {
    const pos =
      top !== undefined
        ? {
            top:
              typeof top === 'string'
                ? top
                : typeof top === 'number'
                ? theme.spacing(top)
                : 0,
          }
        : {
            bottom:
              typeof bottom === 'string'
                ? bottom
                : typeof bottom === 'number'
                ? theme.spacing(bottom)
                : 0,
          }

    return {
      ...pos,
      position: 'absolute',
      // position: 'sticky',
      left: 0,
      width: '100%',
      // backdropFilter: 'blur(10px)',
      // backgroundColor: 'rgba(255,255,255,0.5)',
      backgroundColor: '#fff',
      boxShadow: theme.shadows[4],
    }
  }
)

import { FC, useMemo } from 'react'
import { useCounselingWithDefaultValues } from '../common/hooks/useCounseling'
import { usePageview } from '../common/hooks/useGA'
import { Authentication } from '../components/functional/Authentication'
import Redirect from '../components/functional/Redirect'
import { TheFooter } from '../components/layouts/TheFooter'
import { TheHeader } from '../components/layouts/TheHeader'
import { Form } from '../components/pages/counseling/Form'
import { useProjectValue } from '../common/hooks/useProject'

const PageComponent: FC = () => {
  const { items, defaultValues, validateResolver } =
    useCounselingWithDefaultValues()
  const {
    history: {
      features: { additionalPicts },
    },
  } = useProjectValue()
  const isRedirect = items.length === 0
  const maxPage = useMemo(() => {
    const shootingVariants = Array.from(new Set(['front', ...additionalPicts]))
    return 1 + shootingVariants.length
  }, [additionalPicts])

  usePageview({ ignore: isRedirect })

  // 設問が1つもない場合は顔写真に移動
  if (isRedirect) {
    return <Redirect to="/shooting" replace />
  }

  return (
    <>
      <TheHeader backPath="/">カウンセリング（1/{maxPage}）</TheHeader>
      <Form
        items={items}
        defaultValues={defaultValues}
        validateResolver={validateResolver}
      />
      <TheFooter mt={12} mb={24} />
    </>
  )
}

export default () => (
  <Authentication>
    <PageComponent />
  </Authentication>
)

import { Box, BoxProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export const InlineCode: FC<PropsWithChildren<BoxProps>> = ({
  children,
  sx,
  ...props
}) => (
  <Box
    component="span"
    sx={{
      display: 'inline-flex',
      color: 'primary.main',
      backgroundColor: '#efefef',
      border: 'thin solid #ccc',
      p: 2,
      lineHeight: '1',
      borderRadius: 1,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
)

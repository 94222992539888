import sanitizeHtml from 'sanitize-html'
import { Paper, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { Feedback } from 's2-lib'

type CommentFeedbackProps = {
  id?: Feedback['id']
  content: Feedback['content']
}

const CommentFeedback: FC<CommentFeedbackProps> = ({
  id,
  content: { title, comment },
}) => {
  const sanitizedComment = useMemo(
    () =>
      comment
        ? sanitizeHtml(comment.replaceAll('\n', '<br>'), {
            allowedTags: ['br'],
          })
        : '',
    [comment]
  )

  return (
    <Paper
      id={id}
      sx={{ p: 4, borderRadius: 3, boxShadow: (theme) => theme.shadows[4] }}
    >
      <Typography variant="h3" color="primary.main" fontWeight={700} mt={1}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        textAlign="justify"
        dangerouslySetInnerHTML={{
          __html: sanitizedComment,
        }}
      />
    </Paper>
  )
}

export default CommentFeedback

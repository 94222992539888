import { FaceLandmarks68 } from 'face-api.js'
import { KalmanFilter } from './KalmanFilter'

const kFilter: Record<LandmarkName, KalmanFilter> = {
  nose: new KalmanFilter(),
  leftEyeBrow: new KalmanFilter(),
  rightEyeBrow: new KalmanFilter(),
  leftEye: new KalmanFilter(),
  rightEye: new KalmanFilter(),
  jaw: new KalmanFilter(),
  leftMouth: new KalmanFilter(),
  rightMouth: new KalmanFilter(),
  upperLip: new KalmanFilter(),
  lowerLip: new KalmanFilter(),
  leftOutline: new KalmanFilter(),
  rightOutline: new KalmanFilter(),
}

/**
 * ポイントをフォーマットする
 */
export const formatPoint = (
  name: LandmarkName,
  point: { x: number; y: number }
): Point => {
  const [x, y] = kFilter[name].filter([point.x, point.y])
  return [x, y]
}

/**
 * アウトラインを作成する
 */
export type OutlineVarint = 'face' | 'nose'

export const makeOutline = (
  variant: OutlineVarint,
  landmark: Landmark
): Rect => {
  switch (variant) {
    case 'nose': {
      // 鼻のみにトリミング（上は目の下、下は上唇の上）
      const { nose, leftEye, rightEye, leftMouth, rightMouth, upperLip } =
        landmark

      if (
        !leftEye ||
        !rightEye ||
        !nose ||
        !upperLip ||
        !leftMouth ||
        !rightMouth
      ) {
        throw new Error('Invalid landmark')
      }

      const [noseX, noseY] = nose
      const eyeY = Math.max(leftEye[1], rightEye[1])
      const leftX = Math.min(leftEye[0], leftMouth[0])
      const rightX = Math.max(rightEye[0], rightMouth[0])
      const minX = leftX + (noseX - leftX) * 0.5
      const maxX = noseX + (rightX - noseX) * 0.5
      const minY = eyeY + (noseY - eyeY) * 0.25
      const maxY = noseY + (upperLip[1] - noseY) * 0.5

      return {
        x: minX,
        y: minY,
        w: maxX - minX,
        h: maxY - minY,
      }
    }
    case 'face': {
      const { leftOutline, rightOutline, leftEyeBrow, rightEyeBrow, jaw } =
        landmark
      const minX = leftOutline?.[0] ?? 0
      const maxX = rightOutline?.[0] ?? 0
      const minY = Math.min(leftEyeBrow?.[1] ?? 0, rightEyeBrow?.[1] ?? 0)
      const maxY = jaw?.[1] ?? 0

      return {
        x: minX,
        y: minY,
        w: maxX - minX,
        h: maxY - minY,
      }
    }
    default:
      throw new Error(`Invalid trimming: ${variant}`)
  }
}

export const makeLandmark = (landmarks68: FaceLandmarks68): Landmark => {
  if (!landmarks68) {
    return {}
  }

  const nose = landmarks68.getNose()
  const leftEye = landmarks68.getLeftEye()
  const rightEye = landmarks68.getRightEye()
  const leftEyeBrow = landmarks68.getLeftEyeBrow()
  const rightEyeBrow = landmarks68.getRightEyeBrow()
  const jawOutline = landmarks68.getJawOutline()
  const mouth = landmarks68.getMouth()

  return {
    nose: formatPoint('nose', nose[3]),
    leftEye: formatPoint('leftEye', leftEye[0]),
    rightEye: formatPoint('rightEye', rightEye[3]),
    leftEyeBrow: formatPoint('leftEyeBrow', leftEyeBrow[2]),
    rightEyeBrow: formatPoint('rightEyeBrow', rightEyeBrow[2]),
    jaw: formatPoint('jaw', jawOutline[8]),
    leftMouth: formatPoint('leftMouth', mouth[0]),
    rightMouth: formatPoint('rightMouth', mouth[6]),
    upperLip: formatPoint('upperLip', mouth[14]),
    lowerLip: formatPoint('lowerLip', mouth[18]),
    leftOutline: formatPoint('leftOutline', jawOutline[0]),
    rightOutline: formatPoint('rightOutline', jawOutline[16]),
  }
}

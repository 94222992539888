const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')!
const image = new Image()

export const svgToBase64 = async (svgElement: SVGSVGElement) =>
  new Promise<string>((resolve, reject) => {
    const clone = svgElement.cloneNode(true) as SVGSVGElement
    const ratio = window.devicePixelRatio
    const width = clone.width.baseVal.value
    const height = clone.height.baseVal.value
    const ratioW = width * ratio
    const ratioH = height * ratio
    clone.viewBox.baseVal.width = width
    clone.viewBox.baseVal.height = height
    clone.style.width = `${ratioW}px`
    clone.style.height = `${ratioH}px`
    canvas.width = ratioW
    canvas.height = ratioH
    image.onload = () => {
      ctx.drawImage(image, 0, 0, ratioW, ratioH)
      resolve(canvas.toDataURL('image/png', 1))
    }
    image.onerror = reject
    // SVGデータを取り出す
    const svgData = new XMLSerializer().serializeToString(clone)
    image.src = `data:image/svg+xml;charset=utf-8;base64,${btoa(
      unescape(encodeURIComponent(svgData))
    )}`
  })

import { styled } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

const StyledSection = styled('section')(({ theme }) => ({
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(6),
  marginBottom: theme.spacing(12),
  '&:last-child': {
    marginBottom: theme.spacing(0),
  },
}))

export type SectionProps = PropsWithChildren<{}>

export const Section: FC<SectionProps> = ({ children, ...props }) => (
  <StyledSection {...props}>{children}</StyledSection>
)

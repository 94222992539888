import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import { useCallback } from 'react'
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form'
import { parseOptions, FieldProps } from './helper'

const SelectBoxField = <T extends FieldValues, N extends Path<T>>({
  id,
  label,
  name,
  options,
  size,
  fullWidth,
}: FieldProps<T, N>) => {
  const { control } = useFormContext<T>()
  const parsedOption = parseOptions(options)
  const renderValue: SelectProps<string | string[]>['renderValue'] =
    useCallback(
      (selected: string | string[]) => {
        if (!selected) {
          return '選択してください'
        }

        return parsedOption.find(({ value }) => value === selected)?.label
      },
      [parsedOption]
    )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value: val, ...field }, fieldState }) => (
        <FormControl
          size={size}
          fullWidth={fullWidth}
          error={!!fieldState.error}
        >
          {label ? <InputLabel id={id}>{label}</InputLabel> : null}
          <Select
            labelId={id}
            label={label}
            displayEmpty
            renderValue={renderValue}
            value={val || ''}
            {...field}
          >
            {parsedOption.map((state) => (
              <MenuItem key={state.value} value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </Select>
          {fieldState.error && (
            <FormHelperText>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}

export default SelectBoxField

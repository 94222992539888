type RGB = [number, number, number]

type HexColor = string

// 16進数のhexカラーをRGBに変換する
export const hexToRgb = (hex: HexColor): RGB => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (!result) {
    throw new Error('Invalid color format')
  }
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ]
}

// 前面色と背景色を指定して、アルファブレンディングした不透明の色を返す
export const alphaBlendingColors = (
  foregroundColor: HexColor,
  alpha: number,
  backgroundColor: HexColor = '#ffffff'
): RGB => {
  const [fr, fg, fb] = hexToRgb(foregroundColor)
  const [br, bg, bb] = hexToRgb(backgroundColor)
  return [
    Math.round(fr * alpha + br * (1 - alpha)),
    Math.round(fg * alpha + bg * (1 - alpha)),
    Math.round(fb * alpha + bb * (1 - alpha)),
  ]
}

import { diagnosisSchema as baseSchema } from 's2-lib'
import { z } from 'zod'
import { assetImagePathSchema } from './assetImagePath'
import { feedbackSchema } from './feedback'
import { productSchema } from './product'
import { productCategorySchema } from './productCategory'

export const diagnosisSchema = baseSchema
  .omit({
    faceImagePath: true,
    additionalImagePaths: true,
  })
  .extend({
    faceImagePath: assetImagePathSchema,
    additionalImagePaths: z.record(assetImagePathSchema).optional(),
  })

export const diagnosisResultSchema = z.object({
  item: diagnosisSchema,
  rewards: z.object({
    feedbacks: feedbackSchema.array(),
    products: productSchema.array(),
    productCategories: productCategorySchema.array(),
  }),
})

export type Diagnosis = z.infer<typeof diagnosisSchema>
export type DiagnosisResult = z.infer<typeof diagnosisResultSchema>

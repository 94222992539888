import { IconButton, IconButtonProps } from '@mui/material'
import { forwardRef } from 'react'
import { MaterialIcon } from '../MaterialIcon'

export type MaterialIconButtonProps = IconButtonProps & {
  icon: string
}

export const MaterialIconButton = forwardRef<
  HTMLButtonElement,
  MaterialIconButtonProps
>(({ icon, ...props }, ref) => (
  <IconButton ref={ref} {...props}>
    <MaterialIcon>{icon}</MaterialIcon>
  </IconButton>
))

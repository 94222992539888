import { useEffect, useState } from 'react'

export const useIntersectionObserver = (el: HTMLElement | null) => {
  const [isIntersecting, setIntersection] = useState(false)

  useEffect(() => {
    if (!el) {
      return undefined
    }

    const obs = new IntersectionObserver((entries) =>
      setIntersection(entries[0].isIntersecting)
    )
    obs.observe(el)
    return () => obs.disconnect()
  }, [el])

  return isIntersecting
}

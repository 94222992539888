import { z } from 'zod'

const COLOR_REGX = /^#([a-fA-F\d]{3}|[a-fA-F\d]{6})$/

export const colorSchema = z
  .string()
  .refine(
    (v) => COLOR_REGX.test(v),
    '#から始まる16進数のカラーコードを指定してください'
  )

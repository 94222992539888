import { Grid, styled, Typography } from '@mui/material'

import { FC, useMemo, useCallback, useEffect, useRef } from 'react'

import type { ProductCategory, Project } from 's2-lib'
import { MAX_PRODUCT_CATEGORY_PRIORITY } from 's2-lib'

import RecommendItem, { RecommendItemProps } from './RecommendItem'

export type RecommendListProps = Omit<Project['product'], 'visibleItem'> &
  Project['product']['visibleItem'] & {
    items?: RecommendItemProps['item'][]
    productCategories?: ProductCategory[]
    linkLabel: string
  }

type Group = {
  id: string
  name: string
  priority: number
}
type ItemsByGroup = (Group & {
  items: RecommendItemProps['item'][]
})[]

const NON_GROUP_ID = '__none__'

/**
 * グループを初期化する
 * @param groups グループ
 * @param nonGroupPriority その他の優先度
 * @param nonGroupName その他の名前
 * @returns グループ
 */
const initializeGroups = (
  groups: Group[],
  nonGroupPriority = MAX_PRODUCT_CATEGORY_PRIORITY,
  nonGroupName = 'その他'
): Group[] =>
  groups
    .concat({
      id: NON_GROUP_ID,
      name: nonGroupName,
      priority: nonGroupPriority,
    })
    .sort((a, b) => a.priority - b.priority)

/**
 * 商品をグループ化する
 * @param groups グループ
 * @param items 商品
 * @param getGroupIds 商品からグループIDを取得する関数
 * @returns グループ化された商品
 */
const createItemsByGroupBase = (
  groups: Group[],
  items: RecommendItemProps['item'][],
  getGroupIds: (item: RecommendItemProps['item']) => string[]
): ItemsByGroup => {
  const itemsByGroup: ItemsByGroup = groups.map((group) => ({
    ...group,
    items: [],
  }))

  items.forEach((item) => {
    const groupIds = getGroupIds(item).sort((a, b) => {
      const aPriority = itemsByGroup.find((g) => g.id === a)?.priority
      const bPriority = itemsByGroup.find((g) => g.id === b)?.priority
      return (
        (aPriority ?? MAX_PRODUCT_CATEGORY_PRIORITY) -
        (bPriority ?? MAX_PRODUCT_CATEGORY_PRIORITY)
      )
    })
    const groupId = groupIds[0] || NON_GROUP_ID

    itemsByGroup.find((g) => g.id === groupId)?.items.push(item)
  })

  return itemsByGroup
}

/**
 * グループ化された商品の表示数を制限する
 * @param itemsByGroup グループ化された商品
 * @param maxNumOf 最大表示数
 * @param maxNumOfInGroup グループ内の最大表示数
 * @returns 表示数が制限されたグループ化された商品
 */
const restrictItemsByGroup = (
  itemsByGroup: ItemsByGroup,
  maxNumOf: number,
  maxNumOfInGroup?: number
): ItemsByGroup => {
  let remaining = maxNumOf

  const result: ItemsByGroup = itemsByGroup.reduce<ItemsByGroup>(
    (acc, group) => {
      if (remaining <= 0) {
        return acc
      }

      const maxLength = Math.min(
        typeof maxNumOfInGroup === 'number' && maxNumOfInGroup > 0
          ? maxNumOfInGroup
          : maxNumOf,
        remaining
      )

      const items =
        maxLength < group.items.length
          ? group.items.slice(0, maxLength)
          : group.items

      remaining -= items.length

      return [...acc, { ...group, items }]
    },
    []
  )

  return result
}

/**
 * グループ化された商品を作成するファクトリ関数
 * @param items 商品
 * @param maxNumOf 最大表示数
 * @param maxNumOfInGroup グループ内の最大表示数
 * @param nonGroupPriority その他の優先度
 * @param groups グループ
 * @param getGroupIds 商品からグループIDを取得する関数
 * @param nonGroupName その他の名前
 */
const createItemsByGroupFactory =
  (
    items: RecommendItemProps['item'][],
    maxNumOf: number,
    maxNumOfInGroup?: number,
    nonGroupPriority?: number
  ) =>
  (
    groups: Group[] | undefined,
    getGroupIds: (item: RecommendItemProps['item']) => string[],
    nonGroupName?: string
  ): ItemsByGroup => {
    if (items?.length < 1) {
      return []
    }

    const itemsByGroup = createItemsByGroupBase(
      initializeGroups(groups ?? [], nonGroupPriority, nonGroupName),
      items,
      getGroupIds
    )

    return restrictItemsByGroup(itemsByGroup, maxNumOf, maxNumOfInGroup)
  }

const GridItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  textAlign: 'left',
  '&.column': {
    '&-1': {
      '.RecommendItem__name': {
        minHeight: `${14 * 1.14}px`,
      },
    },
    '&-2': {
      '.RecommendItem__name': {
        minHeight: `${14 * 1.14 * 2}px`,
      },
    },
  },
})

const RecommendList: FC<RecommendListProps> = ({
  columns = 2,
  maxNumOf = 10,
  groupBy,
  maxNumOfInGroup,
  nonGroupPriority = MAX_PRODUCT_CATEGORY_PRIORITY,
  items,
  productCategories: categories,
  linkBehavior = 'blank',
  linkLabel,
  ...visibles
}) => {
  const ref = useRef<HTMLDivElement>(null)
  // グループ化された商品を作成する
  const createItemsByGroup = useCallback<
    ReturnType<typeof createItemsByGroupFactory>
  >(
    (groups, getGroupIds) =>
      createItemsByGroupFactory(
        items || [],
        maxNumOf,
        maxNumOfInGroup,
        nonGroupPriority
      )(groups, getGroupIds),
    [items, maxNumOf, maxNumOfInGroup, nonGroupPriority]
  )

  // 商品カテゴリでグループ化する
  const groupByCategory = useCallback(
    (): ItemsByGroup =>
      createItemsByGroup(
        // グループ化の元になる情報
        categories,
        // 商品からグループのIDを取得する関数
        (item) => item.categories,
        // グループIDが存在しない場合の名前
        'その他'
      ),
    [createItemsByGroup, categories]
  )

  // グループ化された商品
  const itemsByGroup = useMemo<ItemsByGroup>(() => {
    // グループ化の種類が増えたらここに追加する
    switch (groupBy) {
      case 'category':
        return groupByCategory()
      default:
        return []
    }
  }, [groupBy, groupByCategory])

  const usedGroup = groupBy !== null && !!itemsByGroup.length

  useEffect(() => {
    const targetClassNames = ['.RecommendItem__brand', '.RecommendItem__name']

    function fitElementHeight(recommendItems: RecommendItemProps['item'][]) {
      recommendItems
        // idを元にelementを取得
        .map((item) => document.getElementById(item.id))
        // nullを除外
        .filter((element): element is HTMLElement => element !== null)
        // column数でelementsを分割
        .reduce((acc, element, index) => {
          const i = Math.floor(index / columns)
          if (!acc[i]) {
            acc[i] = []
          }
          acc[i].push(element)
          return acc
        }, [] as HTMLElement[][])
        .forEach((elements) => {
          targetClassNames.forEach((targetClassName) => {
            const targetElements = elements.map((el) => {
              const target = el.querySelector<HTMLElement>(targetClassName)
              if (target) {
                target.style.height = ''
              }
              return target
            })
            const maxHeight = Math.ceil(
              Math.max(...targetElements.map((el) => el?.clientHeight ?? 0))
            )
            targetElements.forEach((el) => {
              if (el) {
                // eslint-disable-next-line no-param-reassign
                el.style.height = `${maxHeight}px`
              }
            })
          })
        })
    }

    function onResize() {
      // 同じ行の商品の各要素の（行）高さを揃える
      if (usedGroup) {
        itemsByGroup.forEach((group) => fitElementHeight(group.items))
      } else if (items?.length) {
        fitElementHeight(items)
      }
    }

    const observer = new ResizeObserver(onResize)
    if (ref.current) {
      observer.observe(ref.current)
    } else {
      onResize()
    }

    return () => {
      observer.disconnect()
    }
  }, [columns, items, itemsByGroup, usedGroup])

  return (
    <Grid ref={ref} container rowSpacing={6} columnSpacing={8 / columns}>
      {items?.length ? (
        usedGroup ? (
          itemsByGroup.map((group) => (
            <Grid item xs={12} key={group.id}>
              <Typography variant="h3" mt={0} gutterBottom>
                {group.name}
              </Typography>
              <Grid container rowSpacing={6} columnSpacing={8 / columns}>
                {group.items.map((product) => (
                  <GridItem
                    item
                    key={product.id}
                    id={product.id}
                    className={`column-${columns}`}
                    xs={12 / columns}
                  >
                    <RecommendItem
                      item={product}
                      linkBehavior={linkBehavior}
                      linkLabel={linkLabel}
                      {...visibles}
                    />
                  </GridItem>
                ))}
              </Grid>
            </Grid>
          ))
        ) : (
          items.slice(0, maxNumOf).map((product) => (
            <GridItem
              item
              key={product.id}
              id={product.id}
              className={`column-${columns}`}
              xs={12 / columns}
            >
              <RecommendItem
                item={product}
                linkBehavior={linkBehavior}
                linkLabel={linkLabel}
                {...visibles}
              />
            </GridItem>
          ))
        )
      ) : (
        <Grid item xs={12}>
          <Typography variant="body2" color="grey.500" textAlign="center">
            該当する商品がありません
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default RecommendList

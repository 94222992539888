import { userSchema } from 's2-lib'
import { z } from 'zod'
import { assetImagePathSchema } from './assetImagePath'

export const accountSchema = userSchema.omit({ thumbnailUrl: true }).extend({
  thumbnailUrl: assetImagePathSchema,
})

export const jwtPayloadSchema = z.object({
  userId: z.string(),
  projectId: z.string(),
  domain: z.string().url(),
  connectId: z.string().optional(),
})

export type Account = z.infer<typeof accountSchema>

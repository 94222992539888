import { z } from 'zod'

export const updateRequestTypeValues = [
  'welcome:update',
  'privacyPolicy:update',
  'termsOfService:update',
] as const

export const updateRequestTypeEnum = z.enum(updateRequestTypeValues)

export const updateRequestStatusValues = [
  'pending',
  'approved',
  'rejected',
  'withdraw',
] as const

export const updateRequestStatusEnum = z.enum(updateRequestStatusValues)

export const updateRequestSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  projectId: z.string(),
  type: updateRequestTypeEnum,
  status: updateRequestStatusEnum,
  rejectReason: z.string().nullish(),
  content: z.record(z.any()),
})

export type UpdateRequestType = z.infer<typeof updateRequestTypeEnum>

export type UpdateRequestStatus = z.infer<typeof updateRequestStatusEnum>

export type UpdateRequest = z.infer<typeof updateRequestSchema>

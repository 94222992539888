import { FC, MutableRefObject, useCallback, useState } from 'react'
import { Description, MaterialIconButton, S2Modal } from 's2-component'
import { Box, Typography } from '@mui/material'
import { useProjectValue } from '../../../../common/hooks/useProject'
import { useGA } from '../../../../common/hooks/useGA'
import { svgToBase64 } from '../../../../common/utils/svg2png'
import $style from './style.module.scss'

export type ChartHelpLauncherProps = {
  svgRef: MutableRefObject<SVGSVGElement | null>
}

export const ChartHelpLauncher: FC<ChartHelpLauncherProps> = ({ svgRef }) => {
  const { labels } = useProjectValue()
  const [open, setOpen] = useState(false)
  const [sample, setSample] = useState('')
  const { selectContent } = useGA()[1]
  const handleOpen = useCallback(async () => {
    const el = svgRef.current
    if (el) {
      setSample(await svgToBase64(el))
    }
    setOpen(true)
    selectContent('histroy_detail:showAboutChart')
  }, [selectContent, svgRef])
  const handleClose = useCallback(() => setOpen(false), [])

  return (
    <>
      <MaterialIconButton
        className={$style.btn__help}
        onClick={handleOpen}
        icon="help"
      />
      <S2Modal
        title={`${labels.skinScore}について`}
        maxWidth="375px"
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ p: 4 }}>
          <Box className={$style.root__bg} mb={4}>
            <Box
              className={$style.viewer__chart__help_img}
              style={{
                backgroundImage: `url(${sample})`,
              }}
            />
          </Box>
          <Description>
            <Typography component="span" color="secondary">
              A
            </Typography>
            に近づくほど
            <Typography component="span" color="secondary">
              良好な状態
            </Typography>
            、
            <Typography component="span" color="error">
              E
            </Typography>
            に近づくほど
            <Typography component="span" color="error">
              お手入れが必要な状態
            </Typography>
            となります。
          </Description>
        </Box>
      </S2Modal>
    </>
  )
}

import { Box, Button, Fade, Typography } from '@mui/material'
import {
  FC,
  useMemo,
  useRef,
  useState,
  useCallback,
  PropsWithChildren,
  useEffect,
} from 'react'
import { MaterialIcon } from 's2-component'

import { Diagnosis } from '../../../../common/schemas/diagnosis'
import { useProjectValue } from '../../../../common/hooks/useProject'
import { useComparisonDiagnosisValueLoadable } from '../../../../common/hooks/useDiagnosis'
import { RadarChart, RadarChartProps } from './RadarChart'
import { ChartHelpLauncher } from './ChartHelpLauncher'
import $style from './style.module.scss'

const ViewerEnum = {
  Chart: 'chart',
  Photo: 'photo',
} as const

type ViewerEnumType = (typeof ViewerEnum)[keyof typeof ViewerEnum]

type ButtonComparisonProps = {
  color: string
  isActive?: boolean
  toggle?: () => void
}

const ButtonComparison: FC<PropsWithChildren<ButtonComparisonProps>> = ({
  color,
  isActive = true,
  toggle = () => {},
  children,
}) => (
  <button
    type="button"
    className={[
      $style.btn__comparison,
      !isActive ? $style['btn__comparison--inactive'] : '',
    ].join(' ')}
    onClick={toggle}
    disabled={!toggle}
  >
    <Box
      className={$style.btn__comparison__icon}
      sx={{ backgroundColor: color }}
    />
    <Typography variant="body2" className={$style.btn__comparison__label}>
      {children}
    </Typography>
  </button>
)

export type ViewerProps = {
  result: Diagnosis
}

export const Viewer: FC<ViewerProps> = ({ result }) => {
  // プロジェクト情報
  const {
    color,
    history: { visibleItem },
    labels,
  } = useProjectValue()
  const visibleSkinScore = visibleItem.skinScore

  // 比較スコア
  const comparisonResultLoadable = useComparisonDiagnosisValueLoadable(
    visibleItem.comparisonScore && result.prevId !== undefined
      ? result.prevId
      : null
  )
  const comparisonResult =
    comparisonResultLoadable.state === 'hasValue'
      ? comparisonResultLoadable.contents?.item
      : null

  // ビューアーの種類
  const [viewerType, setViewerType] = useState<ViewerEnumType>('chart')
  const [visibleComparisonScore, setVisibleComparisonScore] = useState(true)

  // チャート要素
  const chartSVGRef = useRef<SVGSVGElement | null>(null)
  const chartProps = useMemo<RadarChartProps>(() => {
    const { scores } = result
    const sortedKey = (
      ['clarity', 'stain', 'wrinkle', 'moisture', 'pores', 'texture'] as const
    ).filter((key) => visibleSkinScore[key])
    const rankScoreMap = new Map([
      ['E', 0.2],
      ['D', 0.4],
      ['C', 0.6],
      ['B', 0.8],
      ['A', 1],
    ])
    const sortedLabels = sortedKey.map((key) => {
      const upperCamelCaseKey = (key.charAt(0).toUpperCase() + key.slice(1)) as
        | 'Clarity'
        | 'Stain'
        | 'Wrinkle'
        | 'Moisture'
        | 'Pores'
        | 'Texture'
      return labels[`skinScore${upperCamelCaseKey}`]
    })
    const dataArray = [
      {
        color: color.secondary,
        values: sortedKey.map(
          (key) => rankScoreMap.get(scores[key].rank) || 0.2
        ),
      },
      {
        color: color.accent,
        values: sortedKey.map((key) => {
          const value = comparisonResult?.scores[key]
          return value && visibleComparisonScore
            ? rankScoreMap.get(value.rank) || 0.2
            : null
        }),
      },
    ]
    const margin = (() => {
      switch (sortedKey.length) {
        case 3:
          return { top: 24, left: 24, right: 24, bottom: 24 }
        case 4:
          return { top: 32, left: 36, right: 36, bottom: 32 }
        // 5, 6
        default:
          return { top: 32, left: 32, right: 32, bottom: 32 }
      }
    })()

    return {
      levels: 6,
      margin,
      labelData: {
        fontSize: 14,
        values: sortedLabels,
      },
      dataArray,
    }
  }, [
    result,
    color.secondary,
    color.accent,
    visibleSkinScore,
    labels,
    comparisonResult?.scores,
    visibleComparisonScore,
  ])

  // 顔写真
  const photos = useMemo(
    () =>
      [
        result.faceImagePath,
        ...Object.values(result.additionalImagePaths ?? {}),
      ].filter((path): path is string => !!path),
    [result.additionalImagePaths, result.faceImagePath]
  )
  const [photoIndex, setPhotoIndex] = useState(0)
  const visiblePhotoNav = photos.length > 1 && viewerType === ViewerEnum.Photo

  const handleClickNextPhoto = useCallback(() => {
    setPhotoIndex((prev) => (prev + 1) % photos.length)
  }, [setPhotoIndex, photos])

  const handleClickPrevPhoto = useCallback(() => {
    setPhotoIndex((prev) => (prev + photos.length - 1) % photos.length)
  }, [setPhotoIndex, photos])

  const toggleVisibleComparisonScore = useCallback(() => {
    setVisibleComparisonScore((prev) => !prev)
  }, [])

  const toggleViewer = useCallback(() => {
    setViewerType((prev) =>
      prev === ViewerEnum.Chart ? ViewerEnum.Photo : ViewerEnum.Chart
    )
  }, [])

  // 履歴更新時に初期化
  useEffect(() => {
    setPhotoIndex(0)
    if (!result.faceImagePath) {
      setViewerType(ViewerEnum.Chart)
    }
  }, [result])

  return (
    <>
      <Box className={['s2-flex-center', $style.viewer__chart].join(' ')}>
        <RadarChart ref={chartSVGRef} {...chartProps} />
        <ChartHelpLauncher svgRef={chartSVGRef} />
      </Box>
      {visibleItem.facePict && (
        <Fade in={viewerType === ViewerEnum.Photo}>
          <Box className={$style.viewer__photo}>
            <img
              className={$style.viewer__photo_thumb}
              src={photos[photoIndex]}
              alt={`${result.createdAt}`}
            />
          </Box>
        </Fade>
      )}
      {(visibleItem.facePict || visibleItem.comparisonScore) && (
        <Box className={$style.viewer__footer}>
          {visibleItem.comparisonScore && (
            <Box className={$style.viewer__comparison}>
              <ButtonComparison
                color={color.accent}
                isActive={!!comparisonResult && visibleComparisonScore}
                toggle={toggleVisibleComparisonScore}
              >
                {labels.comparisonScore}
              </ButtonComparison>
              <ButtonComparison color={color.secondary}>
                {labels.currentScore}
              </ButtonComparison>
            </Box>
          )}
          {visibleItem.facePict && (
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              justifyContent="center"
            >
              {visiblePhotoNav && (
                <Button
                  className={$style.btn__photo_nav}
                  disabled={photoIndex === 0}
                  onClick={handleClickPrevPhoto}
                  variant="contained"
                  size="medium"
                >
                  <MaterialIcon>navigate_before</MaterialIcon>
                </Button>
              )}
              <Button
                variant="contained"
                className={[
                  $style.btn__toggle_type,
                  viewerType === ViewerEnum.Photo
                    ? $style['btn__toggle_type--rotated']
                    : '',
                ].join(' ')}
                disabled={!result.faceImagePath}
                onClick={toggleViewer}
              >
                <MaterialIcon>sync</MaterialIcon>
                <Box component="span" sx={{ width: '100px' }}>
                  {viewerType === ViewerEnum.Photo
                    ? 'チャートに切替'
                    : '顔写真に切替'}
                </Box>
              </Button>
              {visiblePhotoNav && (
                <Button
                  className={$style.btn__photo_nav}
                  disabled={photos.length === photoIndex + 1}
                  onClick={handleClickNextPhoto}
                  variant="contained"
                  size="medium"
                >
                  <MaterialIcon>navigate_next</MaterialIcon>
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

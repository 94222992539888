import { z } from 'zod'

import { idSchema } from './id'
import { productSchema } from './product'

export const releaseProductSchema = productSchema.extend({
  categories: z.array(idSchema).default(() => []),
})

export type ReleaseProduct = z.infer<typeof releaseProductSchema>

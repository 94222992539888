import { FC } from 'react'
import { CheckBoxField } from 's2-component'
import { SelectableFieldProps } from '.'
import { FormTitle } from './FormTitle'

export const CheckboxForm: FC<SelectableFieldProps> = ({
  id,
  title,
  required,
  options,
}) => (
  <>
    <FormTitle title={title} required={required} />
    <CheckBoxField name={id} options={options} />
  </>
)

import { Typography, TypographyProps } from '@mui/material'
import type { PropsWithChildren, FC } from 'react'

export type DescriptionProps = PropsWithChildren<TypographyProps>

export const Description: FC<DescriptionProps> = ({
  children,
  variant = 'body1',
  paragraph = true,
  sx,
  ...props
}) => (
  <Typography
    {...props}
    variant={variant}
    paragraph={paragraph}
    sx={{
      mx: 3,
      my: 2,
      ...sx,
    }}
  >
    {children}
  </Typography>
)

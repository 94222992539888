import { useCallback, useState } from 'react'

// export const DEFAULT_TIPS = [
//   '熱々のお湯で洗ってない？30°Cくらいの「ぬるま湯」洗顔が鉄則よ',
//   '化粧水ケアでペチペチ叩き入れはNG！ゆっくりハンドプレスしよ♪',
//   'マスク荒れは肌摩擦が原因。予防のためにも下地は毎日塗ろう！',
//   '老化原因No.1の紫外線は1年中降り注ぐ。日焼け止めでガードして！',
//   '寝る前スマホはブルーライトシャワー。睡眠質・肌質も悪化します',
//   'メイクしたまま寝落ちした翌朝はレンチン蒸しタオルで肌、復活！',
//   '保湿ケアしてるのに肌が乾くなら、下地に乳液を混ぜて潤いプラス',
//   '上向きにスプレーして浴びる保湿ミスト法で乾燥&メイク崩れ防止',
//   '枕やシーツ、洗ってる？清潔な寝具はニキビ予防のひとつです',
//   '美肌の人ほど帰宅後、即メイクオフ。悪い脂質は毛穴つまりのもと',
// ]

type Next = () => void

export const useSkincareTip = (tips: string[]): [string, Next] => {
  const getTip = useCallback(
    () => tips[Math.floor(Math.random() * tips.length)],
    [tips]
  )
  const [tip, setTip] = useState(getTip())
  const next: Next = useCallback(() => {
    const nextTip = getTip()
    if (tip === nextTip) {
      next()
      return
    }
    setTip(nextTip)
  }, [getTip, tip])

  return [tip, next]
}

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  styled,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState, useCallback, useEffect } from 'react'
import type { FC, PropsWithChildren } from 'react'
import { APIError } from 's2-lib'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
}))

type BootstrapDialogTitleProps = DialogTitleProps & {
  onClose?(): void
}

const BootstrapDialogTitle: FC<
  PropsWithChildren<BootstrapDialogTitleProps>
> = ({ children, onClose, ...props }) => (
  <DialogTitle
    sx={{
      m: 0,
      py: 2,
      px: 4,
      height: 56,
      display: 'flex',
      alignItems: 'center',
    }}
    {...props}
  >
    {children}
    {onClose ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </DialogTitle>
)

export type ErrorAlertProps = {
  error?: Error
}

export const ErrorAlert: FC<ErrorAlertProps> = ({ error }) => {
  const [open, setOpen] = useState(false)
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])
  let statusCode = 500
  let code = 'Internal Server Error'

  useEffect(() => {
    setOpen(!!error)
  }, [error])

  if (error instanceof APIError) {
    statusCode = error.statusCode
    code = error.code ?? ''
    // TODO: sentryに送る
    // eslint-disable-next-line no-console
    console.dir(error.data)
  }

  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="error-dialog"
      maxWidth="xs"
    >
      <BootstrapDialogTitle id="error-dialog" onClose={handleClose}>
        {statusCode} {code}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{error?.message}</Typography>
      </DialogContent>
      {/* <DialogActions>
        <Button autoFocus onClick={handleClick}>
          Close
        </Button>
      </DialogActions> */}
    </BootstrapDialog>
  )
}

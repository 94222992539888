import { ScaleTime } from 'd3-scale'
import { AxisBottom } from '@visx/axis'
import { FC, useMemo } from 'react'

const paddingZero = (num: number) => `${num}`.padStart(2, '0')

type Props = {
  top: number
  scale: ScaleTime<number, number, never>
}

const DateAxis: FC<Props> = ({ top, scale }) => {
  const [xMin, xMax] = scale.domain()
  const xScaleConfig = useMemo(() => {
    const diff = xMax.getTime() - xMin.getTime()
    const ONE_DAY = 1000 * 60 * 60 * 24

    if (diff < ONE_DAY * 2) {
      // 24時間以内（時間と分のみ）
      return {
        hiddenTime: false,
        hiddenDate: true,
      }
    }

    return {
      hiddenTime: true,
      hiddenDate: false,
    }
  }, [xMax, xMin])

  return (
    <AxisBottom
      top={top}
      scale={scale}
      tickFormat={(value, index, values) => {
        const currentDate = value as Date
        const prevDate = index > 0 ? (values[index - 1].value as Date) : null
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth()
        const currentDay = currentDate.getDate()
        const currentHour = currentDate.getHours()
        const currentMinute = currentDate.getMinutes()
        const date: string[] = []
        const time: string[] = []

        if (currentYear !== prevDate?.getFullYear()) {
          date.push(`${currentYear}`)

          if (index === 0) {
            date.push(paddingZero(currentMonth + 1), paddingZero(currentDay))
          }
        } else {
          if (!xScaleConfig.hiddenDate) {
            date.push(paddingZero(currentMonth + 1), paddingZero(currentDay))
          }

          if (!xScaleConfig.hiddenTime) {
            time.push(paddingZero(currentHour), paddingZero(currentMinute))
          }
        }

        return `${date.join('/')} ${time.join(':')}`
      }}
      numTicks={4}
      stroke="#ccc"
      strokeWidth={2}
      tickStroke="#ccc"
      tickLineProps={{ strokeWidth: 1 }}
      tickLabelProps={() => ({
        fill: '#49454F',
        fontSize: 11,
        fontWeight: '500',
        textAnchor: 'middle',
      })}
    />
  )
}

export default DateAxis

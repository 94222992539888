type Label = {
  placeholder: string
  explain: string
  multiline?: boolean
}

export type LabelingKey =
  | 'skinDetect'
  | 'diagnosisHistory'
  | 'detectedAt'
  | 'detectAction'
  | 'detecting'
  | 'skinScore'
  | 'skinScoreStain'
  | 'skinScoreWrinkle'
  | 'skinScoreTexture'
  | 'skinScorePores'
  | 'skinScoreMoisture'
  | 'skinScoreClarity'
  | 'skinType'
  | 'skinAge'
  | 'overallRating'
  | 'currentScore'
  | 'comparisonScore'
  | 'recommendProducts'
  | 'recommendProductLink'

export const labelings: Record<LabelingKey, Label> = {
  skinDetect: {
    placeholder: '肌診断',
    explain: '主に診断結果ページで使用します',
  },
  diagnosisHistory: {
    placeholder: '診断履歴',
    explain: '主に診断結果ページで使用します',
  },
  detectedAt: {
    placeholder: '診断日時',
    explain: '主に診断結果ページで使用します',
  },
  detectAction: {
    placeholder: '肌診断する',
    explain: '主に診断結果ページで使用します',
  },
  detecting: {
    placeholder: '診断中',
    explain: '撮影ページの結果待機中に使用します',
  },
  skinScore: {
    placeholder: '肌スコア',
    explain: '主に診断結果ページで使用します',
  },
  skinScoreStain: {
    placeholder: 'シミ',
    explain: '主に診断結果ページで使用します',
  },
  skinScoreWrinkle: {
    placeholder: 'シワ',
    explain: '主に診断結果ページで使用します',
  },
  skinScoreTexture: {
    placeholder: 'キメ',
    explain: '主に診断結果ページで使用します',
  },
  skinScorePores: {
    placeholder: '毛穴',
    explain: '主に診断結果ページで使用します',
  },
  skinScoreMoisture: {
    placeholder: '潤い',
    explain: '主に診断結果ページで使用します',
  },
  skinScoreClarity: {
    placeholder: '透明感',
    explain: '主に診断結果ページで使用します',
  },
  skinType: {
    placeholder: '肌質',
    explain: '主に診断結果ページで使用します',
  },
  skinAge: {
    placeholder: '肌年齢',
    explain: '主に診断結果ページで使用します',
  },
  overallRating: {
    placeholder: '総合評価',
    explain: '主に診断結果ページで使用します',
  },
  currentScore: {
    placeholder: '現在のスコア',
    explain: '診断結果ページに表示する現在のスコア用のグラフのラベル',
  },
  comparisonScore: {
    placeholder: '前回のスコア',
    explain: '診断結果ページに表示する比較スコア用のグラフのラベル',
  },
  recommendProducts: {
    placeholder: 'おすすめコスメ',
    explain: '診断結果ページに表示する商品のセクションタイトル',
  },
  recommendProductLink: {
    placeholder: '詳細はこちら',
    explain: '診断結果ページに表示する商品のリンクラベル',
  },
}

export const loadImage = async (src: string | typeof Image) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = src instanceof Image ? src : new Image()
    const done = () => resolve(img)

    if (typeof src === 'string') {
      img.src = src
      // img.loading = 'lazy'
    }

    img.crossOrigin = 'anonymous'

    requestAnimationFrame(() => {
      if (img.complete) {
        done()
      } else {
        img.onload = done
        img.onerror = reject
      }
    })
  })

type Func = (...args: any) => any

export class EventEmitter<T extends Record<string, Func>> {
  private events: {
    [key in keyof T]?: Func[]
  } = {}

  on<E extends keyof T>(event: E, listener: T[E]) {
    if (!this.events[event]) {
      this.events[event] = []
    }

    this.events[event]!.push(listener)
  }

  once<E extends keyof T>(event: E, listener: T[E]) {
    const self = this
    this.on(event, function once() {
      self.off(event, once as T[E])
      listener()
    } as T[E])
  }

  off<E extends keyof T>(event: E, listener: T[E]) {
    const index = this.events[event]?.findIndex((l) => l === listener) ?? -1
    if (index > -1) {
      this.events[event]!.splice(index, 1)
    }
  }

  protected emit<E extends keyof T>(event: E, ...args: Parameters<T[E]>) {
    this.events[event]?.forEach((listener) => listener(...(args as any)))
  }
}

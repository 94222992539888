import { FC, useMemo } from 'react'
import { Box, BoxProps, Divider } from '@mui/material'
import { useProjectValue } from '../../../../common/hooks/useProject'
import { Diagnosis } from '../../../../common/schemas/diagnosis'
import { alphaBlendingColors } from '../../../../common/utils/color'
import { Viewer } from './Viewer'
import { DetailCard } from './DetailCard'
import $style from './style.module.scss'

export type ResultDetailProps = BoxProps & {
  result: Diagnosis
}

export const ResultDetail: FC<ResultDetailProps> = ({ result, ...props }) => {
  const {
    color,
    history: { visibleItem },
  } = useProjectValue()
  const viewerPaddingBottom =
    visibleItem.comparisonScore && visibleItem.facePict
      ? 24
      : visibleItem.comparisonScore || visibleItem.facePict
      ? 12
      : 0
  const bgColor = useMemo(() => {
    const [r, g, b] = alphaBlendingColors(color.secondary, 0.08)
    return `rgb(${r}, ${g}, ${b})`
  }, [color])

  return (
    <Box
      {...props}
      style={
        {
          '--bg-color': bgColor,
          ...props.style,
        } as any
      }
      sx={{
        backgroundColor: 'var(--bg-color)',
      }}
    >
      <Divider />
      <Box className={$style.container}>
        <Box className={$style.viewer} sx={{ pb: viewerPaddingBottom }}>
          <Viewer result={result} />
        </Box>
        <Box sx={{ py: 2, pr: 2 }}>
          <DetailCard result={result} />
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}

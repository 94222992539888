import type { FaceDirVariant } from '../../types'
import { getSize } from '../Canvas'
// import { makeOutline } from './Landmark'

export const calcOrigin = (value: number) => {
  if (value < 0 || value > 1) return 0
  return (value - 0.5) * 2
}

const scale = (
  { x, y, w, h }: Rect,
  [scaleX, scaleY]: Scale,
  origin: Origin = [0.5]
): Rect => {
  const originX = calcOrigin(origin[0])
  const originY = calcOrigin(origin[1] ?? origin[0])
  const diffX = (w * (Math.round((scaleX - 1) * 100) / 100)) / 2
  const diffY = (h * (Math.round(((scaleY ?? scaleX) - 1) * 100) / 100)) / 2

  return {
    x: x - (diffX + diffX * originX),
    y: y - (diffY + diffY * originY),
    w: w + diffX * 2,
    h: h + diffY * 2,
  }
}

// const makePoints = ({
//   x,
//   y,
//   w,
//   h,
// }: Rect): [
//   [minX: number, minY: number],
//   [maxX: number, minY: number],
//   [maxX: number, maxY: number],
//   [minX: number, maxY: number]
// ] => [
//   [x, y],
//   [x + w, y],
//   [x + w, y + h],
//   [x, y + h],
// ]

// /**
//  * ひとまわり小さい枠よりも大きいか
//  */
// const greaterThanMinFactory =
//   (scaleValue: Scale) => (detectionBox: Rect, flame: Rect) => {
//     const minFlame = scale(flame, scaleValue)
//     return detectionBox.w >= minFlame.w || detectionBox.h >= minFlame.h
//   }

// /**
//  * ひとまわり大きい枠内に入っているか
//  */
// const containMaxFactory =
//   (scaleValue: Scale) => (detectionBox: Rect, flame: Rect) => {
//     const points = makePoints(detectionBox)
//     const maxFlame = scale(flame, scaleValue)

//     return points.every((point) => {
//       const x = point[0] - maxFlame.x
//       const y = point[1] - maxFlame.y
//       return x > 0 && x < maxFlame.w && y > 0 && y < maxFlame.h
//     })
//   }

/**
 * 顔が枠内に収まっているか
 */
export const validateContainsFactory = (
  _minScaleValue: Scale,
  _maxScaleValue: Scale
) => {
  // x: 耳を除いた顔枠の幅の割合
  // y: 前髪の生え際から顎先までの顔枠の高さの割合
  const scaleValue: Scale = [1, 0.77]
  // 原点を下にする
  const origin: Origin = [0.5, 1]

  // const greaterThanMin = greaterThanMinFactory(minScaleValue)
  // const containMax = containMaxFactory(maxScaleValue)

  // return (landmark: Landmark, flame: Rect) => {
  //   const detectionBox = makeOutline('face', landmark)
  //   const optimizedFlame = scale(flame, scaleValue, origin)

  //   return (
  //     detectionBox.w > 0 &&
  //     detectionBox.h > 0 &&
  //     greaterThanMin(detectionBox, optimizedFlame) &&
  //     containMax(detectionBox, optimizedFlame)
  //   )
  // }
  return (landmark: Landmark, flame: Rect) => {
    const { x, y, w, h } = scale(flame, scaleValue, origin)
    return Object.values(landmark).every((point) => {
      if (!point) {
        return false
      }
      return (
        point[0] >= x && point[0] <= x + w && point[1] >= y && point[1] <= y + h
      )
    })
  }
}

/**
 * 顔の向きが範囲内か
 */
export type EulerAngleRanges = {
  pitch: [number, number]
  yaw: [number, number]
  roll: [number, number]
}
export const validateDirectionFactory =
  (base: EulerAngleRanges) => (eulerAngles?: EulerAngles) =>
    !!eulerAngles &&
    eulerAngles.yaw <= base.yaw[1] &&
    eulerAngles.yaw >= base.yaw[0] &&
    eulerAngles.roll <= base.roll[1] &&
    eulerAngles.roll >= base.roll[0] &&
    Math.abs(Math.abs(eulerAngles.pitch) - 180) <= base.pitch[1] &&
    Math.abs(Math.abs(eulerAngles.pitch) - 180) >= base.pitch[0]

/**
 * 枠内に絞った画像の平均明度を算出する
 */
export const getBrightnessFactory = () => {
  const debugCanvas = document.createElement('canvas')
  const debugCtx = debugCanvas?.getContext('2d')

  function getNoseRect(landmarks: Landmark) {
    const { nose, upperLip } = landmarks
    const distanceNoseToLip = (upperLip![1] - nose![1]) * 0.5
    const size = Math.round(distanceNoseToLip * 2)
    const x = Math.round(nose![0] - distanceNoseToLip)
    const y = Math.round(nose![1] - distanceNoseToLip)
    return { x, y, w: size, h: size }
  }

  function getCenterRect(source: CanvasSource) {
    const { width, height } = getSize(source)
    const size = Math.min(width, height) / 8
    const x = (width - size) / 2
    const y = (height - size) / 2
    return { x, y, w: size, h: size }
  }

  return (
    variant: FaceDirVariant,
    source: CanvasSource,
    landmarks: Landmark
  ) => {
    if (!debugCanvas || !debugCtx || !landmarks.nose || !landmarks.upperLip) {
      return 0
    }

    const { x, y, w, h } =
      variant === 'front' ? getNoseRect(landmarks) : getCenterRect(source)
    debugCanvas.width = w
    debugCanvas.height = h
    debugCtx.drawImage(source, x, y, w, h, 0, 0, w, h)

    // HSV変換した際の平均明度を算出
    const iData = debugCtx.getImageData(0, 0, w, h)
    let brightnessSum = 0
    for (let i = 0, len = iData.data.length; i < len; i += 4) {
      brightnessSum += Math.max(
        iData.data[i],
        iData.data[i + 1],
        iData.data[i + 2]
      )
    }
    const brightness = brightnessSum / (iData.data.length / 4) / 255

    return brightness
  }
}

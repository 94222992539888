import { useEffect } from 'react'

export const usePageVisibility = (
  whenVisible: () => void,
  whenHidden: () => void,
  immediate = false
) => {
  useEffect(() => {
    const onChange = () => {
      if (document.hidden) {
        whenHidden()
      } else {
        whenVisible()
      }
    }

    document.addEventListener('visibilitychange', onChange, false)

    if (immediate) {
      onChange()
    }

    return () => document.removeEventListener('visibilitychange', onChange)
  }, [whenHidden, whenVisible, immediate])
}

import {
  Box,
  Card,
  Divider,
  Modal,
  ModalProps,
  styled,
  Typography,
} from '@mui/material'
import { FC, PropsWithChildren, ReactNode, useCallback } from 'react'
import { MaterialIconButton } from '../../ui-elements/MaterialIconButton'

export type S2ModalProps = PropsWithChildren<
  ModalProps & {
    title?: ReactNode
    maxWidth?: string
  }
>

const ModalWrap = styled(Box)(({ theme }) => ({
  height: '100vh',
  padding: theme.spacing(4, 6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const S2Modal: FC<S2ModalProps> = ({
  children,
  title,
  maxWidth = '560px',
  ...props
}) => {
  const onClose = useCallback(
    () => props.onClose?.({}, 'backdropClick'),
    [props]
  )
  const ModalHeaderHeight = '56px'
  const ModalBtnWidth = '45px'
  return (
    <Modal {...props}>
      <ModalWrap>
        <Card sx={{ borderRadius: 4, maxWidth }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: ModalHeaderHeight,
            }}
          >
            <Box className="s2-flex-center" sx={{ width: ModalBtnWidth }}>
              <MaterialIconButton onClick={onClose} icon="cancel" />
            </Box>
            <Box sx={{ overflow: 'hidden', textAlign: 'center', flexGrow: 1 }}>
              <Typography
                variant="h3"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  my: 0,
                }}
              >
                {title}
              </Typography>
            </Box>
            <Box sx={{ width: ModalBtnWidth }} />
          </Box>
          <Divider />
          <Box
            sx={{
              maxHeight: `calc(90vh - ${ModalHeaderHeight} - 16px)`,
              overflowY: 'auto',
            }}
          >
            {children}
          </Box>
        </Card>
      </ModalWrap>
    </Modal>
  )
}

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  styled,
} from '@mui/material'
import { FC, useCallback, useState, ReactNode } from 'react'

const BaseDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(4),
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(4),
  },
}))

export type DeleteConfirmDialogProps = {
  open: boolean
  onClose(): void
  onDelete(): void
  title: ReactNode
  content: ReactNode
  confirmCode: string
}

export const DeleteConfirmDialog: FC<DeleteConfirmDialogProps> = (props) => {
  const [correct, setCorrect] = useState(false)
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCorrect(e.target.value === props.confirmCode)
    },
    [props.confirmCode]
  )
  return (
    <BaseDialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="delete-confirm-dialog-title"
      aria-describedby="delete-confirm-dialog-description"
    >
      <DialogTitle id="delete-confirm-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-confirm-dialog-description">
          {props.content}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="delete-confirm-code"
          type="text"
          fullWidth
          placeholder={props.confirmCode}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>キャンセル</Button>
        <Button
          onClick={props.onDelete}
          variant="contained"
          color="error"
          disabled={!correct}
        >
          削除
        </Button>
      </DialogActions>
    </BaseDialog>
  )
}

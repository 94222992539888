import { styled, Box, BoxProps } from '@mui/material'
import { forwardRef, memo } from 'react'

export interface ImageBoxProps extends BoxProps {
  src?: string | null
  alt?: string | null
}

const InnerThumb = styled(Box)<ImageBoxProps>({
  display: 'block',
  backgroundColor: '#fff',
  objectFit: 'contain',
  '&.no-src': {
    backgroundColor: '#f0f0f0',
  },
})

const RawThumb = forwardRef<HTMLImageElement, ImageBoxProps>(
  ({ src, alt, ...props }, ref) => {
    const hasSrc = !!src?.trim().length
    const imgProps = hasSrc ? { src, alt } : {}
    const component = hasSrc ? 'img' : 'div'
    const classNames =
      Array.from(
        new Set([
          ...(props.className?.split(' ') ?? []),
          hasSrc ? '' : 'no-src',
        ])
      ).join(' ') || undefined

    return (
      <InnerThumb
        ref={ref}
        {...props}
        {...imgProps}
        component={component}
        className={classNames}
      />
    )
  }
)

export const Thumbnail = memo(RawThumb)

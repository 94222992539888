import KFilter from 'kalmanjs'

type Options = { R?: number; Q?: number; d?: 1 | 2 | 3 }

const defaultOpts = {
  R: 0.2,
  d: 2,
}

export class KalmanFilter {
  private kfilters: KFilter[]

  constructor(opts?: Options) {
    const { d, ...rest } = { ...defaultOpts, ...opts }
    const dim = d || 1
    this.kfilters = new Array(dim).fill(0).map(() => new KFilter(rest))
  }

  filter(data: number[]) {
    return this.kfilters.map((f, idx) => f.filter(data[idx]))
  }
}

import { styled, Theme, Box, BoxProps } from '@mui/material'
import { FC } from 'react'

const Footer = styled(Box)({
  fontSize: 12,
  textAlign: 'center',
})

const Anchro = styled('a')((ctx) => {
  const { palette } = ctx.theme as Theme
  const mainColor = palette.primary.main
  return {
    color: mainColor,
    fontWeight: 500,
    textDecoration: 'none',
  }
})

export type TheFooterProps = BoxProps

export const TheFooter: FC<TheFooterProps> = (props) => (
  <Footer {...props} component="footer">
    Powered by{' '}
    <Anchro href="https://skinsense.jp/" target="_blank">
      skinsense
    </Anchro>
  </Footer>
)

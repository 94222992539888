import { z } from 'zod'

export const tinyTextSchema = z
  .string()
  .trim()
  .max(32, '32文字以内にしてください')
export const shortTextSchema = z
  .string()
  .trim()
  .max(64, '64文字以内にしてください')
export const mediumTextSchema = z
  .string()
  .trim()
  .max(512, '512文字以内にしてください')
export const longTextSchema = z
  .string()
  .trim()
  .max(1024, '1024文字以内にしてください')

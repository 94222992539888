import { FC, MouseEvent, useCallback, useMemo, useState } from 'react'
import {
  alpha,
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  styled,
} from '@mui/material'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Heading } from 's2-component'
import { useProjectValue } from '../../../../common/hooks/useProject'
import { useLatestDiagnosisValue } from '../../../../common/hooks/useDiagnosis'
import { Warning } from '../../../ui/Warning'
import { ResultDetail } from '../ResultDetail'
import { ResultTransaction } from '../ResultTransaction'
import { Diagnosis } from '../../../../common/schemas/diagnosis'

import IcoTransaction from './IcoTransaction'
import IcoDetail from './IcoDetail'
import { Project } from '../../../../common/schemas/project'
import { useGA } from '../../../../common/hooks/useGA'

const TabButton = styled(Button)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
  borderColor: alpha(theme.palette.primary.main, 0.16),
  '&.active': {
    fontWeight: 700,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  '&:not(.active)': {
    color: '#49454F',
    currentColor: '#49454F',
  },
  svg: {
    marginRight: 4,
  },
  path: {
    fill: 'currentColor',
  },
}))

export type ResultIndexProps = BoxProps & {
  alertRediagnosis?: number
  result: Diagnosis
}

type ViewType = Project['history']['features']['defaultView']

export const ResultIndex: FC<ResultIndexProps> = ({
  alertRediagnosis = 1000 * 60 * 60 * 24 * 31,
  result,
  ...props
}) => {
  const {
    labels,
    history: {
      features: { defaultView, detailView, transactionView },
    },
  } = useProjectValue()
  const [viewType, setViewType] = useState<ViewType>(defaultView)
  const { selectContent } = useGA()[1]
  const latestAt = useLatestDiagnosisValue()?.item.createdAt ?? 0
  const needRediagnosis = useMemo(
    () => Date.now() - latestAt > alertRediagnosis,
    [alertRediagnosis, latestAt]
  )
  const handleChangeViewType = useCallback(
    (e: MouseEvent) => {
      const { type } = (e.target as HTMLButtonElement).dataset
      if (type) {
        selectContent(`history_${type as ViewType}`)
        setViewType(type as ViewType)
      }
    },
    [selectContent]
  )

  return (
    <Box {...props}>
      {needRediagnosis && (
        <Warning left="calc(100% - 82px)" sx={{ mb: 4, mx: -6 }}>
          最後の{labels.skinDetect}から1ヶ月以上経過しています
        </Warning>
      )}
      <Heading
        variant="h2"
        mt={2}
        mb={4}
        action={
          <Button variant="contained" color="primary" href="/counseling">
            {labels.detectAction}
          </Button>
        }
      >
        {labels.diagnosisHistory}
      </Heading>
      {detailView && transactionView ? (
        <ButtonGroup fullWidth size="large" variant="outlined">
          <TabButton
            className={viewType === 'detail' ? 'active' : ''}
            onClick={handleChangeViewType}
            data-type="detail"
          >
            <IcoDetail />
            詳細
          </TabButton>
          <TabButton
            className={viewType === 'transaction' ? 'active' : ''}
            onClick={handleChangeViewType}
            data-type="transaction"
          >
            <IcoTransaction />
            変遷
          </TabButton>
        </ButtonGroup>
      ) : null}
      <Box my={4}>
        <SwitchTransition>
          <CSSTransition key={viewType} classNames="fade" timeout={200}>
            {(() => {
              switch (viewType) {
                case 'transaction':
                  return <ResultTransaction />
                case 'detail':
                default:
                  return <ResultDetail mx={-6} result={result} />
              }
            })()}
          </CSSTransition>
        </SwitchTransition>
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import { FC } from 'react'
import { defaultTermsOfServiceContent, parseMarkdown } from 's2-lib'
import { ElementToMUI } from 's2-component'
import { usePageview } from '../common/hooks/useGA'
import { TheFooter } from '../components/layouts/TheFooter'
import { TheHeader } from '../components/layouts/TheHeader'
import { useProjectValue } from '../common/hooks/useProject'

const PageComponent: FC = () => {
  usePageview()
  const project = useProjectValue()
  const content =
    project?.customPages?.termsOfService?.type === 'customize'
      ? project.customPages.termsOfService.content
      : defaultTermsOfServiceContent

  return (
    <div>
      <TheHeader backPath="/">利用規約</TheHeader>
      <Box component="section" sx={{ px: 6 }}>
        <ElementToMUI html={parseMarkdown(content)} />
      </Box>
      <TheFooter mt={12} mb={6} />
    </div>
  )
}

export default PageComponent

import { FC, PropsWithChildren } from 'react'
import { css, Box, BoxProps, Typography } from '@mui/material'
import { MaterialIcon } from 's2-component'

export type WarningProps = PropsWithChildren<
  BoxProps & {
    left?: string
  }
>

export const Warning: FC<WarningProps> = ({
  left = '50%',
  children,
  sx,
  ...props
}) => (
  <Typography
    variant="body2"
    component={Box}
    className="s2-flex-center"
    sx={{
      position: 'relative',
      height: '32px',
      backgroundColor: (theme) => theme.palette.warning.main,
      ...sx,
    }}
    {...props}
  >
    <Box
      component="i"
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        bottom: '-13px',
        marginLeft: '-8px',
        width: 0,
        height: 0,
        borderWidth: '13.9px 8px 0 8px',
        borderColor: (theme) =>
          `${theme.palette.warning.main} transparent transparent transparent`,
        borderStyle: 'solid',
        left,
      }}
    />
    <MaterialIcon mr={0.5} mt={-0.5} size={16} color="#FF9800">
      warning
    </MaterialIcon>
    <Box
      component="span"
      css={css`
        color: #663c00;
      `}
    >
      {children}
    </Box>
  </Typography>
)

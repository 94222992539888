import { marked } from 'marked'
import sanitizeHtml from 'sanitize-html'

marked.setOptions({
  breaks: true,
})

const MarkdownSanitizeOptions: sanitizeHtml.IOptions = {
  allowedTags: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'a',
    'img',
    'ol',
    'ul',
    'li',
    'strong',
    'em',
    'del',
    'code',
    'pre',
    'br',
    'hr',
    'blockquote',
    'div',
    'table',
    'thead',
    'tbody',
    'tr',
    'td',
    'th',
  ],
  allowedAttributes: {
    '*': ['style'],
    a: ['href', 'target', 'rel'],
    img: ['src', 'alt'],
  },
  allowedSchemes: ['https', 'mailto'],
  allowedSchemesAppliedToAttributes: ['href', 'src'],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
}

export const parseMarkdown = (str: string) =>
  sanitizeHtml(marked(str), MarkdownSanitizeOptions)

import { z } from 'zod'
import { shortTextSchema } from './text'
import { idSchema } from './id'

export const MAX_PRODUCT_CATEGORY_PRIORITY = 9999

export const productCategorySchema = z.object({
  id: idSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
  name: shortTextSchema.min(1, '必須項目です'),
  priority: z.number().int().min(0).max(MAX_PRODUCT_CATEGORY_PRIORITY),
})

export type ProductCategory = z.infer<typeof productCategorySchema>

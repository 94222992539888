/* eslint-disable no-param-reassign */
import { useCallback } from 'react'
import {
  ERROR_CAMERA_NOT_FOUND,
  ERROR_CAMERA_PERMISSION_DENID,
} from '../constants/errorCodes'

let activeDeviceId = ''

const getVideoDevices = async () => {
  const mediaDevices = await navigator.mediaDevices.enumerateDevices()
  const videoDevices = mediaDevices.filter(
    (device) => device.kind === 'videoinput'
  )
  return videoDevices
}

const getVideoStream = async (deviceId: string) => {
  try {
    const media = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId,
      },
    })
    // FIXME: 2024/06/27: 保存する画像を高画質にするためにデバイスごとに最高解像度を設定したいが、以下の問題がある
    // - aspectRatio がドキュメントだと width / height なのに、Android Google Chrome だと height / width になっている（iPhone未検証）
    // - その他にも高解像度にすると顔の向き（yaw）が正面で0付近にならない問題がある（原因不明）
    //
    // const videoTrack = media.getVideoTracks()[0]
    // const capabilities = videoTrack.getCapabilities()
    // const { innerHeight, innerWidth, devicePixelRatio } = window
    // const height =
    //   capabilities.height?.max ?? Math.round(innerHeight * devicePixelRatio)
    // const aspectRatio = innerHeight / Math.min(innerWidth, 600)
    // const width = Math.round(height * aspectRatio)
    // const constraints: MediaTrackConstraints & {
    //   resizeMode?: 'crop-and-scale' | 'none'
    // } = {
    //   deviceId,
    //   width: { ideal: width },
    //   height: { ideal: height },
    //   aspectRatio: { exact: aspectRatio },
    //   frameRate: { ideal: 15 },
    //   resizeMode: 'crop-and-scale',
    // }

    // await videoTrack.applyConstraints(constraints)

    return media
  } catch {
    return null
  }
}

const create = async (video: HTMLVideoElement) => {
  // https://developer.chrome.com/blog/play-request-was-interrupted/
  // This will allow us to play video later...
  video.load()

  const devices = await getVideoDevices()

  if (!devices?.length) {
    throw new Error(ERROR_CAMERA_NOT_FOUND)
  }

  const { deviceId } = devices[0]
  const stream = await getVideoStream(deviceId)

  if (!stream) {
    throw new Error(ERROR_CAMERA_PERMISSION_DENID)
  }

  activeDeviceId = deviceId
  video.srcObject = stream
}

const cleanup = async (video?: HTMLVideoElement | null) => {
  const stream = (video?.srcObject ||
    (await getVideoStream(activeDeviceId))) as MediaStream | undefined

  stream?.getTracks().forEach((track) => track.stop())

  if (video) {
    video.srcObject = null
  }
}

export const useVideoStream = () => ({
  create: useCallback(create, []),
  cleanup: useCallback(cleanup, []),
})

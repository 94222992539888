import { FC, useEffect } from 'react'
import { useAccountValue } from '../common/hooks/useAccount'
import { usePageview } from '../common/hooks/useGA'
import Redirect from '../components/functional/Redirect'
import { TheFooter } from '../components/layouts/TheFooter'
import { WelcomeView } from '../components/pages/welcome/WelcomeView'
import TitleHeader from '../components/ui/TitleHeader'
import { useProjectValue } from '../common/hooks/useProject'
import { useRedirect } from '../common/hooks/useRedirect'

const PageComponent: FC = () => {
  const account = useAccountValue()
  const project = useProjectValue()
  const redirect = useRedirect()
  const hasExternalWelcome = project?.customPages?.welcome?.type === 'external'
  const ignore = !!account

  usePageview({ ignore })

  useEffect(() => {
    if (!account && hasExternalWelcome) {
      redirect()
    }
  }, [account, hasExternalWelcome, redirect])

  if (account) {
    return <Redirect to="/" replace />
  }

  if (hasExternalWelcome) {
    return null
  }

  return (
    <div>
      <TitleHeader />
      <WelcomeView sx={{ px: 6 }} />
      <TheFooter mt={12} mb={6} />
    </div>
  )
}

export default PageComponent

import { Button } from '@mui/material'
import { useState, useCallback } from 'react'
import { MaterialIcon, S2Modal } from 's2-component'
import { S2HowtoBestShootingView, useFaceDetect } from 's2-shooting-component'

const HowToBestShootingLauncher = () => {
  const { start, stop } = useFaceDetect()
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => {
    stop()
    setOpen(true)
  }, [stop])
  const handleClose = useCallback(() => {
    setOpen(false)
    start()
  }, [start])

  return (
    <>
      <Button
        sx={{ borderRadius: '9999px', px: 4 }}
        variant="contained"
        color="secondary"
        onClick={handleOpen}
      >
        <MaterialIcon mr={2}>auto_awesome</MaterialIcon>
        精度のいい写真を撮る方法
      </Button>
      <S2Modal
        title="精度のいい写真を撮る方法"
        open={open}
        onClose={handleClose}
      >
        <S2HowtoBestShootingView />
      </S2Modal>
    </>
  )
}

export default HowToBestShootingLauncher

import sanitizeHtml from 'sanitize-html'
import { Paper, Typography, Stack, Box, styled } from '@mui/material'
import { FC, useMemo } from 'react'
import { Feedback } from 's2-lib'
import { Thumbnail } from '../../ui-elements/Thumbnail'

const Link = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
  transition: 'opacity 0.25s ease-out',
  '&:hover': {
    opacity: 0.5,
  },
})

type RichFeedbackProps = {
  id?: Feedback['id']
  content: Feedback['content']
  linkBehavior?: 'blank' | 'self'
}

const RichFeedback: FC<RichFeedbackProps> = ({
  id,
  content: {
    title,
    thumbSubTitle,
    thumbMainTitle,
    thumbnail,
    comment,
    banner,
    link,
  },
  linkBehavior = 'blank',
}) => {
  const sanitizedComment = useMemo(
    () =>
      comment
        ? sanitizeHtml(comment.replaceAll('\n', '<br>'), {
            allowedTags: ['br'],
          })
        : '',
    [comment]
  )
  const el = (
    <Paper
      id={link ? undefined : id}
      sx={{ p: 4, borderRadius: 3, boxShadow: (theme) => theme.shadows[4] }}
    >
      <Stack spacing={2}>
        <Typography
          variant="h4"
          color={(theme) => theme.palette.primary.main}
          fontWeight={700}
        >
          {title}
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <Stack spacing={1} textAlign="right">
            <Typography
              variant="body2"
              sx={{
                lineHeight: 1,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              {thumbSubTitle}
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1 }}>
              {thumbMainTitle}
            </Typography>
          </Stack>
          {thumbnail && (
            <Box
              sx={{
                width: 48,
                height: 48,
                borderRadius: 24,
                background: `url(${thumbnail}) no-repeat 50% 50%`,
                backgroundSize: 'cover',
              }}
            />
          )}
        </Stack>
        <Typography
          variant="body1"
          textAlign="justify"
          dangerouslySetInnerHTML={{
            __html: sanitizedComment,
          }}
        />
        {banner && (
          <Thumbnail src={banner} alt={title} width="100%" height="auto" />
        )}
      </Stack>
    </Paper>
  )

  if (link) {
    return (
      <Link
        id={id}
        href={link}
        target={`_${linkBehavior}`}
        rel="noopener noreferrer"
      >
        {el}
      </Link>
    )
  }

  return el
}

export default RichFeedback

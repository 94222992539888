import { useRef } from 'react'
import { useRecoilValueLoadable, RecoilValue } from 'recoil'

type Ref<T> = {
  contents: T | null
  loading: boolean
}

export const useRecoilValueWithPrevious = <T>(value: RecoilValue<T>) => {
  const { contents, state } = useRecoilValueLoadable(value)
  const output = useRef<Ref<T>>({
    contents: null,
    loading: false,
  })

  switch (state) {
    case 'hasValue':
      output.current = {
        contents,
        loading: false,
      }
      break
    case 'loading':
      output.current = {
        ...output.current,
        loading: true,
      }
      break
    case 'hasError':
      throw contents
    default:
      break
  }

  return output.current
}

import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { Feedback } from 's2-lib'
import BannerFeedback from './BannerFeedback'
import CommentFeedback from './CommentFeedback'
import RichFeedback from './RichFeedback'

export type FeedbackListProps = {
  items?: Feedback[]
  maxNumOf?: number
  linkBehavior?: 'blank' | 'self'
}

const FeedbackList: FC<FeedbackListProps> = ({
  items,
  maxNumOf = 10,
  linkBehavior = 'blank',
}) => (
  <Stack spacing={4} sx={{ mb: 8 }}>
    {items?.length ? (
      items.slice(0, maxNumOf).map(({ id, layout, content }) => {
        switch (layout) {
          case 'comment':
            return <CommentFeedback key={id} id={id} content={content} />
          case 'banner':
            return (
              <BannerFeedback
                key={id}
                id={id}
                content={content}
                linkBehavior={linkBehavior}
              />
            )
          case 'rich':
            return (
              <RichFeedback
                key={id}
                id={id}
                content={content}
                linkBehavior={linkBehavior}
              />
            )
          default:
            return null
        }
      })
    ) : (
      <Typography mt={4} variant="body2" color="grey.500" textAlign="center">
        該当するフィードバックがありません
      </Typography>
    )}
  </Stack>
)

export default FeedbackList

import { Box, Button, styled } from '@mui/material'

import type { ReleaseProduct } from 's2-lib'

import { Thumbnail } from '../../ui-elements/Thumbnail'

export type RecommendItemProps = {
  item: ReleaseProduct
  linkBehavior?: 'blank' | 'self'
  brand?: boolean
  description?: boolean
  name?: boolean
  price?: boolean
  tags?: boolean
  thumbnail?: boolean
  linkLabel: string
}

const Thumb = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',

  img: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}))

const Brand = styled('div')(() => ({
  fontSize: '11px',
  lineHeight: '1.18',
  wordBreak: 'break-all',
}))

const Name = styled('h4')(() => ({
  fontWeight: 400,
  lineHeight: '1.14',
  // minHeight: `${14 * 1.14 * 3}px`,
  wordBreak: 'break-all',
  margin: 0,
}))

const Price = styled('div')(() => ({
  fontSize: '16px',
  textAlign: 'right',
  fontWeight: '500',
  wordBreak: 'break-all',
}))

const Description = styled('div')(() => ({
  marginTop: '4px',
  fontSize: '12px',
  lineHeight: '1.8',
  wordWrap: 'break-word',
  textAlign: 'justify',
  wordBreak: 'break-all',
}))

const Tag = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  lineHeight: '1',
}))

const TagItem = styled('i')(() => ({
  border: '1px solid currentColor',
  fontStyle: 'normal',
  fontSize: '12px',
  backgroundColor: '#fff',
  padding: '2px 10px',
  borderRadius: '9px',
}))

const RecommendItem = ({
  item,
  linkBehavior = 'blank',
  brand = true,
  description = true,
  name = true,
  price = true,
  tags = true,
  thumbnail = true,
  linkLabel,
}: RecommendItemProps) => (
  <>
    {thumbnail && item.thumbnailUrl ? (
      <Thumb>
        <Thumbnail
          src={item.thumbnailUrl}
          width={512}
          height={512}
          alt={item.title}
        />
      </Thumb>
    ) : null}
    {brand && item.brand ? (
      <Brand className="RecommendItem__brand">{item.brand}</Brand>
    ) : null}
    {name && item.title ? (
      <Name className="RecommendItem__name">{item.title}</Name>
    ) : null}
    {price ? <Price>¥{item.price.toLocaleString()}</Price> : null}
    {item.link ? (
      <Button
        variant="outlined"
        color="primary"
        href={item.link}
        target={`_${linkBehavior}`}
        sx={{ p: 1.25, textAlign: 'center' }}
      >
        {linkLabel}
      </Button>
    ) : null}
    {description && item.description ? (
      <Description>{item.description}</Description>
    ) : null}
    {tags && item.tags?.length ? (
      <Tag>
        {item.tags.map((tag) => (
          <TagItem key={tag}>{tag}</TagItem>
        ))}
      </Tag>
    ) : null}
  </>
)

export default RecommendItem

import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Typography,
  styled,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useSkincareTip } from '../../hooks/useSkincareTip'
import $style from './style.module.scss'

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    transition: 'none !important',
  },
}))

const UP_PER_FRAME = 1 / 60
let dotPrevTime = 0
let rafId: number

export type S2TipsLoadingProgressProps = {
  label: string
  progress: number
  onDone?: () => void
}

export type S2TipsLoadingViewProps = S2TipsLoadingProgressProps & {
  tips: string[]
}

export const S2TipsLoadingProgress: FC<S2TipsLoadingProgressProps> = ({
  label,
  progress: goal,
  onDone,
}) => {
  const [progress, setProgress] = useState(0)
  const [dot, setDot] = useState(0)

  useEffect(() => {
    rafId = requestAnimationFrame(function tick(timeStamp) {
      if (progress === 1) {
        requestAnimationFrame(() => onDone?.())
        return
      }

      rafId = requestAnimationFrame(tick)

      // パーセントのカウントアップ
      if (progress !== goal) {
        setProgress(Math.min(goal, progress + UP_PER_FRAME))
      }

      // ドットの点滅アニメーション
      if (timeStamp - dotPrevTime >= 250) {
        dotPrevTime = timeStamp
        setDot((dot + 1) % 4)
      }
    })

    return () => cancelAnimationFrame(rafId)
  }, [dot, goal, onDone, progress])

  return (
    <Box className={$style.progress}>
      <Box className={$style.progress__label}>
        {label}
        <span className={$style.progress__dot}>{'...'.slice(0, dot)}</span>
      </Box>
      <BorderLinearProgress
        variant="determinate"
        value={progress * 100}
        sx={{ my: 2 }}
      />
      <Typography variant="body2">{Math.round(progress * 100)}%</Typography>
    </Box>
  )
}

export const S2TipsLoadingView: FC<S2TipsLoadingViewProps> = ({
  tips,
  ...props
}) => {
  const [tip] = useSkincareTip(tips)
  return (
    <Box className={$style.root}>
      {tips.length ? (
        <Box className={$style.tips}>
          <Box
            className="s2-flex-center"
            sx={{
              px: 4,
              py: 2,
              backgroundColor: (theme) => theme.palette.primary.main,
            }}
          >
            <Box component="i" className={$style.tips__ico} sx={{ mr: 2 }} />
            <Box sx={{ lineHeight: 1.3, textAlign: 'left' }}>
              <Typography variant="body2">
                知っているようで、できてない
              </Typography>
              <Typography variant="h4" sx={{ fontWeigth: 700, my: 0 }}>
                Skincare Tipsをチェック
              </Typography>
            </Box>
          </Box>
          <Box className={$style.tips__content} sx={{ px: 4, py: 2 }}>
            {tip}
          </Box>
        </Box>
      ) : null}
      <S2TipsLoadingProgress {...props} />
    </Box>
  )
}

import {
  FC,
  PropsWithChildren,
  UIEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { styled } from '@mui/material'
import { createBoxShadow } from '../../common/utils/createBoxShadow'
import { FillHeight } from './FillHeight'
import { throttle } from '../../common/utils/throttle'

const headerHeight = '56px'

const Header = styled('header')(({ theme }) => {
  const { shadows, transitions } = theme
  // const mainColor = palette.primary.main
  return {
    position: 'absolute',
    zIndex: 15,
    top: 0,
    left: 0,
    width: '100%',
    height: headerHeight,
    backgroundColor: '#fff',
    // boxShadow: createBoxShadow(shadows[3], 'rgb(0,0,0)', 0.25),
    transition: transitions.create(['box-shadow'], {
      duration: transitions.duration.short,
      easing: transitions.easing.easeOut,
    }),
    '&.scrolling': {
      boxShadow: createBoxShadow(shadows[3], 'rgb(0,0,0)', 0.5),
      // boxShadow: createBoxShadow(shadows[3], mainColor, 0.5),
    },
  }
})

const Scroller = styled('main')({
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  MsOverflowStyle: 'none',
  height: '100%',
})

export const BaseLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [reachedTop, setReachedTop] = useState(true)
  const updateReachedTop = useMemo(
    () => throttle((bool: boolean) => setReachedTop(bool), 250),
    []
  )
  const onScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      updateReachedTop(e.currentTarget.scrollTop === 0)
    },
    [updateReachedTop]
  )

  return (
    <FillHeight
      sx={{
        mx: 'auto',
        maxWidth: 'sm',
        position: 'relative',
        backgroundColor: '#fff',
        pt: headerHeight,
        boxShadow: (theme) =>
          createBoxShadow(theme.shadows[6], theme.palette.primary.main),
      }}
    >
      <Header id="s2-ghead" className={reachedTop ? '' : 'scrolling'} />
      <Scroller onScroll={onScroll}>{children}</Scroller>
    </FillHeight>
  )
}

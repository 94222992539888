import { z } from 'zod'
import { projectSchema as baseSchema, labelings, LabelingKey } from 's2-lib'
import { counselingSchema } from './counseling'
import { assetImagePathSchema } from './assetImagePath'

type Labeling = Record<LabelingKey, string>

const defaultLabels = Object.entries(labelings).reduce<Labeling>(
  (map, [key, data]) => {
    // eslint-disable-next-line no-param-reassign
    map[key as LabelingKey] = data.placeholder
    return map
  },
  {} as Labeling
)

export const projectSchema = baseSchema
  .omit({
    enabledFeatures: true,
    features: true,
  })
  .extend({
    features: baseSchema.shape.features.pick({
      connectId: true,
      anonymous: true,
      homePage: true,
      saveFacePict: true,
      transferAccountUrl: true,
    }),
    thumbnailUrl: assetImagePathSchema,
    titleImageUrl: assetImagePathSchema,
    counselings: counselingSchema.array(),
    labels: baseSchema.shape.labels.transform<Labeling>((v) => ({
      ...defaultLabels,
      ...v,
    })),
  })

export type Project = z.infer<typeof projectSchema>

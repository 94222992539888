/* eslint-disable no-param-reassign */
import { useEffect } from 'react'
import {
  atom,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil'
import {
  EulerAngleRanges,
  getBrightnessFactory,
  validateContainsFactory,
  validateDirectionFactory,
} from '../helper/FaceDetector/Checklist'
import type { FaceDirVariant } from '../types'
import { detecter } from './useFaceDetect'

// 閾値
const CONTAINS_MIN_SCALE: Scale = [0.75]
const CONTAINS_MAX_SCALE: Scale = [1.1]
const FRONT_DIRECTION_RANGE: EulerAngleRanges = {
  pitch: [0, 30], // x
  yaw: [-7.5, 7.5], // y
  roll: [-7.5, 7.5], // z
}
const RIGHT_DIRECTION_RANGE: EulerAngleRanges = {
  ...FRONT_DIRECTION_RANGE,
  yaw: [30, 90],
}
const LEFT_DIRECTION_RANGE: EulerAngleRanges = {
  ...FRONT_DIRECTION_RANGE,
  yaw: [-90, -30],
}
const MIN_BRIGHTNESS_THRESHOLD = 0.55
const MAX_BRIGHTNESS_THRESHOLD = 1.0

// 失敗の許容回数
const MAX_SAFITY_FAIL = 3

const checklistMemo: Record<
  ChecklistKeys,
  { value: number; fallCount: number }
> = {
  contains: {
    value: 0,
    fallCount: MAX_SAFITY_FAIL,
  },
  direction: {
    value: 0,
    fallCount: MAX_SAFITY_FAIL,
  },
  brightness: {
    value: 0,
    fallCount: MAX_SAFITY_FAIL,
  },
}

const checklistsState = atom<Checklists>({
  key: 'face-detect-checklists',
  default: {
    contains: false,
    direction: false,
    brightness: false,
  },
})

export const validState = selector({
  key: 'face-detect-valid',
  get({ get }) {
    const checklists = get(checklistsState)
    return checklists.contains && checklists.direction && checklists.brightness
  },
})

const validMessageState = selectorFamily<string, FaceDirVariant>({
  key: 'face-detect-valid-message',
  get(dirVariant) {
    return ({ get }) => {
      const checklists = get(checklistsState)

      if (!checklists.contains || !checklists.direction) {
        switch (dirVariant) {
          case 'dir-right':
            return '枠内に顔を収めて右側を向いてください'
          case 'dir-left':
            return '枠内に顔を収めて左側を向いてください'
          default:
            return '枠内に顔を収めて正面を向いてください'
        }
      }

      if (!checklists.brightness) {
        if (checklistMemo.brightness.value < MIN_BRIGHTNESS_THRESHOLD) {
          return '撮影場所を明るくしてください'
        }

        if (checklistMemo.brightness.value > MAX_BRIGHTNESS_THRESHOLD) {
          return '撮影場所を暗くしてください'
        }
      }

      return 'そのまま静止してください'
    }
  },
})

const getBrightness = getBrightnessFactory()

const validateContains = validateContainsFactory(
  CONTAINS_MIN_SCALE,
  CONTAINS_MAX_SCALE
)

const validateDirections: Record<
  FaceDirVariant,
  ReturnType<typeof validateDirectionFactory>
> = {
  front: validateDirectionFactory(FRONT_DIRECTION_RANGE),
  'dir-right': validateDirectionFactory(RIGHT_DIRECTION_RANGE),
  'dir-left': validateDirectionFactory(LEFT_DIRECTION_RANGE),
}

const updatedCheckItem = (key: ChecklistKeys, valid: boolean) => {
  const memo = checklistMemo[key]

  if (valid) {
    memo.fallCount = 0
    return true
  }

  if (memo.fallCount < MAX_SAFITY_FAIL) {
    memo.fallCount += 1
    return true
  }

  return false
}

export const useChecklist = (dirVariant: FaceDirVariant) => {
  const [checklists, setChecklist] = useRecoilState(checklistsState)
  const validMessage = useRecoilValue(validMessageState(dirVariant))
  const reset = useResetRecoilState(checklistsState)

  useEffect(() => {
    const updater = () => {
      const { lastLandmark, targetRect, lastEulerAngles, source } = detecter

      if (!lastLandmark || !targetRect || !lastEulerAngles || !source) {
        return
      }

      const validContains = validateContains(lastLandmark, targetRect)
      const validDirection = validateDirections[dirVariant](lastEulerAngles)
      const contains = updatedCheckItem('contains', validContains)
      const direction = updatedCheckItem('direction', validDirection)
      // 位置か向きがNGなら明るさは計測しない
      const brightValue =
        !contains || !direction
          ? -1
          : getBrightness(dirVariant, source, lastLandmark)
      const validBrightness =
        brightValue >= MIN_BRIGHTNESS_THRESHOLD &&
        brightValue <= MAX_BRIGHTNESS_THRESHOLD
      const brightness = updatedCheckItem('brightness', validBrightness)
      checklistMemo.brightness.value = brightValue

      setChecklist((prev) => {
        if (
          prev.contains !== contains ||
          prev.direction !== direction ||
          prev.brightness !== brightness
        ) {
          return { contains, direction, brightness }
        }

        return prev
      })
    }
    const reseter = () => {
      checklistMemo.brightness.fallCount = 0
      checklistMemo.brightness.value = 0
      checklistMemo.contains.fallCount = 0
      checklistMemo.direction.fallCount = 0
      reset()
    }

    detecter.on('detected', updater)
    detecter.on('stop', reseter)

    return () => {
      detecter.off('detected', updater)
      detecter.off('stop', reseter)
    }
  }, [reset, setChecklist, dirVariant])

  return {
    checklists,
    validMessage,
  }
}

import { TextField } from '@mui/material'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { TextfieldProps } from '.'
import { CounselingAnswer } from '../../../common/schemas/counseling'
import { FormTitle } from './FormTitle'

export const TextForm: FC<TextfieldProps> = ({ id, title, required }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CounselingAnswer>()

  return (
    <>
      <FormTitle title={title} required={required} />
      <TextField
        fullWidth
        required={required}
        error={id in errors}
        helperText={errors[id]?.message}
        {...register(id)}
      />
    </>
  )
}

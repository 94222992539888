import { Box, BoxProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export type MaterialIconProps = PropsWithChildren<
  BoxProps & {
    size?: number | string
  }
>

export const MaterialIcon: FC<MaterialIconProps> = ({
  children,
  className,
  size = 'inherit',
  sx,
  ...props
}) => (
  <Box
    component="span"
    className={`${className} material-icons-round`}
    sx={{
      fontSize: `${typeof size === 'string' ? size : `${size}px`} !important`,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
)

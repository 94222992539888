class Proceed {
  private pgs = 0

  get progress() {
    return this.pgs
  }

  update(progress = 1) {
    this.pgs = progress
  }
}

export const useProgress = (
  totalTasks: number,
  func: (progress: number) => void
) => {
  const proceeds = Array(totalTasks)
    .fill(0)
    .map(() => new Proceed())

  const getCurrentProgress = () =>
    proceeds.reduce((total, proceed) => total + proceed.progress, 0) /
    proceeds.length

  return proceeds.map((proceed) => (num?: number) => {
    proceed.update(num)
    func(getCurrentProgress())
  })
}

import { Link, LinkProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { MaterialIcon } from '../MaterialIcon'
import styles from './style.module.scss'

export type TextLinkProps = PropsWithChildren<
  LinkProps & {
    external?: boolean
  }
>

export const TextLink: FC<TextLinkProps> = ({
  children,
  external: externalProps,
  target,
  rel,
  underline = 'hover',
  href,
  sx,
  ...props
}) => {
  const external = externalProps ?? /^(http|mailto)/.test(href || '')
  return (
    <Link
      href={href}
      target={target || (external ? '_blank' : '_self')}
      rel={rel || (external ? 'noreferrer noopener' : '')}
      underline={underline}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        ...sx,
      }}
      {...props}
    >
      {children}
      {external && (
        <MaterialIcon className={styles.icon} size="1.1em">
          open_in_new
        </MaterialIcon>
      )}
    </Link>
  )
}

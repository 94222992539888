import type { FC, ReactNode } from 'react'

import { Thumbnail } from 's2-component'

import { useProjectValue } from '../../common/hooks/useProject'
import { TheHeader } from '../layouts/TheHeader'

type TitleHeaderProps = {
  action?: ReactNode
}

const TitleHeader: FC<TitleHeaderProps> = ({ action }) => {
  const { title, titleImageUrl } = useProjectValue()
  return (
    <TheHeader action={action}>
      {titleImageUrl ? (
        <Thumbnail src={titleImageUrl} alt={title} width="100%" height="100%" />
      ) : (
        title
      )}
    </TheHeader>
  )
}

export default TitleHeader

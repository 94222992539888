import { Stack } from '@mui/material'
import { scaleLinear, scaleTime } from '@visx/scale'
import { LinePath } from '@visx/shape'
import { curveBasis } from '@visx/curve'
import { AxisLeft } from '@visx/axis'
import { FC, useMemo, useState } from 'react'
import { Group } from '@visx/group'
import DateAxis from './DateAxis'
import { Diagnosis } from '../../../../common/schemas/diagnosis'
import { useProjectValue } from '../../../../common/hooks/useProject'

export type SeriesData = {
  date: number
  value: number
}

export type Props = {
  width: number
  height: number
  items: Diagnosis[]
}

const Y_AXIS_SIZE = 15

const X_AXIS_SIZE = 21

const COLOR_ENTRY = [
  ['stain', '#F48FB1'],
  ['wrinkle', '#CE93D8'],
  ['texture', '#9FA8DA'],
  ['pores', '#90CAF9'],
  ['moisture', '#80DEEA'],
  ['clarity', '#C5E1A5'],
] as const

const COLOR_MAP = new Map(COLOR_ENTRY)

const Y_AXIS_MAP = new Map([
  // 0.14~0.28
  [0.1, 'E'],
  // 0.28~0.42
  [0.3, 'D'],
  // 0.42~0.56
  [0.5, 'C'],
  // 0.56~0.7
  [0.7, 'B'],
  // 0.7 ~ 1
  [0.9, 'A'],
])

const Y_AXIS_TICKS = Array.from(Y_AXIS_MAP.keys())

const Y_LINES = [0.2, 0.4, 0.6, 0.8]

const SkinScoreGraph: FC<Props> = ({ width, height, items }) => {
  const { labels } = useProjectValue()
  const [visibleKeys, setVisibleKeys] = useState({
    stain: true,
    wrinkle: true,
    texture: true,
    pores: true,
    moisture: true,
    clarity: true,
  })
  const series = useMemo(() => {
    const scoreKeys = Object.keys(items[0].scores)
    const scoreMap = new Map<string, SeriesData[]>(
      scoreKeys.map((key) => [key, []])
    )

    Object.entries(visibleKeys).forEach(([key, visible]) => {
      if (!visible) {
        scoreMap.delete(key)
      }
    })

    items.forEach(({ scores, createdAt }) => {
      Object.entries(scores).forEach(([key, { rank }]) => {
        const list = scoreMap.get(key)
        const yAxisScore = Array.from(Y_AXIS_MAP).find(
          ([_, yAxisRank]) => yAxisRank === rank
        )
        if (list) {
          list.push({ date: createdAt, value: yAxisScore![0] })
        }
      })
    })

    return Array.from(scoreMap.entries()).map(([key, data]) => ({
      data,
      color: COLOR_MAP.get(key as 'stain')!,
    }))
  }, [items, visibleKeys])
  const mainHeight = Math.max(0, height - 32)
  const dates = items.map(({ createdAt }) => createdAt)
  const xMin = Math.min(...dates)
  const xMax = Math.max(...dates)
  const xScale = scaleTime<number>({
    domain: [xMin, xMax],
    range: [0, width - Y_AXIS_SIZE],
  })
  const yScale = scaleLinear<number>({
    domain: [0, 1],
    range: [mainHeight - X_AXIS_SIZE, 0],
  })
  // const colorScale = scaleOrdinal({
  //   domain: Array.from(COLOR_MAP.keys()),
  //   range: Array.from(COLOR_MAP.values()),
  // })

  return (
    <>
      <svg width={width} height={mainHeight}>
        <Group left={Y_AXIS_SIZE}>
          {Y_LINES.map((y) => (
            <line
              key={y}
              x1={xScale(xMin)}
              x2={xScale(xMax)}
              y1={yScale(y)}
              y2={yScale(y)}
              stroke="#efefef"
            />
          ))}
          {series.map(({ data, color }, i) => (
            <LinePath<SeriesData>
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              curve={curveBasis}
              data={data}
              x={(d) => xScale(d.date)}
              y={(d) => yScale(d.value)}
              stroke={color}
              strokeWidth={2}
              shapeRendering="auto"
            />
          ))}
          <AxisLeft
            hideTicks
            scale={yScale}
            tickFormat={(value) => Y_AXIS_MAP.get(value as number) ?? ''}
            strokeWidth={2}
            stroke="#ccc"
            tickStroke="#ccc"
            tickLabelProps={() => ({
              fill: '#49454F',
              fontSize: 11,
              fontWeight: '500',
              textAnchor: 'end',
              dx: '2px',
            })}
            tickValues={Y_AXIS_TICKS}
          />
          <DateAxis top={mainHeight - X_AXIS_SIZE} scale={xScale} />
        </Group>
      </svg>
      <Stack
        direction="row"
        gap={2}
        justifyContent="center"
        typography="body2"
        sx={{ mt: 4, whiteSpace: 'nowrap' }}
      >
        {COLOR_ENTRY.map(([key, color]) => {
          const upperCamelCaseKey = (key.charAt(0).toUpperCase() +
            key.slice(1)) as
            | 'Clarity'
            | 'Stain'
            | 'Wrinkle'
            | 'Moisture'
            | 'Pores'
            | 'Texture'
          const text = labels[`skinScore${upperCamelCaseKey}`]
          return (
            <Stack
              key={key}
              sx={{
                opacity: visibleKeys[key] ? 1 : 0.5,
              }}
              gap={1}
              direction="row"
              alignItems="center"
              onClick={() => {
                setVisibleKeys((current) => ({
                  ...current,
                  [key]: !current[key],
                }))
              }}
            >
              <svg width={12} height={12}>
                <circle fill={color} r={6} cx={6} cy={6} />
              </svg>
              {text}
            </Stack>
          )
        })}
      </Stack>
    </>
  )
}

export default SkinScoreGraph

import { Box, DialogProps, styled } from '@mui/material'
import { FC } from 'react'
import { Description, Heading, S2Modal } from 's2-component'

const Title = styled(Heading)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: theme.spacing(2),
  flex: 1,
}))

type CameraAccessDeniedDialogProps = Pick<DialogProps, 'open' | 'onClose'>

const CameraAccessDeniedDialog: FC<CameraAccessDeniedDialogProps> = (props) => (
  <S2Modal title="カメラにアクセスできません" {...props}>
    <Box p={4}>
      <Title variant="h3" mt={0}>
        ブラウザの確認
      </Title>
      <Description>
        SNSなどのアプリ内で本サイトを開いている場合、カメラ機能は利用できません。
        <br />
        iOSをご利用の方はSafari、Androidをご利用の方はGoogle
        Chromeで本サイトを表示し直してください。
      </Description>

      <Title variant="h3">カメラのアクセス拒否設定の確認</Title>
      <Heading variant="h4" color="secondary">
        iOSをご利用の方
      </Heading>
      <Description>
        ホーム画面の「設定」から「Safari」をタップし、「ウェブサイトの設定」の「カメラ」を許可に切り替えてください。
      </Description>

      <Heading variant="h4" color="secondary">
        Androidをご利用の方
      </Heading>
      <Description>
        Chromeアプリを開き、アドレスバーの右のその他アイコンから「設定」をタップした後、「サイト設定」をタップし、次に「カメラ」をオンに切り替えてください。
      </Description>
    </Box>
  </S2Modal>
)

export default CameraAccessDeniedDialog

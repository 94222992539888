import { z } from 'zod'

export const jwtSchema = z.object({
  refreshToken: z.string(),
  expiresIn: z.number(),
})

export type JwtSchema = z.infer<typeof jwtSchema>

export const getJWT = (key: string) => {
  try {
    const value = localStorage.getItem(key)
    return jwtSchema.parse(JSON.parse(value || ''))
  } catch {
    return null
  }
}

export const setJWT = (key: string, newValue: Partial<JwtSchema> | null) => {
  const value = getJWT(key)
  const refreshToken = newValue?.refreshToken || value?.refreshToken
  const expiresIn = newValue?.expiresIn || value?.expiresIn
  if (newValue && refreshToken && expiresIn) {
    localStorage.setItem(
      key,
      JSON.stringify({
        refreshToken,
        expiresIn,
      })
    )
  } else {
    localStorage.removeItem(key)
  }
}

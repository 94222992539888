import { Box, BoxProps, CircularProgress } from '@mui/material'
import { forwardRef } from 'react'

type Props = BoxProps & {
  fullHeight?: boolean
}

export const FallbackLoader = forwardRef<HTMLDivElement, Props>(
  ({ sx, fullHeight, ...props }, ref) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: fullHeight ? '100%' : 'auto',
        ...sx,
      }}
      ref={ref}
      {...props}
    >
      <CircularProgress thickness={5} size={32} />
    </Box>
  )
)

FallbackLoader.displayName = 'FallbackLoader'

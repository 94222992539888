import factory, { OpenCVJS, OPCV } from 'opencv'

const DETECT_POINTS = [
  // nose
  0, 0, 0,
  // jaw
  0, -330, -65,
  // left eye
  -240, 170, -135,
  // right eye
  240, 170, -135,
  // left mouth
  -150, -150, -125,
  // right mouth
  150, -150, -125,
  // left outline
  -480, 170, -340,
  // right outline
  480, 170, -340,
]

const ROW_NUM = DETECT_POINTS.length / 3

export class OpenCV {
  private opcv: OpenCVJS

  private modelPoints: OPCV.Mat

  private cameraMatrix: OPCV.Mat

  private imagePoints: OPCV.Mat

  private distCoeffs: OPCV.Mat

  constructor(opcv: OpenCVJS) {
    const { Mat, CV_64FC1, matFromArray } = opcv

    this.modelPoints = matFromArray(ROW_NUM, 3, CV_64FC1, DETECT_POINTS)
    this.cameraMatrix = matFromArray(
      3,
      3,
      CV_64FC1,
      [0, 0, 0, 0, 0, 0, 0, 0, 0]
    )
    this.imagePoints = Mat.zeros(ROW_NUM, 2, CV_64FC1)
    this.distCoeffs = Mat.zeros(4, 1, CV_64FC1)
    this.opcv = opcv
  }

  delete() {
    this.modelPoints.delete()
    this.cameraMatrix.delete()
    this.imagePoints.delete()
    this.distCoeffs.delete()
  }

  updateCameraMatrix(width: number, height: number) {
    const { CV_64FC1, matFromArray } = this.opcv
    this.cameraMatrix = matFromArray(3, 3, CV_64FC1, [
      ...[width, 0, width / 2],
      ...[0, width, height / 2],
      ...[0, 0, 1],
    ])
  }

  estimateEulerAngles(landmark: Landmark): EulerAngles | undefined {
    const { Mat, CV_64FC1, solvePnP, Rodrigues, decomposeProjectionMatrix } =
      this.opcv
    const {
      nose,
      leftMouth,
      rightMouth,
      jaw,
      leftEye,
      rightEye,
      leftOutline,
      rightOutline,
    } = landmark

    if (
      !nose ||
      !leftMouth ||
      !rightMouth ||
      !jaw ||
      !leftEye ||
      !rightEye ||
      !leftOutline ||
      !rightOutline
    ) {
      return
    }

    const rvec = new Mat({ width: 1, height: 3 }, CV_64FC1)
    const tvec = new Mat({ width: 1, height: 3 }, CV_64FC1)
    const flat2DPoints = [
      ...nose,
      ...jaw,
      ...leftEye,
      ...rightEye,
      ...leftMouth,
      ...rightMouth,
      ...leftOutline,
      ...rightOutline,
    ]

    for (let idx = 0; idx < flat2DPoints.length; idx += 1) {
      this.imagePoints.data64F[idx] = flat2DPoints[idx]
    }

    // initialize transition and rotation matrixes to improve estimation
    tvec.data64F[0] = -100
    tvec.data64F[1] = 100
    tvec.data64F[2] = 1000
    const distToLeftEyeX = Math.abs(leftEye[0] - nose[0])
    const distToRightEyeX = Math.abs(rightEye[0] - nose[0])

    if (distToLeftEyeX < distToRightEyeX) {
      // looking at left
      rvec.data64F[0] = -1.0
      rvec.data64F[1] = -0.75
      rvec.data64F[2] = -3.0
    } else {
      // looking at right
      rvec.data64F[0] = 1.0
      rvec.data64F[1] = -0.75
      rvec.data64F[2] = -3.0
    }

    const success = solvePnP(
      this.modelPoints,
      this.imagePoints,
      this.cameraMatrix,
      this.distCoeffs,
      rvec,
      tvec,
      true
    )

    if (
      !success ||
      ((rvec.data64F || []).some((v: number) => Number.isNaN(v)) &&
        (tvec.data64F || []).some((v: number) => Number.isNaN(v)))
    ) {
      rvec.delete()
      tvec.delete()
      return
    }

    const rmat = new Mat()
    const projectMat = Mat.zeros(3, 4, CV_64FC1)

    Rodrigues(rvec, rmat)

    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[0] = rmat.data64F[0]
    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[1] = rmat.data64F[1]
    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[2] = rmat.data64F[2]
    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[4] = rmat.data64F[3]
    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[5] = rmat.data64F[4]
    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[6] = rmat.data64F[5]
    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[8] = rmat.data64F[6]
    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[9] = rmat.data64F[7]
    // eslint-disable-next-line prefer-destructuring
    projectMat.data64F[10] = rmat.data64F[8]

    const cmat = new Mat()
    const rotmat = new Mat()
    const travec = new Mat()
    const rotmatX = new Mat()
    const rotmatY = new Mat()
    const rotmatZ = new Mat()
    const eaMat = new Mat()

    decomposeProjectionMatrix(
      projectMat,
      cmat,
      rotmat,
      travec,
      rotmatX,
      rotmatY,
      rotmatZ,
      eaMat
    )

    const eulerAngles: EulerAngles = {
      yaw: eaMat.data64F[1],
      pitch: eaMat.data64F[0],
      roll: eaMat.data64F[2],
    }

    rmat.delete()
    projectMat.delete()
    cmat.delete()
    rotmat.delete()
    travec.delete()
    rotmatX.delete()
    rotmatY.delete()
    rotmatZ.delete()
    eaMat.delete()
    rvec.delete()
    tvec.delete()

    // eslint-disable-next-line consistent-return
    return eulerAngles
  }

  static async create() {
    return new OpenCV(await factory())
  }
}

import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { atom, useRecoilValue } from 'recoil'

export const gaMeasurementIdState = atom<string | null>({
  key: 'ga',
  default: null,
})

type UseGAMethods = {
  gaLog<T extends keyof GtagEventAction>(
    key: T,
    value: GtagEventAction[T]
  ): void
  selectContent(
    content:
      | 'history_detail'
      | 'histroy_detail:showHistoryMenu'
      | 'histroy_detail:changeHistory'
      | 'histroy_detail:showAboutChart'
      | 'history_transaction'
      | 'recommend_items'
      | 'favorite_items'
  ): void
}

export const useGA = () => {
  const id = useRecoilValue(gaMeasurementIdState)
  const gaLog = useCallback<UseGAMethods['gaLog']>(
    (key, value) => {
      if (id) {
        window.gtag?.('event', key, {
          ...value,
          send_to: id,
        })
      }
    },
    [id]
  )

  const selectContent = useCallback<UseGAMethods['selectContent']>(
    (content) => gaLog('select_content', { content_type: content }),
    [gaLog]
  )

  return [id, { gaLog, selectContent }] as const
}

type UsePageviewParams = {
  ignore?: boolean
}

export const usePageview = (params?: UsePageviewParams) => {
  const { pathname: routerPath, search, hash } = useLocation()
  const { gaLog } = useGA()[1]
  const { ignore } = params || {}

  useEffect(() => {
    const timerId = setTimeout(() => {
      const { origin, pathname: originPath } = window.location

      if (!ignore && originPath === routerPath) {
        gaLog('page_view', {
          page_title: document.title,
          page_location: `${origin}${routerPath}${search}${hash}`,
        })
      }
    }, 100)

    return () => clearTimeout(timerId)
  }, [gaLog, hash, ignore, routerPath, search])
}

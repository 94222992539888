import { Box, BoxProps, styled } from '@mui/material'
import { FC } from 'react'
import { MaterialIcon } from 's2-component'
import { useChecklist } from '../../hooks/useChecklist'
import { FaceDirVariant } from '../../types'

type S2ChecklistItemProps = BoxProps & {
  label: string
  valid: boolean
}

const ValidLabel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  backgroundColor: '#666',
  color: '#fff',
  fontSize: '13px',
  transitionProperty: 'background-color, color',
  transitionDuration: '0.1s',
  transitionTimingFunction: 'ease-out',
  '&.is--valid': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}))

const ValidIconWrap = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  backgroundColor: 'rgba(255,255,255, 0.9)',
}))

const S2ChecklistItem: FC<S2ChecklistItemProps> = ({
  label,
  valid,
  ...props
}) => (
  <Box {...props}>
    <ValidLabel className={valid ? 'is--valid' : ''}>{label}</ValidLabel>
    <ValidIconWrap>
      {valid ? (
        <MaterialIcon
          size={32}
          display="block !important"
          color={(theme) => theme.palette.primary.main}
        >
          check_circle
        </MaterialIcon>
      ) : (
        <MaterialIcon size={32} display="block !important" color="#666">
          close
        </MaterialIcon>
      )}
    </ValidIconWrap>
  </Box>
)

export type S2ChecklistProps = {
  dirVariant: FaceDirVariant
}

export const S2Checklist: FC<S2ChecklistProps> = ({ dirVariant }) => {
  const { checklists, validMessage } = useChecklist(dirVariant)

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5,
        textAlign: 'center',
        borderRadius: 2,
        overflow: 'hidden',
      }}
    >
      <S2ChecklistItem flex="1" label="顔の位置" valid={checklists.contains} />
      <S2ChecklistItem flex="1" label="顔の向き" valid={checklists.direction} />
      <S2ChecklistItem flex="1" label="明るさ" valid={checklists.brightness} />
      <Box
        sx={{
          py: 2,
          fontWeight: 700,
          color: '#666',
          backgroundColor: 'rgba(255,255,255,0.9)',
          width: '100%',
          fontSize: '15px',
        }}
      >
        {validMessage}
      </Box>
    </Box>
  )
}

import Axios from 'axios'
import { UserApi, Configuration } from 's2-core-api'

const config = new Configuration()

export const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
})

const client = new UserApi(config, '', axios)

export default client

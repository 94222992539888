import { Alert, AlertTitle, Box, Button } from '@mui/material'
import { FC } from 'react'
import { createBoxShadow } from '../../common/utils/createBoxShadow'
import { FillHeight } from '../layouts/FillHeight'

export const ErrorBoundaryFallback: FC<{
  error: Error
  // componentStack: string | null
  // eventId: string | null
  resetError(): void
}> = ({ error, resetError }) => (
  <FillHeight
    className="s2-flex-center"
    sx={{
      mx: 'auto',
      maxWidth: 'sm',
      position: 'relative',
      backgroundColor: '#fff',
      p: 2,
      boxShadow: (theme) =>
        createBoxShadow(theme.shadows[6], theme.palette.primary.main),
    }}
  >
    <Box>
      <Alert severity="error">
        <AlertTitle>エラーが発生しました。</AlertTitle>
        <Box my={2}>{error.toString()}</Box>
        エラーは開発チームに通知済です。
        再発する場合は、時間をおいてから改めてご利用ください。
      </Alert>
      <Box textAlign="center" mt={4}>
        <Button variant="outlined" size="large" fullWidth onClick={resetError}>
          やり直す
        </Button>
      </Box>
    </Box>
  </FillHeight>
)

import { FC } from 'react'
import { useLatestDiagnosisValue } from '../common/hooks/useDiagnosis'
import { useProjectValue } from '../common/hooks/useProject'
import { Authentication } from '../components/functional/Authentication'
import Redirect from '../components/functional/Redirect'

const PageComponent: FC = () => {
  const latest = useLatestDiagnosisValue()
  const {
    features: { homePage },
  } = useProjectValue()
  const to = !latest || homePage === 'shooting' ? '/counseling' : '/result'

  return <Redirect to={to} replace />
}

export default () => (
  <Authentication>
    <PageComponent />
  </Authentication>
)

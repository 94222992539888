import { styled, Box } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

const Container = styled(Box)(({ theme }) => ({
  width: 375,
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(16, 'auto'),
}))

export const CompactCentalizeContainer: FC<PropsWithChildren> = ({
  children,
}) => (
  <Container>
    <Box sx={{ flex: 1 }}>{children}</Box>
  </Container>
)

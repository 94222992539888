import { FC } from 'react'
import { Navigate, NavigateProps, useLocation } from 'react-router-dom'

const Redirect: FC<NavigateProps> = ({ to: pathname, ...props }) => {
  const loc = useLocation()
  const to = {
    ...loc,
    ...(typeof pathname === 'string' ? { pathname } : pathname),
  }
  return <Navigate to={to} {...props} />
}

export default Redirect

export function throttle<T extends any[]>(
  fn: (...args: T) => any,
  delay: number
) {
  let timerId: NodeJS.Timer | undefined
  let lastExecTime = 0

  return (...args: T) => {
    const execute = () => {
      fn(...args)
      lastExecTime = performance.now()
    }

    if (timerId) {
      clearTimeout(timerId)
    }

    if (performance.now() - lastExecTime >= delay) {
      execute()
    } else {
      timerId = setTimeout(execute, delay)
    }
  }
}

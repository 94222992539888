import { createRoot } from 'react-dom/client'
import { App } from './App'
// import { queryString } from './common/utils/queryString'

import './plugins/sentry'
import './common/styles/global.scss'

const defaultProps: SetupProps = {
  mountTo: '#s2-root',
}

// const scriptQuery: SetupProps = ((script: HTMLOrSVGScriptElement | null) => {
//   if (script instanceof HTMLScriptElement) {
//     return queryString(script.src)
//   }
//   return {}
// })(document.currentScript)
// const pageQuery: SetupProps = queryString()

const setup = (props?: SetupProps) => {
  const {
    mountTo,
    routerType,
    baseUrl,
    projectId,
    idToken,
    idLinkage,
    dynamicParams,
  } = {
    ...defaultProps,
    ...props,
  }

  if (!mountTo || !projectId) {
    throw new Error('Initialization failed : invalid params')
  }

  const el = document.querySelector(mountTo)

  if (el) {
    createRoot(el).render(
      <App
        projectId={projectId}
        idToken={idToken}
        idLinkage={idLinkage}
        routerType={routerType}
        baseUrl={baseUrl}
        dynamicParams={dynamicParams}
      />
    )
  } else {
    throw new Error(`Initialization failed : [${mountTo}] is not found.`)
  }
}

if (typeof window !== 'undefined') {
  window.addEventListener('DOMContentLoaded', () => {
    if (typeof window.s2Ready === 'function') {
      window.s2Ready({ setup })
    }
  })
}

import { styled, Typography } from '@mui/material'
import { FC } from 'react'
import { Counseling } from '../../../common/schemas/counseling'

type FormTitleProps = Pick<Counseling, 'title' | 'required'>

const OptionalLabel = styled('small')(({ theme }) => ({
  ml: theme.spacing(2),
}))

export const FormTitle: FC<FormTitleProps> = ({ title, required }) => (
  <Typography variant="h3">
    {title}
    {!required && <OptionalLabel>（任意）</OptionalLabel>}
  </Typography>
)

import { FC, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { FeedbackList, Heading, RecommendList } from 's2-component'
import { Product } from '../../../common/schemas/product'
import { ProductCategory } from '../../../common/schemas/productCategory'
import { Feedback } from '../../../common/schemas/feedback'
import { useProjectValue } from '../../../common/hooks/useProject'
import { useRecord } from '../../../common/hooks/useRecord'

type MatchingIndexProps = {
  feedbacks: Feedback[]
  products: Product[]
  productCategories: ProductCategory[]
}

export const MatchingIndex: FC<MatchingIndexProps> = ({
  feedbacks,
  products,
  productCategories,
}) => {
  const {
    product: { visibleItem, ...product },
    feedback,
    labels,
  } = useProjectValue()
  const { viewProduct, viewFeedback, clickProduct, clickFeedback } = useRecord()

  useEffect(() => {
    const list: Record<string, 'feedback' | 'product'> = Object.fromEntries([
      ...feedbacks.map((data) => [data.id, 'feedback']),
      ...products.map((data) => [data.id, 'product']),
    ])
    const elements = Object.keys(list).map((id) => document.getElementById(id))
    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const el = entry.target as HTMLElement
            const category = list[el.id]
            switch (category) {
              case 'feedback':
                viewFeedback(el.id)
                break
              case 'product':
                viewProduct(el.id)
                break
              default:
                break
            }
            io.unobserve(el)
          }
        })
      },
      {
        // 画面の下1/4より上に入ったら閲覧とする
        rootMargin: '0px 0px -25% 0px',
        threshold: 0,
      }
    )
    const onClick = (e: MouseEvent) => {
      const el = e.currentTarget as HTMLElement
      const category = list[el.id]
      let currentTarget = e.target as HTMLElement | null | undefined

      if (!el || !currentTarget || !category) {
        return
      }

      do {
        if (currentTarget?.tagName.toLowerCase() === 'a') {
          switch (category) {
            case 'feedback':
              clickFeedback(el.id)
              break
            case 'product':
              clickProduct(el.id)
              break
            default:
              break
          }
          return
        }

        if (el === currentTarget) {
          return
        }

        currentTarget = currentTarget?.parentElement
      } while (currentTarget)
    }

    // ioとclickイベントの登録は1秒待つ
    elements.forEach((el) => {
      if (!el) {
        return
      }

      io.observe(el)
      el.addEventListener('click', onClick)
    })

    // ioとclickの後処理
    return () => {
      elements.forEach((el) => {
        if (!el) {
          return
        }

        io.unobserve(el)
        el.removeEventListener('click', onClick)
      })
      io.disconnect()
    }
  }, [
    clickFeedback,
    clickProduct,
    feedbacks,
    products,
    viewFeedback,
    viewProduct,
  ])

  return (
    <>
      {/* フィードバック */}
      {feedbacks.length ? (
        <Box mt={6}>
          <FeedbackList items={feedbacks} {...feedback} />
        </Box>
      ) : null}
      {/* レコメンド商品 */}
      {products.length ? (
        <Box sx={{ mt: 12 }}>
          <Heading variant="h2">{labels.recommendProducts}</Heading>
          <RecommendList
            items={products}
            productCategories={productCategories}
            linkLabel={labels.recommendProductLink}
            {...product}
            {...visibleItem}
          />
          <Button
            variant="contained"
            color="primary"
            href="/counseling"
            fullWidth
            sx={{ mt: 8 }}
          >
            {labels.detectAction}
          </Button>
        </Box>
      ) : null}
    </>
  )
}

import { FC, PropsWithChildren, useEffect } from 'react'
import { To } from 'react-router-dom'
import { useAccountValue } from '../../common/hooks/useAccount'
import { useRedirect } from '../../common/hooks/useRedirect'

export type AuthenticationProps = PropsWithChildren<{
  redirect?: To
}>

export const Authentication: FC<AuthenticationProps> = ({
  children,
  redirect: redirectPath = '/welcome',
}) => {
  const noAccount = !useAccountValue()
  const redirect = useRedirect()

  useEffect(() => {
    if (noAccount) {
      redirect(redirectPath)
    }
  }, [noAccount, redirect, redirectPath])

  if (noAccount) {
    return null
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  )
}

import { z } from 'zod'
import { tinyTextSchema } from './text'

export const userConnectIdStatus = z.enum([
  'Connected',
  'Reject',
  'Request',
  'Resolve',
  'None',
])

export const userGender = z.enum(['male', 'female', 'others'])

export const userSchema = z.object({
  id: z.string(),
  displayName: tinyTextSchema.optional(),
  gender: userGender.optional(),
  birthDate: z.string().optional(),
  lastDiagnosedAt: z.string().datetime().optional(),
  thumbnailUrl: z.string().nullish(),
  createdAt: z.string().datetime(),
  connectId: z.string().optional(),
  connectStatus: userConnectIdStatus,
})

export type User = z.infer<typeof userSchema>

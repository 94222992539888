import { Box, Typography } from '@mui/material'

import { FC, useEffect, useState } from 'react'

import { InlineCode, S2Modal } from 's2-component'

import { useAuth } from '../../../common/hooks/useAuth'
import { QRCode } from '../../ui/QRCode'

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

type TransferAccountModalProps = {
  transferAccountUrl: string
  open: boolean
  onClose(): void
}

export const TransferAccountModal: FC<TransferAccountModalProps> = ({
  transferAccountUrl,
  ...props
}) => {
  const [qrCodeValue, setQrCodeValue] = useState('')
  const [, { accessToken }] = useAuth()

  useEffect(() => {
    if (!accessToken) {
      return
    }

    const url = new URL(`${API_BASE_URL}/v1/user/auth-provider`)
    const redirectTo = new URL(
      transferAccountUrl === 'SAME_DOMAIN'
        ? window.location.origin
        : transferAccountUrl
    )
    redirectTo.search = window.location.search

    url.searchParams.append('accessToken', accessToken)
    url.searchParams.append('redirect', redirectTo.toString())

    setQrCodeValue(url.toJSON())
  }, [transferAccountUrl, accessToken])

  return (
    <S2Modal title="アカウントの引き継ぎ" {...props}>
      <Box sx={{ pr: 8, pb: 6, pl: 8 }}>
        <QRCode value={qrCodeValue} size={200} />
        <Typography>
          アカウント（診断履歴）を引き継ぎたい別の携帯電話／PCなどで上記QRコードを読み取るか、以下のURLをブラウザで開いてください。
          <br />
          このURLは最大15分間有効です。不正ログイン等につながるため、第三者に共有しないようご注意ください。
          <br />
          <br />
          <InlineCode
            sx={{
              wordBreak: 'break-all',
            }}
          >
            {qrCodeValue}
          </InlineCode>
        </Typography>
      </Box>
    </S2Modal>
  )
}

import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, AlertTitle, Box, BoxProps, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Description, TextLink, WelcomeHero } from 's2-component'
import {
  defaultWelcomeBtnLabel,
  defaultWelcomeContent,
  defaultWelcomeTitle,
} from 's2-lib'
import { useProjectValue } from '../../../../common/hooks/useProject'
import { useAuth } from '../../../../common/hooks/useAuth'
import defaultBgImage from './assets/welcome.jpg'
import { assetImagePathSchema } from '../../../../common/schemas/assetImagePath'

export type WelcomeViewProps = BoxProps & {}

export const WelcomeView: FC<WelcomeViewProps> = (props) => {
  const [
    authState,
    { initialize, logout, validConnectId, hasUserWithConnectId },
  ] = useAuth()
  const { labels, customPages } = useProjectValue()
  const [loading, setLoading] = useState(false)
  const { welcome, termsOfService, privacyPolicy } = customPages ?? {}
  const welcomeExternalUrl = welcome?.type === 'external' ? welcome.url : null
  const privacyPolicyUrl =
    privacyPolicy?.type === 'external' ? privacyPolicy.url : '/privacy-policy'
  const termsOfServiceUrl =
    termsOfService?.type === 'external'
      ? termsOfService.url
      : '/terms-of-service'
  const welcomeTitle =
    welcome?.type === 'customize' && welcome.title
      ? welcome.title
      : defaultWelcomeTitle
  const welcomeContent =
    welcome?.type === 'customize' && welcome.content
      ? welcome.content
      : defaultWelcomeContent
  const bgImage =
    welcome?.type === 'customize' && welcome.kvPath
      ? assetImagePathSchema.parse(welcome.kvPath)!
      : defaultBgImage
  const welcomeBtnLabel = hasUserWithConnectId
    ? '再開する'
    : welcome?.type === 'customize' && welcome.btnLabel
    ? welcome.btnLabel
    : defaultWelcomeBtnLabel
  const redirect = !validConnectId && !!welcomeExternalUrl
  const [hasError, setHasError] = useState(false)

  const handleInitialize = useCallback(async () => {
    setLoading(true)
    const result = await initialize()
    if (result.isErr) {
      setHasError(true)
      setLoading(false)
    }
  }, [initialize])

  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  const action = useMemo(() => {
    if (!validConnectId) {
      return (
        <Alert severity="error" sx={{ mb: 6 }}>
          <AlertTitle>ID連携の情報に誤りがあります</AlertTitle>
          アクセス元に戻って適切な方法でアクセスし直してください。
        </Alert>
      )
    }

    switch (authState) {
      case 'alreadyConnected':
        return (
          <Alert severity="error" sx={{ mb: 6 }}>
            <AlertTitle>ご指定のIDは既に別アカウントと連携済です</AlertTitle>
            別アカウントでの利用をご希望の場合は一度ログアウトしてください。
            <br />
            既存アカウントでの利用をご希望の場合は、画面を戻って適切な方法でアクセスし直してください。
            <Button
              sx={{ mt: 3, borderRadius: 10 }}
              size="small"
              fullWidth
              variant="outlined"
              color="error"
              onClick={handleLogout}
            >
              現在のアカウントからログアウトする
            </Button>
          </Alert>
        )
      default:
        return (
          <>
            <LoadingButton
              sx={{ minHeight: '43px', mb: 6, display: 'flex' }}
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleInitialize}
              loading={loading}
            >
              {welcomeBtnLabel}
            </LoadingButton>
            {hasError ? (
              <Alert severity="error" sx={{ mb: 6 }}>
                <AlertTitle>初期化処理に失敗にしました</AlertTitle>
                時間をおいて改めてお試しください。
              </Alert>
            ) : null}
          </>
        )
    }
  }, [
    handleInitialize,
    handleLogout,
    hasError,
    authState,
    loading,
    validConnectId,
    welcomeBtnLabel,
  ])

  useEffect(() => {
    if (redirect) {
      window.location.href = welcomeExternalUrl
    }
  }, [welcomeExternalUrl, redirect])

  return (
    <Box {...props}>
      <WelcomeHero
        title={welcomeTitle}
        content={welcomeContent}
        kvPath={bgImage}
        sx={{ mx: -6, mb: 6 }}
      />
      {action}
      <Description variant="body2">
        {labels.skinDetect}を行うと
        <TextLink href={termsOfServiceUrl}>利用規約</TextLink>と
        <TextLink href={privacyPolicyUrl}>プライバシーポリシー</TextLink>
        にご同意いただいたものとします。
        <br />
        現在ご利用のブラウザ以外からのアクセスやcookieの削除などを行うと
        {labels.diagnosisHistory}にアクセスできなくなります。ご注意ください。
        <br />
      </Description>
    </Box>
  )
}

import { z } from 'zod'

import { productCategorySchema } from './productCategory'

import { optinalUrlSchema } from './url'
import { longTextSchema, shortTextSchema, tinyTextSchema } from './text'
import { idSchema } from './id'

export const MAX_PRICE = 10_000_000

export const productSchema = z.object({
  id: idSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
  title: shortTextSchema.min(1, '必須項目です'),
  price: z.number().int().min(0).max(MAX_PRICE),
  code: shortTextSchema.nullable().optional(),
  thumbnailUrl: z.string().nullish(),
  brand: shortTextSchema.nullable().optional(),
  description: longTextSchema.nullable().optional(),
  link: optinalUrlSchema,
  categories: z
    .array(productCategorySchema)
    .optional()
    .transform((v) => v ?? []),
  tags: z
    .custom<string[]>()
    .refine(
      (v) =>
        !v?.length || v.every((val) => tinyTextSchema.safeParse(val).success),
      '1つのタグあたり32文字以内にしてください'
    )
    .transform((v) => (v?.length ? v : [])),
})

export type Product = z.infer<typeof productSchema>

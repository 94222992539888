import { Box, CircularProgress, Fade } from '@mui/material'
import { FC, ReactElement, useState, useEffect, memo, useRef } from 'react'
import { useRecoilValue } from 'recoil'
import { validState } from '../../hooks/useChecklist'
import { useFaceDetect } from '../../hooks/useFaceDetect'
import { FaceDirVariant } from '../../types'
import { S2Checklist } from '../S2Checklist'
import $style from './style.module.scss'

const MemoizeChecklist = memo(S2Checklist)

const Countdown: FC<{
  countdown: number
  onCapture?: () => void
}> = ({ countdown, onCapture }) => {
  const { detecter } = useFaceDetect()
  const valid = useRecoilValue(validState)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let reqId = 0
    const startTime = performance.now()
    const tick = (timestamp: number) => {
      const newProgress = Math.min(1, (timestamp - startTime) / countdown)

      if (newProgress === 1) {
        setTimeout(() => onCapture?.(), 100)
      } else {
        reqId = requestAnimationFrame(tick)
      }

      setProgress(newProgress * 100)
    }
    const onStart = () => {
      if (valid) {
        reqId = requestAnimationFrame(tick)
      }
    }
    const onStop = () => {
      cancelAnimationFrame(reqId)
    }

    detecter.on('start', onStart)
    detecter.on('stop', onStop)

    if (valid) {
      setProgress(0)
      tick(startTime)
    }

    return () => {
      cancelAnimationFrame(reqId)
      detecter.off('start', onStart)
      detecter.off('stop', onStop)
    }
  }, [countdown, detecter, onCapture, valid])

  return (
    <Fade in={valid && progress < 100}>
      <div className={$style.countdown}>
        <CircularProgress
          variant="determinate"
          size={100}
          thickness={5}
          color="primary"
          value={progress}
        />
      </div>
    </Fade>
  )
}

const MemoizeCountdown = memo(Countdown)

export type S2FaceDetectUIViewProps = {
  countdown?: number
  onCapture?: () => void
  top?: ReactElement
  right?: ReactElement
  bottom?: ReactElement
  left?: ReactElement
  center?: ReactElement
  variant?: FaceDirVariant
}

export const S2FaceDetectUIView: FC<S2FaceDetectUIViewProps> = ({
  countdown = 1000,
  onCapture,
  top,
  right,
  bottom,
  left,
  center,
  variant = 'front',
}) => {
  const { setDistFrame } = useFaceDetect()
  const rootRef = useRef<HTMLDivElement>()
  const frameRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const root = rootRef.current
    const frame = frameRef.current
    const resizeObserver = new ResizeObserver(() => {
      if (!root || !frame) {
        return
      }

      const {
        width,
        height,
        left: rootX,
        top: rootY,
      } = root.getBoundingClientRect()
      // 0.7 は枠全体の大きさ
      const w1 = Math.round(width * 0.75)
      // 枠画像のサイズ
      const frameW = 972
      const frameH = 1266
      const h1 = Math.round(w1 * (frameH / frameW))
      const h2 = Math.round(height * 0.75)
      const w2 = Math.round(h2 * (frameW / frameH))
      const w = w1 < w2 ? w1 : w2
      const h = h1 < h2 ? h1 : h2

      frame!.style.width = `${w}px`
      frame!.style.height = `${h}px`

      requestAnimationFrame(() => {
        const { left: x, top: y } = frame.getBoundingClientRect()
        setDistFrame({
          root: { x: 0, y: 0, w: width, h: height },
          target: { x: x - rootX, y: y - rootY, w, h },
        })
      })
    })

    if (root && frame) {
      resizeObserver.observe(root)
    }

    return () => resizeObserver.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={$style.root} ref={rootRef}>
      {/* 顔枠 */}
      <Box className={$style.frame}>
        <Box
          className={$style['frame--blank']}
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
            flexDirection: 'column',
            pt: 2,
          }}
        >
          {top || (
            <Box
              sx={{
                mx: 'auto',
                position: 'relative',
                mb: -8,
                width: '80%',
                maxWidth: '375px',
              }}
            >
              {/* 項目評価 */}
              <MemoizeChecklist dirVariant={variant} />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Box className={$style['frame--blank']}>{left}</Box>
          <Box ref={frameRef} className={$style.frame__img} data-dir={variant}>
            {center}
          </Box>
          <Box className={$style['frame--blank']}>{right}</Box>
        </Box>
        <Box
          className={$style['frame--blank']}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            py: 2,
          }}
        >
          {bottom}
        </Box>
      </Box>

      {/* カウントダウン */}
      <MemoizeCountdown countdown={countdown} onCapture={onCapture} />
    </Box>
  )
}

import { Result } from '@badrap/result'

type ZodErrorValidation = {
  validation: string
  code: string
  message: string
  path: string[]
}

export type APIErrorBody =
  | {
      statusCode: number
      message: string
      code?: string
    }
  | {
      statusCode: number
      message: string
      code: 'ZOD_ERROR'
      data: ZodErrorValidation[]
    }

export class APIError extends Error {
  #body: APIErrorBody

  constructor(body: APIErrorBody) {
    super(body.message)
    this.#body = body
  }

  get statusCode() {
    return this.#body.statusCode
  }

  get code() {
    return this.#body.code
  }

  get data() {
    // @ts-ignore
    return this.#body.data as ZodErrorValidation[] | undefined
  }
}

export type APIResult<T> = Result<T, APIError>

import { createTheme, LinkProps } from '@mui/material'
import { ElementType } from 'react'

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary']
    monochrome: Palette['primary']
  }
  interface PaletteOptions {
    accent: PaletteOptions['primary']
    monochrome: PaletteOptions['primary']
  }
}

// 3. Button の color props を更新
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true
    monochrome: true
  }
}

export type ThemeProps =
  | {
      primary?: string
      secondary?: string
      accent?: string
      LinkBehavior?: ElementType<any>
    }
  | undefined

const defaulThemeProps: ThemeProps = {
  primary: '#40AEA9',
  secondary: '#14AAFF',
  accent: '#14AAFF',
}

export const createConsoleTheme = (props: ThemeProps) => {
  const merged = { ...defaulThemeProps, ...props }
  return createTheme({
    components: {
      MuiContainer: {
        defaultProps: {
          maxWidth: 'md',
        },
      },
      MuiPaper: {
        defaultProps: {
          sx: {
            // borderRadius: 4,
            boxShadow: (pallet) => pallet.shadows['2'],
          },
        },
      },
      MuiTableCell: {
        defaultProps: {
          sx: {
            p: 0,
          },
        },
      },
      MuiLink: {
        defaultProps: {
          component: merged.LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: merged.LinkBehavior,
        },
      },
      MuiCheckbox: {
        defaultProps: {
          sx: {
            my: -2,
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1080, // 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: merged.primary!,
        contrastText: '#fff',
      },
      secondary: {
        main: merged.secondary!,
        contrastText: '#fff',
      },
      accent: {
        main: merged.accent!,
        contrastText: '#fff',
      },
      error: {
        main: '#EF5350',
      },
      // warning: {
      //   main: '#ed6c02',
      //   light: '#FFF4E5',
      //   dark: '#e65100',
      // },
      monochrome: {
        main: '#fff',
        contrastText: '#333',
      },
    },
    spacing: 4,
    typography: {
      fontSize: 14,
      fontFamily: `"Roboto","Noto Sans JP","Helvetica","Arial",sans-serif`,
      h1: {
        fontSize: 32,
        fontWeight: '700',
        lineHeight: 1.1,
        marginTop: '8px',
        marginBottom: '32px',
      },
      h2: {
        fontSize: 24,
        fontWeight: 'inherit',
        lineHeight: 1.2,
        marginTop: '32px',
        marginBottom: '16px',
      },
      h3: {
        fontSize: 18,
        fontWeight: 'inherit',
        lineHeight: 1.3,
        marginTop: '24px',
        marginBottom: '12px',
      },
      h4: {
        fontSize: 16,
        fontWeight: 'inherit',
        lineHeight: 1.4,
        marginTop: '16px',
        marginBottom: '8px',
      },
      h5: {
        fontSize: 15,
        fontWeight: 'inherit',
        lineHeight: 1.4,
        marginTop: '8px',
        marginBottom: '4px',
      },
      // h6: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
      // subtitle1: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
      // subtitle2: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
      body1: {
        fontSize: 14,
        fontWeight: 'inherit',
        lineHeight: 1.6,
      },
      body2: {
        fontSize: 11,
        fontWeight: 'inherit',
        lineHeight: 1.4,
      },
      button: {
        fontSize: 14,
        fontWeight: 500,
        // lineHeight: 1.1,
      },
      // caption: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
      // overline: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
    },
  })
}

export const createAppTheme = (props: ThemeProps) => {
  const merged = { ...defaulThemeProps, ...props }
  return createTheme({
    components: {
      MuiLink: {
        defaultProps: {
          component: merged.LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: merged.LinkBehavior,
        },
      },
    },
    palette: {
      primary: {
        main: merged.primary!,
        contrastText: '#fff',
      },
      secondary: {
        main: merged.secondary!,
        contrastText: '#fff',
      },
      accent: {
        main: merged.accent!,
        contrastText: '#fff',
      },
      error: {
        main: '#EF5350',
      },
      warning: {
        main: '#FFF4E5',
      },
      monochrome: {
        main: '#333',
        contrastText: '#fff',
      },
    },
    spacing: 4,
    typography: {
      fontSize: 14,
      fontFamily: `"Roboto","Noto Sans JP","Helvetica","Arial",sans-serif`,
      h1: {
        fontSize: 20,
        fontWeight: 'inherit',
        lineHeight: 1.2,
        marginTop: '32px',
        marginBottom: '24px',
      },
      h2: {
        fontSize: 18,
        fontWeight: 'inherit',
        lineHeight: 1.3,
        marginTop: '24px',
        marginBottom: '16px',
      },
      h3: {
        fontSize: 16,
        fontWeight: 'inherit',
        lineHeight: 1.4,
        marginTop: '16px',
        marginBottom: '8px',
      },
      h4: {
        fontSize: 14,
        fontWeight: 'inherit',
        lineHeight: 1.4,
        marginTop: '8px',
        marginBottom: '4px',
      },
      // h5: {
      //   fontSize: 15,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.4,
      //
      //
      // },
      // h6: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
      // subtitle1: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
      // subtitle2: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
      body1: {
        fontSize: 14,
        fontWeight: 'inherit',
        lineHeight: 1.6,
      },
      body2: {
        fontSize: 11,
        fontWeight: 'inherit',
        lineHeight: 1.4,
      },
      button: {
        fontSize: 14,
        fontWeight: 500,
        // lineHeight: 1.1,
      },
      // caption: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
      // overline: {
      //   fontSize: 32,
      //   fontWeight: 'inherit',
      //   lineHeight: 1.1,
      // },
    },
  })
}

import { styled } from '@mui/material'
import { FC } from 'react'
import { Feedback } from 's2-lib'
import { Thumbnail } from '../../ui-elements/Thumbnail'

const Link = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
  transition: 'opacity 0.25s ease-out',
  '&:hover': {
    opacity: 0.5,
  },
})

type BannerFeedbackProps = {
  id?: Feedback['id']
  content: Feedback['content']
  linkBehavior?: 'blank' | 'self'
}

const BannerFeedback: FC<BannerFeedbackProps> = ({
  id,
  content: { banner, link, title },
  linkBehavior = 'blank',
}) => {
  if (!banner) {
    return null
  }

  const el = (
    <Thumbnail
      id={link ? undefined : id}
      src={banner}
      alt={title}
      width="100%"
      height="auto"
    />
  )

  if (link) {
    return (
      <Link
        id={id}
        href={link}
        target={`_${linkBehavior}`}
        rel="noopener noreferrer"
      >
        {el}
      </Link>
    )
  }

  return el
}

export default BannerFeedback

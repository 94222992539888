import { init } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const { DEV } = import.meta.env
const dsn = import.meta.env.VITE_SENTRY_DSN

if (dsn) {
  init({
    debug: DEV,
    enabled: !DEV,
    dsn,
    release: window.S2_APP_VERSION,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
    maxBreadcrumbs: 50,
    beforeBreadcrumb(breadcomb) {
      if (
        typeof breadcomb.data?.url === 'string' &&
        breadcomb.data.url.startsWith('data:application/octet-stream;base64,')
      ) {
        // eslint-disable-next-line no-param-reassign
        breadcomb.data.url = 'data:application/octet-stream;base64,...'
      }
      return breadcomb
    },
  })
}

import { Typography, Box, BoxProps } from '@mui/material'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { MaterialIconButton } from 's2-component'

export type TheHeaderProps = PropsWithChildren<
  BoxProps & {
    title?: string
    backPath?: string
    backIcon?: string
    backLabel?: string
    action?: ReactNode
    onBack?(): void
  }
>

export const TheHeader: FC<TheHeaderProps> = ({
  children,
  backPath,
  backLabel = '戻る',
  backIcon = 'arrow_back_ios_new',
  onBack,
  action,
  sx,
  ...props
}) => {
  const visibleBack = !!(backPath || onBack)
  const el = document.getElementById('s2-ghead')

  if (!el) {
    return null
  }

  return createPortal(
    <Box
      className="s2-flex-center"
      {...props}
      sx={{
        ...sx,
        width: '100%',
        height: '100%',
        px: 1,
        overflow: 'hidden',
      }}
    >
      <Box sx={{ width: '45px' }}>
        {visibleBack && (
          <MaterialIconButton
            // @ts-ignore
            href={backPath}
            aria-label={backLabel}
            icon={backIcon}
            onClick={onBack}
          />
        )}
      </Box>
      <Typography
        variant="h1"
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 500,
          textAlign: 'center',
          px: 2,
          py: 3,
          my: 0,
        }}
      >
        {children}
      </Typography>
      <Box sx={{ width: '45px' }}>{action}</Box>
    </Box>,
    el
  )
}

import { z } from 'zod'

import { releaseProductSchema as baseSchema, idSchema } from 's2-lib'

import { assetImagePathSchema } from './assetImagePath'

export const productSchema = baseSchema
  .omit({
    categories: true,
  })
  .extend({
    thumbnailUrl: assetImagePathSchema,
    categories: z.array(idSchema).default(() => []),
  })

export type Product = z.infer<typeof productSchema>
